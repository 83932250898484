import React from 'react';
import { Field } from '../../../components/Form';
import { FormSectionInner } from '../../../components/FormSection';

const Links = ({ index, name }) => (
  <FormSectionInner columns={2}>
    <Field
      name={`${name}[${index}].link_text`}
      component="text"
      placeholder="Follow link"
      label="Link Text"
    />
    <Field
      name={`${name}[${index}].link`}
      component="text"
      placeholder="https://..."
      label="Link Url"
    />
  </FormSectionInner>
);

export default Links;
