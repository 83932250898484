import React from 'react';
import { capitalize, find } from 'lodash';
import {
  Flex,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

const SurveyOptionStats = ({ option, statsPercentages }) => {
  const responses = option?.responses || [];
  const optionPercentage = find(statsPercentages, { title: option.title });

  return (
    <Stat>
      <StatLabel>{capitalize(option.title)}</StatLabel>
      <StatNumber color="blue.700">
        {optionPercentage.percentage}%
        <Text as="span" fontSize="xs" marginLeft={2} color="gray.500">
          ({responses?.length.toLocaleString('en-GB')})
        </Text>
      </StatNumber>
    </Stat>
  );
};

const SurveyOptionResponses = ({ option }) => {
  const responses = option?.responses || [];

  if (responses.length === 0) {
    return (
      <Text as="span" color="gray.500">
        No responses yet
      </Text>
    );
  }

  return (
    <UnorderedList>
      {responses?.map((response) => (
        <ListItem key={response._id}>
          <Text as="span" color="blue.700" fontWeight="semibold">
            {response.value}
          </Text>{' '}
          <Text as="span" fontSize="xs">
            ({response.userId})
          </Text>
        </ListItem>
      ))}
    </UnorderedList>
  );
};

const SurveyResponses = ({ survey }) => {
  // console.log('SurveyResponses ', survey?.questions);

  // calculate stats percentages
  const calculateStatsPercentages = (question, showStats) => {
    if (!showStats) {
      return null;
    }

    const totalResponses = question?.options?.reduce(
      (acc, option) => acc + option.responses.length,
      0,
    );

    return question?.options?.map((option) => {
      const optionResponses = option?.responses.length;
      const percentage = Math.round((optionResponses / totalResponses) * 100);
      return {
        title: option.title,
        responses: optionResponses,
        percentage,
      };
    });
  };

  return (
    <Flex flexDirection="column">
      <Accordion defaultIndex={[0]} allowMultiple>
        {survey?.questions?.map((question) => {
          const showStats = ['radio', 'checkbox', 'select'].includes(
            question?.type,
          );
          const statsPercentages = calculateStatsPercentages(
            question,
            showStats,
          );

          return (
            <AccordionItem key={question._id}>
              <h2>
                <AccordionButton _expanded={{ bg: '#e8e8e8' }}>
                  <Box flex="1" textAlign="left" fontWeight="medium">
                    {capitalize(question.title)}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {showStats ? (
                  <StatGroup size="sm">
                    {question.options &&
                      question.options.map((option) => (
                        <SurveyOptionStats
                          key={option._id}
                          option={option}
                          statsPercentages={statsPercentages}
                        />
                      ))}
                  </StatGroup>
                ) : (
                  <>
                    {question?.options.length > 0 ? (
                      question.options.map((option) => (
                        <SurveyOptionResponses
                          key={option._id}
                          option={option}
                        />
                      ))
                    ) : (
                      <Text as="span" color="gray.500">
                        No options set
                      </Text>
                    )}
                  </>
                )}
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Flex>
  );
};

export default SurveyResponses;
