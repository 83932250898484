import React, { useEffect } from 'react';
import {
  Box,
  Button,
  FormLabel,
  IconButton,
  Grid,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
} from '@chakra-ui/react';
import { arrayMoveImmutable } from 'array-move';
import { useWatch, useFormContext } from 'react-hook-form';
import { AiOutlineAppstoreAdd } from 'react-icons/ai';
import { FiSettings } from 'react-icons/fi';

import { BiArrowToBottom, BiArrowToTop, BiTrashAlt } from 'react-icons/bi';
import PopConfirm from '../../components/PopConfirm';

const List = (props) => {
  const { name, singleName, keyName, label, max } = props;
  const { setValue } = useFormContext();
  const val = useWatch({ name });

  const append = () => {
    setValue(name, val ? [...val, ''] : []);
  };

  const insert = (i) => {
    const _f = [...val];
    _f.splice(1, i);
    setValue(name, _f);
  };

  const move = (from, to) => {
    const array = arrayMoveImmutable(val, from, to);
    console.log(array);
    setValue(name, array);
  };

  const remove = (i) => {
    const arr = [...val];
    arr.splice(i, 1);
    setValue(name, arr);
  };

  useEffect(() => {
    if (!val || (val && val.length === 0)) {
      append('');
    }
  }, [val]);

  return (
    <Box w="100%">
      <FormLabel>
        {label} {max && `(maximum ${max} items)`}
      </FormLabel>
      <Box rounded="lg" mb="5px" position="relative" w="100%">
        {val &&
          val.length !== 0 &&
          val.map((field, index) => (
            <Grid
              key={field[keyName || 'id']}
              w="100%"
              templateColumns="1fr 60px"
              mb="4px"
            >
              <InputField name={name} index={index} append={append} />
              <Flex align="center" ml="5px">
                <Flex isInline spacing="2px">
                  <Menu boundary={document.body}>
                    <MenuButton
                      as={IconButton}
                      variant="link"
                      px="2px"
                      icon={<FiSettings fontSize="12px" />}
                    ></MenuButton>
                    <MenuList>
                      <MenuItem
                        leftIcon={<AiOutlineAppstoreAdd />}
                        onClick={() => insert(index, {})}
                      >
                        Add New
                      </MenuItem>
                      <MenuItem
                        leftIcon={<AiOutlineAppstoreAdd />}
                        onClick={() => insert(index, {})}
                      >
                        Add Item Above
                      </MenuItem>
                      <MenuItem
                        leftIcon={<AiOutlineAppstoreAdd />}
                        onClick={() => insert(index + 1, {})}
                      >
                        Add Item Below
                      </MenuItem>
                      <MenuItem
                        isDisabled={index === 0}
                        leftIcon={<BiArrowToTop fontSize="16px" />}
                        onClick={() => move(index, index - 1)}
                      >
                        Move Up
                      </MenuItem>
                      <MenuItem
                        isDisabled={index + 1 === val.length}
                        leftIcon={<BiArrowToBottom fontSize="16px" />}
                        onClick={() => move(index, index + 1)}
                      >
                        Move Down
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <PopConfirm onConfirm={() => remove(index)}>
                    <IconButton
                      variant="link"
                      px="2px"
                      icon={<BiTrashAlt fontSize="12px" />}
                    />
                  </PopConfirm>
                </Flex>
              </Flex>
            </Grid>
          ))}
      </Box>
      <Button
        onClick={() => append({})}
        leftIcon={<AiOutlineAppstoreAdd />}
        variant="outline"
        variantColor="secondary"
        isDisabled={val && val.length >= max}
        mb="10px"
        size="sm"
      >
        Add {singleName || 'item'}
      </Button>
    </Box>
  );
};

const InputField = ({ index, name, append }) => {
  const _value = useWatch({ name });

  const { setValue } = useFormContext();

  const set = (value) => {
    const arr = [..._value];
    arr[index] = value;
    setValue(name, arr);
  };

  const handleKeyPress = (key) => {
    if (key.key === 'Enter') {
      key.preventDefault();
      append();
    }
  };

  return (
    <Input
      autoFocus
      parentName={`${name}[${index}]`}
      isRepeater
      component="text"
      variant="filled"
      label={null}
      placeholder="Press Enter to add new item"
      onChange={(e) => set(e.target.value)}
      value={_value[index]}
      onKeyDown={(key) => {
        // key.preventDefault();
        handleKeyPress(key);
      }}
    />
  );
};

export default List;
