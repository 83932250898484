import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Field } from '../../components/Form';
import Controls from './Controls';

const AddGalleryImages = ({ field, index, name }) => {
  console.log(name, 'NAME');
  return (
    <Flex
      w="100%"
      direction="column"
      align="flex-start"
      rounded="5px"
      bg="white"
      p="15px"
      mb="15px"
    >
      <Controls index={index} />
      <Flex direction="column" w="100%">
        <Field
          name={`${name}[${index}].gallery`}
          component="repeater"
          singleName="Image"
          layout="vertical"
          width="100%"
          minWidth="100%"
          label="Gallery Images"
          isRepeater={true}
          fieldArray={[
            {
              name: 'image',
              component: 'file',
              placeholder: 'File',
              label: 'File',
            },
          ]}
        />
      </Flex>
    </Flex>
  );
};

export default AddGalleryImages;
