import React from 'react';
import { useRoutes } from 'react-router-dom';

import AdminLayout from '../containers/AdminLayout';
import { Login } from '../containers/Login';
import AdminUsers from '../containers/AdminUsers/setup';
import Posts from '../containers/Posts/setup';
import Users from '../containers/Users/setup';
import Emojis from '../containers/Emojis/setup';
import Comments from '../containers/Comments/setup';
import Admins from '../containers/Admins/setup';
import Stickers from '../containers/Stickers/setup';
import Avatars from '../containers/Avatars/setup';
import Surveys from '../containers/Surveys/setup';
import Jobs from '../containers/Jobs/setup';
import Documents from '../containers/Documents/setup';
import Feed from '../containers/Feed/setup';
import Messages from '../containers/Messages/setup';
import Branches from '../containers/Branches/setup';
import Notifications from '../containers/Notifications/setup';
import MessageCategories from '../containers/MessageCategories/setup';
import Schedule from '../containers/Schedule/setup';

const routes = [
  {
    path: '/',
    element: React.createElement(AdminLayout),
    children: [
      ...AdminUsers,
      ...Surveys,
      ...Documents,
      ...Jobs,
      ...Posts,
      ...Users,
      ...Branches,
      ...Emojis,
      ...Comments,
      ...Admins,
      ...Stickers,
      ...Avatars,
      ...Feed,
      ...Messages,
      ...Notifications,
      ...MessageCategories,
      ...Schedule,
    ],
  },
  {
    path: '/login',
    element: React.createElement(Login),
  },
];

const XRoutes = () => {
  const routeSetup = useRoutes(routes);

  return routeSetup;
};

export default XRoutes;
