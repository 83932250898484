import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import Cookies from 'js-cookie';

import { generateSlugFromSite } from '../helpers';

const ApolloProviderComponent = ({ children }) => {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API}`,
    fetch: async (uri, options) => {
      const myOptions = { ...options };
      const accessToken = Cookies.get(`${generateSlugFromSite()}-token`);
      // console.log('accessToken', accessToken);
      myOptions.headers.Authorization = `${accessToken}`;
      return fetch(uri, myOptions);
    },
  });

  const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderComponent;
