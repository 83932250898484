import { Flex, Heading } from '@chakra-ui/react';
import React from 'react';

const HeaderWithActions = ({ title, children }) => (
  <Flex w="100%" align="center">
    <Heading fontSize="1.5rem" color="gray.800" fontWeight="600">
      {title}
    </Heading>
    {children}
  </Flex>
);

export default HeaderWithActions;
