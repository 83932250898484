import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
  query BunnyFile_findMany($path: String) {
    bunnyFile_findMany(path: $path) {
      url
      Path
      ObjectName
      IsDirectory
    }
  }
`;

export const FETCH = gql`
  query Jobs_findById($_id: MongoID!) {
    jobs_findById(_id: $_id) {
      name
      _id
      updatedAt
      createdAt
    }
  }
`;

export const CREATE = gql`
  query BunnyFile_GetUploadData(
    $useFileName: Boolean
    $bulk: Boolean
    $path: String
  ) {
    bunnyFile_GetUploadData(
      useFileName: $useFileName
      bulk: $bulk
      path: $path
    ) {
      uploadUrl
      headers {
        projectid
        path
      }
    }
  }
`;
export const CREATE_FOLDER = gql`
  query Query($path: String) {
    bunnyFile_dir_create(path: $path)
  }
`;

export const EDIT = gql`
  mutation Emojis_updateById($_id: MongoID!, $record: UpdateByIdemojisInput!) {
    emojis_updateById(_id: $_id, record: $record) {
      error {
        message
      }
      recordId
      record {
        _id
        name
        unicode
        updatedAt
        createdAt
      }
    }
  }
`;

export const DELETE = gql`
  mutation BunnyFile_delete($url: String!) {
    bunnyFile_delete(url: $url) {
      url
      success
      message
    }
  }
`;
