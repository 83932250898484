import {
  Button,
  Modal,
  ModalOverlay,
  Text,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Flex,
  Box,
  Spinner,
  Icon,
} from '@chakra-ui/react';
import Papa from 'papaparse';
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { HiCheck } from 'react-icons/hi';

import PreviewTable from './PreviewTable';
import { CREATE, UPDATE, DELETE_STAFF, GET_BRANCH_ID } from './graphql';

const BulkUploader = ({ staff, reload }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [parsedCSV, setParsedCSV] = useState([]);
  const [importing, setImporting] = useState(false);
  const [importedSuccess, setImportedSuccess] = useState(false);

  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [importMessage, setImportMessage] = useState([]);

  const [registerUser, { error: createError }] = useMutation(CREATE);
  const [updateUser, { error: updateError }] = useMutation(UPDATE);
  const [getBranchId, { error: branchError }] = useLazyQuery(GET_BRANCH_ID);

  //   const [createResults, setCreateResult] = useState([]);
  //   const [createErros, setCreateErrors] = useState;

  const handleFileChange = e => {
    setSelectedFile(e.target.files[0]);
  };

  // console.log(parsedCSV, 'parsed csv');
  // console.log(staff, 'existing users');

  const [deleteUser, { error: deleteError }] = useMutation(DELETE_STAFF);

  const deleteUserMutation = user => {
    console.log(`Deleting user with ID: ${user.id}`);
    deleteUser({ variables: { filter: { id: user.id } } });
  };

  useEffect(() => {
    if (selectedFile) {
      Papa.parse(selectedFile, {
        header: true,
        skipEmptyLines: true,
        complete: results => {
          const rowsArray = [];
          const valuesArray = [];

          // Iterating data to get column name and their values
          results.data.map(d => {
            rowsArray.push(Object.keys(d));
            valuesArray.push(Object.values(d));
          });

          // Parsed Data Response in array format
          setParsedCSV(results.data);

          // Filtered Column Names
          setColumns(rowsArray[0]);

          // Filtered Values
          setRows(valuesArray);
        },
      });
    }
  }, [selectedFile]);

  const getBranchIdFromCode = async data => {
    const codes = data.map(el => ({ code: el['Cost Centre Code'] }));
    console.log('🚀 ~ getBranchIdFromCode ~ codes:', codes);

    const result = await getBranchId({
      variables: {
        filter: {
          OR: codes,
        },
      },
    });
    console.log(
      '🚀 ~ getBranchIdFromCode ~ result:',
      result?.data?.branches_pagination?.items
    );

    const mergedArray = data.map(item1 => {
      const matchingItem = result?.data?.branches_pagination?.items.find(
        item2 => item1['Cost Centre Code'] === item2.code
      );

      return {
        branchId: matchingItem?._id,
        ...item1,
      };
    });
    // console.log('🚀 ~ mergedArray ~ mergedArray:', mergedArray);
    return mergedArray;
  };

  const iterateArrayWithFeedback = async (eachMemberOfStaff, asyncCallback) => {
    setImporting(true);

    staff?.forEach(existingUser => {
      if (
        !eachMemberOfStaff.find(
          user => user['Personnel Number*'] === existingUser.id
        )
      ) {
        // console.log('!!!!!!should have deleted');
        // deleteUserMutation(existingUser);
      }
    });

    for (let i = 0; i < eachMemberOfStaff.length; i++) {
      try {
        if (
          !staff.find(
            user => user.id === eachMemberOfStaff[i]['Personnel Number*']
          )
        ) {
          const createObject = {
            staffRegistrationId: eachMemberOfStaff[i]['Personnel Number*'],
            password: eachMemberOfStaff[i]['Personnel Number*']
              .split('')
              .reverse()
              .join(''),
            passwordExpires: Date.now(),
            name: eachMemberOfStaff[i]['First Name*'],
            last_name: eachMemberOfStaff[i]['Last Name*'],
            branchCode: eachMemberOfStaff[i]['Cost Centre Code'],
          };

          const result = await asyncCallback({ variables: createObject });
          setParsedCSV(
            parsedCSV.filter(
              el =>
                el['Personnel Number*'] !==
                eachMemberOfStaff[i]['Personnel Number*']
            )
          );

          setImportedSuccess(true);

          setImportMessage(prevArray => [
            ...prevArray,
            `User ${eachMemberOfStaff[i]['Personnel Number*']} processed successfully.`,
          ]);

          // Update UI with feedback for the current iteration
        } else {
          const existingStaff = staff.find(
            user => user.id === eachMemberOfStaff[i]['Personnel Number*']
          );
          const branch_id = eachMemberOfStaff[i]?.branchId;
          const firstName = eachMemberOfStaff[i]['First Name*'] || null;
          const lastName = eachMemberOfStaff[i]['Last Name*'] || null;
          const mongo_id = existingStaff?._id;

          // // Update user
          updateUser({
            variables: {
              record: {
                branch: {
                  _id: branch_id,
                },
                name: firstName,
                last_name: lastName,
              },
              id: mongo_id,
            },
            onCompleted: result => {
              console.log('🚀 ~ iterateArrayWithFeedback ~ result:', result);
              setImportedSuccess(true);
            },
            onError: error => {
              console.log('🚀 ~ iterateArrayWithFeedback ~ error:', error);
            },
          });
        }
      } catch (error) {
        setImportMessage(prevArray => [
          ...prevArray,
          `Error processing item ${eachMemberOfStaff[i]['Personnel Number*']}: ${error.message}`,
        ]);

        // Update UI with error feedback for the current iteration
      } finally {
        // onClose();
        // window.location.reload();
      }
    }
    setImporting(false);
  };

  const handleCreateUsers = async () => {
    // assigned branch id to each user
    const userCSV = await getBranchIdFromCode(parsedCSV);
    // console.log('🚀 ~ handleCreateUsers ~ userCSV:', userCSV);

    await iterateArrayWithFeedback(userCSV, registerUser);
  };
  return (
    <>
      <Button colorScheme="green" onClick={() => setIsOpen(true)}>
        Upload CSV
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          reload(true);
          setIsOpen(false);
        }}
        size="ls"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Bulk staff upload</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {parsedCSV.length < 1 ? (
              <Input
                type="file"
                placeholder="upload users"
                onChange={handleFileChange}
              />
            ) : (
              <Flex direction={'column'}>
                {importedSuccess && (
                  <Flex
                    p={5}
                    alignSelf={'center'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Text textAlign={'center'} fontWeight={'bold'}>
                      Successfully Updated
                    </Text>
                    <Icon color="success" mr="0px" fontSize="25px">
                      <HiCheck />
                    </Icon>
                  </Flex>
                )}

                {!importedSuccess && (
                  <Button
                    mb="25px"
                    onClick={() => handleCreateUsers()}
                    disabled={importing}
                  >
                    Import {parsedCSV.length} users
                  </Button>
                )}

                {importing ? (
                  <Flex direction={'column'}>
                    <Spinner />
                    {importMessage?.reverse()?.map(el => (
                      <Box key={el} bg={el.includes('Error') ? 'red' : 'auto'}>
                        {' '}
                        <Text color={el.includes('Error') ? 'white' : 'auto'}>
                          {el}
                        </Text>
                      </Box>
                    ))}
                  </Flex>
                ) : (
                  <PreviewTable rows={rows} columns={columns} />
                )}
              </Flex>
            )}
          </ModalBody>

          {/* <ModalFooter>
            <Button colorScheme="green" disabled="true">
              Save
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default BulkUploader;
