import React, { useState } from 'react';
import { MdAdd, MdMenu } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { findIndex } from 'lodash';
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  SimpleGrid,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  ButtonGroup,
  Checkbox,
} from '@chakra-ui/react';

const QuestionOptions = ({
  options,
  handleAddOption,
  handleChangeOption,
  handleRemoveOption,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeOption, setActiveOption] = useState(undefined);
  const { register, handleSubmit, reset, watch } = useForm();

  const hasAnswer = watch('hasAnswer');

  const handleShowOptionMenu = (item) => {
    setActiveOption(item);
    setIsOpen(true);
  };

  const onClose = () => {
    reset({});
    setIsOpen(false);
    setActiveOption(undefined);
  };

  const handleFormSubmit = (formData) => {
    const index = findIndex(options, { _id: activeOption._id });
    handleChangeOption(formData, index);

    onClose();
  };

  return (
    <>
      <FormControl>
        <FormLabel>Options</FormLabel>
        <SimpleGrid columns={2} spacing={2}>
          {options &&
            options.map((item, index) => (
              <InputGroup key={item._id}>
                <Input
                  type="text"
                  width="auto"
                  variant="filled"
                  value={item.title}
                  onChange={(e) =>
                    handleChangeOption({ title: e.target.value }, index)
                  }
                />
                <InputRightAddon
                  backgroundColor="blackAlpha.300"
                  paddingY={1}
                  onClick={() => handleShowOptionMenu(item)}
                >
                  <MdMenu />
                </InputRightAddon>
              </InputGroup>
            ))}
          <IconButton
            aria-label="Add Option"
            icon={<MdAdd />}
            onClick={handleAddOption}
          />
        </SimpleGrid>
      </FormControl>
      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <ModalOverlay />
        <ModalContent>
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              return handleSubmit(handleFormSubmit)(e);
            }}
          >
            <ModalHeader>Option</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex flexDirection="column" gap="2">
                <FormControl>
                  <Checkbox {...register('hasAnswer')}>
                    Does this question require an answer?
                  </Checkbox>
                </FormControl>
                {hasAnswer === true && (
                  <FormControl>
                    <FormLabel>Question to option</FormLabel>
                    <Input type="text" {...register('question')} />
                  </FormControl>
                )}
              </Flex>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup variant="outline" spacing="3">
                <Button
                  variant="ghost"
                  colorScheme="red"
                  onClick={() => {
                    handleRemoveOption(activeOption);
                    onClose();
                  }}
                >
                  Remove
                </Button>
                <Button variant="outline" colorScheme="blue" type="submit">
                  Save
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default QuestionOptions;
