import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useColorModeValue,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  IconButton,
  Spinner,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Calendar from 'react-calendar';
import { differenceInCalendarDays } from 'date-fns';
import { TbPencil } from 'react-icons/tb';
import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import {
  DELETE_MANY,
  FETCH_ALL,
  FIRE,
  FETCH_SCHEDULE,
  FETCH,
  DELETE_SCHEDULE,
} from './graphql';
import { setup } from './setup';
import 'react-calendar/dist/Calendar.css';

dayjs.extend(relativeTime);

const filters = [
  {
    value: 'name',
    type: 'text',
    label: 'Name',
    isOperator: false,
  },
];

const List = () => {
  const [fetchData, { loading, error, data, refetch }] =
    useLazyQuery(FETCH_ALL);
  const [
    fetchSingle,
    { data: singleData, loading: singleLoading, error: singleError },
  ] = useLazyQuery(FETCH);
  const [toggleScheduled, setToggleScheduled] = useState(false);

  const { data: scheduleData, refetch: reFectchSchedule } = useQuery(
    FETCH_SCHEDULE,
    {
      variables: {
        page: 1,
        perPage: 999,
        filter: {
          completed: false,
          enabled: true,
        },
      },
    },
  );

  const nav = useNavigate();

  const [sendNotification] = useMutation(FIRE);
  const [removeSchedule, { data: removeData }] = useMutation(DELETE_SCHEDULE);

  const handleRemoveSchedule = (removeID) => {
    removeSchedule({ variables: { id: removeID } });
  };

  useEffect(() => {
    if (removeData) {
      refetch();
      reFectchSchedule();
    }
  }, [removeData]);
  const columns = [
    {
      Header: 'Heading',
      accessor: 'message',
      disableSortBy: false,
      Cell: ({ row, value }) => (
        <Link to={`/${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant="link">{value.title}</Button>
        </Link>
      ),
    },

    {
      Header: 'Reach',
      accessor: 'devices',
      disableSortBy: false,
      Cell: ({ value, row }) => (
        <Box>
          <Text as="b">{row?.original?.message?.data?.[0]?.other}</Text>
          <Text as="small">
            {' '}
            {value?.length} {value?.length > 1 ? ' Devices' : 'Device'}
          </Text>
        </Box>
      ),
    },
    {
      Header: 'Status',
      accessor: 'fired',
      disableSortBy: false,
      Cell: ({ value, row }) => {
        const hasScheduled = scheduleData?.schedules_pagination?.items?.find(
          (el) =>
            el.notifications.find((elel) => elel?._id === row.original._id),
        );
        console.log(hasScheduled, '!!!!! hahshshs');
        return (
          <div>
            {value.status ? (
              <Text>
                Sent on {dayjs(value.date).format('DD/MM/YYYY @ HH:mm')}
              </Text>
            ) : (
              <Flex gap="8px">
                {!hasScheduled && (
                  <Button
                    colorScheme="red"
                    onClick={() =>
                      sendNotification({ variables: { id: row.original._id } })
                    }
                  >
                    Send Now
                  </Button>
                )}
                <Button
                  colorScheme="blue"
                  variant={hasScheduled ? 'link' : 'solid'}
                  onClick={() =>
                    nav(
                      `/${setup.model}/schedule/${
                        row.original[setup.accessor]
                      }`,
                    )
                  }
                >
                  {hasScheduled
                    ? `Scheduled: sending ${dayjs(
                        hasScheduled?.date,
                      ).fromNow()}`
                    : 'Schedule'}
                </Button>
              </Flex>
            )}
          </div>
        );
      },
    },
  ];
  function isSameDay(a, b) {
    return differenceInCalendarDays(a, b) === 0;
  }

  const [datesToAddContentTo, setDatesToAddContentTo] = useState([]);
  useEffect(() => {
    if (scheduleData) {
      setDatesToAddContentTo(
        scheduleData?.schedules_pagination?.items?.map((el) => {
          const jsDate = dayjs(el.date).toDate();

          return {
            date: jsDate,
            _id: el?.notifications?.[0]?._id,
            sched_id: el._id,
          };
        }),
      );
    }
  }, [scheduleData]);
  function tileContent({ date, view }) {
    // Add class to tiles in month view only
    if (view === 'month') {
      const matchindDate = datesToAddContentTo.filter((dDate) =>
        isSameDay(dDate?.date, date),
      );
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      if (matchindDate) {
        return matchindDate.map((eachDate, i) => (
          <Box key={`cal-sche-${i}`}>
            <Popover>
              <PopoverTrigger>
                <Button
                  variant="outline"
                  size="xs"
                  p="0"
                  onClick={() =>
                    fetchSingle({
                      variables: {
                        id: eachDate?._id,
                      },
                    })
                  }
                >
                  @{dayjs(eachDate?.date).format('HH:mm')}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader color="black">
                  {dayjs(eachDate?.date).format('DD/MM/YY @ HH:mm')}
                </PopoverHeader>
                <PopoverBody color="black">
                  {singleLoading ? (
                    <Spinner />
                  ) : (
                    <Flex alignItems={'center'} justifyContent={'center'}>
                      <Box>
                        <Text as="strong" fontSize={'18px'}>
                          {singleData?.notifications_findById?.message?.title}
                        </Text>
                        <Text fontSize={'16px'}>
                          {singleData?.notifications_findById?.message?.body}
                        </Text>
                      </Box>
                      <Link to={`/notifications/edit/${eachDate?._id}`}>
                        <IconButton
                          variant="outline"
                          aria-label="Search database"
                          icon={<TbPencil />}
                        />
                      </Link>
                    </Flex>
                  )}
                </PopoverBody>
                <PopoverFooter>
                  <Button
                    variant="solid"
                    colorScheme="red"
                    size="sm"
                    onClick={() => handleRemoveSchedule(eachDate?.sched_id)}
                  >
                    Remove schedule
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
            {/* </Link> */}
          </Box>
        ));
      }
    }
  }

  return (
    <Meta title={setup.title}>
      <HeaderWithActions>
        {/* <Calendar tileContent={tileContent} /> */}

        <Stack ml="auto" isInline spacing="15px">
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>
      <Box
        mt="25px"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w="100%"
      >
        <ListView
          extra={'hi'}
          setup={setup}
          fetchData={fetchData}
          data={data?.notifications_pagination}
          loading={loading}
          error={error}
          gqlDelete={DELETE_MANY}
          columns={columns}
          filters={filters}
        />
      </Box>
    </Meta>
  );
};

export default List;
