import React, { memo, useState, useEffect, useRef } from 'react';
import {
  Flex,
  Button,
  Text,
  Box,
  Skeleton,
  Stack,
  IconButton,
  useToast,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { useMutation, gql } from '@apollo/client';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useRecoilState, useRecoilValue } from 'recoil';
import { RiCodeSSlashLine } from 'react-icons/ri';
import LoadingBar from 'react-top-loading-bar';
import { pick } from 'lodash';

import InfoBar from './InfoBar';
import DeveloperTools from './DeveloperTools';
import MoreOptions from './MoreOptions';
import { formAtom } from '../../state/form';
import { authAtom } from '../../state/auth';
import LogoHeading from '../../containers/AdminLayout/LogoHeading';
import { Form } from '../Form';
import DeleteButton from '../DeleteButton/Delete';

const EMPTY = gql`
  mutation Empty {
    empty
  }
`;

const SubmitButton = memo(({ setup }) => {
  const params = useParams();
  const { loading } = useRecoilValue(formAtom);
  const isEdit = params.type === 'edit';
  const { submit } = useFormContext();

  return (
    <Button
      colorScheme={'green'}
      bg="green.600"
      rounded="full"
      onClick={() => submit()}
      isLoading={loading}
      loadingText="Submitting "
      // isDisabled={!isValid}
      _disabled={{
        opacity: 0.5,
      }}
      _hover={{ bg: 'green.500' }}
    >
      {isEdit ? `Save ${setup.singular}` : `Save ${setup.singular}`}
    </Button>
  );
});

const FormColumn = props => {
  const { isEdit, data, children, error } = props;
  // console.log('form column', error);

  return (
    <Flex w="100%" h="100%" flex={1} align="center" direction="column">
      {isEdit ? (
        data ? (
          children
        ) : error ? (
          <Alert status="error">
            <AlertIcon />
            There was an error loading this resource. ${error.message}
          </Alert>
        ) : (
          <Box w="90%" h="100%" maxWidth={600} mt={10}>
            <Skeleton variant="rect" h="100px" mb="5px" />
            <Skeleton variant="rect" mb="5px" />
            <Skeleton h="30px" mb="5px" />
            <Skeleton mb="5px" />
            <Skeleton variant="rect" mb="5px" />
            <Skeleton h="30px" mb="5px" />
          </Box>
        )
      ) : (
        children
      )}
    </Flex>
  );
};

const EditView = props => {
  const {
    children,
    data,
    fetchData,
    setup,
    isFullWidth,
    noPadding,
    validationSchema,
    gqlCreate,
    gqlEdit,
    gqlDelete,
    gqlString,
    ignoreValues,
    defaultValues,
    extraParam,
    loading,
    error,
    hideDelete,
    editFields = [],
  } = props;
  const params = useParams();
  const hasMessagesInUrl =
    window.location.href.includes('messages') ||
    window.location.href.includes('comments') ||
    window.location.href.includes('stickers') ||
    window.location.href.includes('avatars');
  const ref = useRef(null);
  const isView = params.type === 'view';
  const isEdit = params.type === 'edit';
  const isSchedule = params.type === 'schedule';
  const isCreate = params.type === 'create';
  const [devTools, setDevTools] = useState(false);
  const toast = useToast();

  const [formState, setFormState] = useRecoilState(formAtom);
  const currentUser = useRecoilValue(authAtom);

  const [create, { data: createData, error: createError }] = useMutation(
    gqlCreate || EMPTY
  );
  const [edit, { error: editError }] = useMutation(gqlEdit);
  const [deleteFunction, { error: deleteError }] = useMutation(gqlDelete);

  React.useState(() => {
    setFormState(old => ({ ...old, errors: null, success: false }));
  }, []);

  useEffect(() => {
    if ((isEdit && currentUser) || (isView && currentUser)) {
      fetchData({
        variables: hasMessagesInUrl ? { id: params.id } : { _id: params.id },
      });
    }
  }, [fetchData, isEdit, isView, currentUser, params.id]);

  useEffect(() => {
    if (createError) {
      setFormState(old => ({ ...old, errors: createError }));
    }
    if (editError) {
      setFormState(old => ({ ...old, errors: editError }));
    }
  }, [createError, editError, setFormState]);

  useEffect(() => {
    if (loading && isEdit && ref && ref.current) {
      ref.current.continuousStart();
    }
    if (!loading && isEdit && ref && ref.current) {
      ref.current.complete();
    }
  }, [loading, ref]);

  const onSubmit = async values => {
    setFormState(old => ({ ...old, loading: true }));

    if (isSchedule) {
      try {
        // const original = { ...values };
        delete values?._id;
        const vals = { ...values };

        await edit({
          variables: {
            record: {
              ...(editFields?.length > 0 ? pick(vals, editFields) : vals),
              enabled: true,
              notifications: [
                {
                  _id: params?.id,
                },
              ],
            },
          },
          //   update: (cache) => {
          //     cache.writeQuery({ query: gqlFetch, broadcast: true }, original);
          //   },
        });
        setFormState(old => ({
          ...old,
          loading: false,
          success: true,
        }));
        toast({
          status: 'success',
          title: 'Entry saved successfully',
          position: 'top-right',
        });
      } catch (e) {
        setFormState(old => ({
          ...old,
          loading: false,
          success: false,
        }));
        toast({
          status: 'error',
          title: 'There was a problem saving this entry',
          position: 'top-right',
        });
      }
    }
    if (isEdit) {
      try {
        // const original = { ...values };
        delete values?._id;
        const vals = { ...values };

        await edit({
          variables: {
            record: editFields?.length > 0 ? pick(vals, editFields) : vals,
            id: params?.id,
          },
          //   update: (cache) => {
          //     cache.writeQuery({ query: gqlFetch, broadcast: true }, original);
          //   },
        });
        setFormState(old => ({
          ...old,
          loading: false,
          success: true,
        }));
        toast({
          status: 'success',
          title: 'Entry saved successfully',
          position: 'top-right',
        });
      } catch (e) {
        setFormState(old => ({
          ...old,
          loading: false,
          success: false,
        }));
        toast({
          status: 'error',
          title: 'There was a problem saving this entry',
          position: 'top-right',
        });
      }
    }
    if (isCreate) {
      try {
        let resp;
        if (extraParam) {
          const __extraParam = { ...values[extraParam] };

          if (__extraParam?.public.length === 0) {
            __extraParam.public = false;
          }
          delete values[extraParam];

          resp = await create({
            variables: { record: values, [extraParam]: __extraParam },
          });
        } else {
          resp = await create({
            variables: { record: values },
          });
        }

        setFormState(old => ({
          ...old,
          loading: false,
          success: true,
        }));
        toast({
          status: 'success',
          title: 'Entry saved successfully',
          position: 'top-right',
        });
        setTimeout(() => {
          if (
            window.location.href.includes('/stickers/create') ||
            window.location.href.includes('/avatars/create')
          ) {
            window.location.href = `/${setup.model}/edit/${resp?.data?.[gqlString].recordId}`;
          } else {
            window.location.href = resp?.data?.[gqlString]
              ? `/${setup.model}/edit/${resp?.data?.[gqlString]?._id}`
              : `/${setup.model}`;
          }
        }, 1000);
      } catch (e) {
        setFormState(old => ({
          ...old,
          loading: false,
        }));
        toast({
          status: 'error',
          title: 'There was a problem saving this entry',
          position: 'top-right',
        });
      }
    }
  };

  return (
    <Form
      data={data && Object.values(data)[0]}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      ignoreValues={ignoreValues}
      defaultValues={defaultValues}
    >
      <Box pos="fixed" zIndex={9999} w="100%" left={0} top={0}>
        <LoadingBar color="#366DDD" ref={ref} />
      </Box>
      <Flex
        align="center"
        direction="column"
        m={noPadding ? '-20px' : '0px'}
        minHeight="100vh"
      >
        <Flex
          w="100%"
          align="center"
          h="3.9rem"
          pos="relative"
          zIndex={6}
          position="fixed"
          top={0}
          left={0}
          bg="gray.900"
          px="15px"
        >
          <LogoHeading noFlex />
          <Button
            ml="50px"
            variant="ghost"
            colorScheme="whiteAlpha"
            size="sm"
            display={{ base: 'none', lg: 'flex' }}
            mr={2}
            onClick={() => (window.location.href = `/${setup.model}`)}
            leftIcon={<MdKeyboardArrowLeft fontSize="30px" />}
          >
            View all {setup.model}
          </Button>
          <Text
            as="h1"
            fontSize="lg"
            fontWeight="semibold"
            ml="25px"
            display={{ base: 'none', md: 'inline-block' }}
            color="white"
          >
            {isEdit ? 'Edit' : 'Create New '} {setup.singular}
          </Text>

          <Stack h="35px" ml="auto" isInline spacing="10px" mr="10px">
            <MoreOptions setup={setup} />
            {!isEdit && <SubmitButton setup={setup} />}

            <InfoBar />
            {process.env.REACT_APP_ENVIRONMENT === 'development' && (
              <IconButton
                onClick={() => setDevTools(!devTools)}
                rounded="md"
                fontSize="16px"
                colorScheme="gray"
                bg="gray.700"
                color="white"
                mr="0px"
                display={{ base: 'none', xl: 'flex' }}
              >
                <RiCodeSSlashLine />
              </IconButton>
            )}
          </Stack>
        </Flex>

        {/* <ErrorMessage /> */}
        <Flex w="100%" pb="50px" flex={1} justify="flex-start">
          <FormColumn
            data={data ? Object.values(data)[0] : null}
            isEdit={isEdit}
            isView={isView}
            isFullWidth={isFullWidth}
            error={error}
          >
            {children}
          </FormColumn>
          {isEdit && gqlDelete && !hideDelete && (
            <DeleteButton
              id={params.id}
              setup={setup}
              deleteFunction={deleteFunction}
            />
          )}

          {process.env.REACT_APP_ENVIRONMENT === 'development' && (
            <Flex
              direction="column"
              w="100%"
              h="100%"
              overflowY="scroll"
              maxWidth="350px"
              flexGrow={0}
              bg="#1a1a1e"
              position="fixed"
              right={0}
              zIndex={8000}
              top={0}
              willChange="transform"
              transition="all 0.3s ease"
              transform={devTools ? 'translateX(0%)' : 'translateX(100%)'}
            >
              {devTools && (
                <DeveloperTools setDevTools={setDevTools} devTools={devTools} />
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Form>
  );
};

EditView.defaultProps = {
  isFullWidth: true,
};

export default EditView;
