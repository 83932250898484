import React from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Box, Button, Stack, useColorModeValue } from '@chakra-ui/react';

import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import { FETCH_ALL, DELETE_ID } from './graphql';
import { setup } from './setup';

const columns = [
  {
    Header: '',
    accessor: '_id',
    disableSortBy: true,
    Cell: ({ row }) => (
      <Link to={`/${setup.model}/edit/${row.original[setup.accessor]}`}>
        <Button variant="link">View</Button>
      </Link>
    ),
  },
  {
    Header: 'Comment',
    accessor: 'content',
    disableSortBy: false,
    Cell: ({ value }) => <div>{value}</div>,
  },
];

const filters = [
  {
    value: 'comment',
    type: 'text',
    label: 'Comment',
    isOperator: false,
  },
];

const List = () => {
  const [fetchData, { loading, error, data }] = useLazyQuery(FETCH_ALL);

  // const [commentsData, setCommentsData] = useState([]);

  // // useEffect(() => {
  // //   if (data) {
  // //     const items = data?.comment_bank_pagination?.items.map(item => ({
  // //       name: item.content,
  // //     }));
  // //     const modifiedData = [
  // //       {
  // //         ...data.comment_bank_pagination,
  // //         items,
  // //       },
  // //     ];
  // //     setCommentsData(modifiedData[0]);
  // //     console.log('modifiedData', modifiedData[0]);
  // //   }
  // // }, [data]);

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml="auto" isInline spacing="15px">
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>
      <Box
        mt="25px"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w="100%"
      >
        <ListView
          setup={setup}
          fetchData={fetchData}
          data={data?.comment_bank_pagination}
          loading={loading}
          error={error}
          gqlDelete={DELETE_ID}
          columns={columns}
          filters={filters}
        />
      </Box>
    </Meta>
  );
};

export default List;
