import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { RecoilRoot } from 'recoil';
import { CloudinaryContext } from 'cloudinary-react';

import ApolloProvider from './providers/ApolloProvider';
import theme from './theme';
import XRoutes from './routes';
import config from './config.json';
import XProvider from './providers/XProvider';
import CloudinaryProvider from './providers/CloudinaryProvider';

import 'react-datepicker/dist/react-datepicker.min.css';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
// import { authAtom } from './state/auth';

function App() {
  return (
    <RecoilRoot>
      <ApolloProvider>
        <ChakraProvider theme={theme}>
          <GlobalStyles>
            <XProvider config={config}>
              <CloudinaryContext
                cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
              >
                <CloudinaryProvider>
                  <XRoutes />
                </CloudinaryProvider>
              </CloudinaryContext>
            </XProvider>
          </GlobalStyles>
        </ChakraProvider>
      </ApolloProvider>
    </RecoilRoot>
  );
}

export default App;

const GlobalStyles = styled.div`
  .react-datepicker__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .react-datepicker__navigation {
    top: 8px;
  }
  .react-datepicker {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    .react-datepicker__day {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 0.95rem;

      &.react-datepicker__day--selected {
        background-color: #2b2871;
      }
    }

    .react-datepicker__header {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      .react-datepicker__current-month {
        font-weight: 500;
        font-size: 1.15rem;
      }
    }

    .react-datepicker__day-name {
      width: 30px;
      padding: 3px;
      font-weight: 500;
    }

    .react-datepicker__header__dropdown--select {
      margin-top: 5px;
    }

    .react-datepicker__day--today {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 100%;
    }
    .react-datepicker__month-select,
    .react-datepicker__year-select {
      height: 25px;
      border-radius: 3px;
      padding-left: 5px;
      padding-right: 5px;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  .react-datepicker__close-icon::after {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
`;
