import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
  query Branches_pagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManybranchesInput
    $sort: SortFindManybranchesInput
  ) {
    branches_pagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        code
        _id
        value
        updatedAt
        createdAt
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const FETCH = gql`
  query Branches_findById($_id: MongoID) {
    branches_findById(_id: $_id) {
      _id
      code
      value
    }
  }
`;

export const CREATE = gql`
  mutation Owner_create($record: UpdateByIdownerInput!) {
    owner_create(record: $record) {
      payload
    }
  }
`;

export const EDIT = gql`
  mutation Owner_updateById($record: UpdateByIdownerInput!, $_id: MongoID) {
    owner_updateById(record: $record, _id: $_id) {
      error {
        message
      }
      record {
        _id
      }
    }
  }
`;

export const DELETE = gql`
  mutation Owner_remove($_id: MongoID!) {
    owner_remove(_id: $_id) {
      payload
    }
  }
`;

export const FETCH_LEGACY = gql`
  query Owner_findLegacy($filter: legacy_ownerInput) {
    owner_findLegacy(filter: $filter) {
      id
      email
      full_name
    }
  }
`;

export const MIGRATE_OWNER = gql`
  mutation Owner_migrate($id: String) {
    owner_migrate(id: $id) {
      _id
      email
      user {
        _id
        created
      }
    }
  }
`;
