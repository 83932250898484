import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  Spinner,
  Stack,
  Text,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import { AiOutlineDownload } from 'react-icons/ai';

import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import { DELETE, FETCH_ALL } from './graphql';
import { FETCH_ALL as FETCH_ALL_STAFF } from '../Users/graphql';
import { setup } from './setup';

const filters = [];

const List = () => {
  const [fetchData, { loading, error, data, refetch }] =
    useLazyQuery(FETCH_ALL);

  const columns = [
    {
      Header: 'Branch code',
      accessor: 'code',
      disableSortBy: false,
      Cell: ({ value, row }) => (
        <Link to={`/branches/edit/${row.original._id}`}>{value}</Link>
      ),
    },
    {
      Header: 'Registered Staff',
      accessor: '_id',
      disableSortBy: false,
      Cell: ({ value, row }) => {
        const [
          fetchStaff,
          { loading: staffLoading, error: staffError, data: staffData },
        ] = useLazyQuery(FETCH_ALL_STAFF);

        useEffect(() => {
          if (value) {
            fetchStaff({
              variables: {
                filter: {
                  acceptedTerms: true,
                  branch: {
                    _id: value,
                  },
                },
                page: 1,
                perPage: 999,
              },
            });
          }
        }, [value]);

        const handleDoTheIDs = () => {
          const branchStaff = staffData?.staff_pagination?.items?.map(
            (el) => el.id
          );

          console.log(branchStaff);

          const csvContent =
            'data:text/csv;charset=utf-8,' + branchStaff.join('\n');
          let yourDate = new Date();

          const encodedUri = encodeURI(csvContent);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute(
            'download',
            `${row.original.code}-${yourDate.toISOString().split('T')[0]}.csv`
          );
          document.body.appendChild(link); // Required for FF

          link.click(); // This will download the data file named "data.csv".

          // Cleanup
          document.body.removeChild(link);
        };

        return staffLoading ? (
          <Spinner />
        ) : staffData?.staff_pagination?.count ? (
          <Flex justifyContent={'start'} alignItems={'center'}>
            <Box w="48px">
              <Text as={'strong'}>{staffData?.staff_pagination?.count}</Text>
            </Box>

            <IconButton
              variant="outline"
              onClick={() => handleDoTheIDs()}
              aria-label="Download"
              icon={<AiOutlineDownload />}
            />
          </Flex>
        ) : (
          '0'
        );
      },
    },
  ];

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml="auto" isInline spacing="15px">
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>

      <Box
        mt="25px"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w="100%"
      >
        <ListView
          setup={setup}
          fetchData={fetchData}
          data={data?.branches_pagination}
          loading={loading}
          error={error}
          gqlDelete={DELETE}
          columns={columns}
          filters={filters}
        />
      </Box>
    </Meta>
  );
};

export default List;
