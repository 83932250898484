export const reactSelectTheme = (theme) => ({
  ...theme,

  colors: {
    ...theme.colors,
    primary25: '#BFD7F8',
    primary75: '#104389',
    primary: '#1A6FE5',
  },
});
