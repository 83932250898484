import { gql, useLazyQuery } from '@apollo/client';
import { Flex, Spinner } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
// import { Login } from '../containers/Login';
import { authAtom } from '../state/auth';

const CHECK_AUTH = gql`
  query Admins_findById {
    admins_findById {
      _id
      email
    }
  }
`;

const AuthProvider = ({ children }) => {
  const [checkAuth, { data, loading, error }] = useLazyQuery(CHECK_AUTH);
  const [auth, setAuth] = useRecoilState(authAtom);
  // console.log('checkAuth', { data, loading, error });

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (data && data.admins_findById) {
      setAuth({ success: true });
    }
  }, [data, setAuth]);

  if (loading) {
    return (
      <Flex w="100vw" h="100vh" align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }
  if (!auth && error) {
    return <Navigate to="/login" />;
  }

  return <div>{children}</div>;
};

export default AuthProvider;
