import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
  query MessagestreamsCategories_pagination {
    messagestreamsCategories_pagination {
      count
      items {
        name
        _id
        updatedAt
        createdAt
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const FETCH = gql`
  query MessagestreamsCategories_findById($id: MongoID!) {
    messagestreamsCategories_findById(_id: $id) {
      _id
      createdAt
      name
      updatedAt
    }
  }
`;

export const CREATE = gql`
  mutation MessagestreamsCategories_createOne(
    $record: CreateOnemessagestreamsCategoriesInput!
  ) {
    messagestreamsCategories_createOne(record: $record) {
      record {
        name
        _id
        createdAt
        updatedAt
      }
    }
  }
`;

export const EDIT = gql`
  mutation Mutation(
    $id: MongoID!
    $record: UpdateByIdmessagestreamsCategoriesInput!
  ) {
    messagestreamsCategories_updateById(_id: $id, record: $record) {
      record {
        name
        _id
      }
      recordId
    }
  }
`;

export const DELETE_ID = gql`
  mutation Mutation($id: MongoID!) {
    messagestreamsCategories_removeById(_id: $id) {
      recordId
      record {
        _id
        name
      }
    }
  }
`;

// needs backend function building
export const DELETE_MANY = gql`
  mutation Mutation($id: MongoID!) {
    messagestreamsCategories_removeById(_id: $id) {
      recordId
      record {
        _id
        name
      }
    }
  }
`;
