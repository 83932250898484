import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery, useMutation, gql } from '@apollo/client';
import { subYears, subDays } from 'date-fns';
import {
  Grid,
  Stack,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  Flex,
  useToast,
  TabPanels,
  Text,
  Box,
  Input,
  Button,
  Spinner,
  Link,
} from '@chakra-ui/react';
import * as PDFJS from 'pdfjs-dist/build/pdf';
import * as PDFJSWorker from 'pdfjs-dist/build/pdf.worker';
import { UPLOAD_DATA } from '../../Fields/File/graphql';
import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import {
  CREATE,
  FETCH,
  EDIT_JOB_SPEC,
  FETCH_BENEFITS,
  DELETE_ID,
  CREATE_SCHEDULE,
} from './graphql';
import { setup } from './setup';
import FeedFields from '../../components/Feed/Fields';
import { FETCH_ALL as BRANCH_FETCH_ALL } from '../Branches/graphql';
import ScheduleNotification from '../../components/ScheduleNotification';

const EDIT_STATIC_PAGE = gql`
  mutation Staticpage_updateById(
    $id: MongoID!
    $record: UpdateByIdstaticpageInput!
  ) {
    staticpage_updateById(_id: $id, record: $record) {
      record {
        content
      }
    }
  }
`;
PDFJS.GlobalWorkerOptions.workerSrc = PDFJSWorker;

const fields = [
  {
    name: 'feed.jobspec.status',
    label: 'Status',
    targetProperty: 'status',
  },
  {
    name: 'feed.jobspec.salary',
    label: 'Salary',
    targetProperty: 'salary',
  },
  {
    name: 'feed.jobspec.type',
    label: 'Type',
    targetProperty: 'type',
  },
  {
    name: 'feed.jobspec.address',
    label: 'Address',
    targetProperty: 'address',
  },
];

const SingleInput = ({ _id, name, targetProperty, label, loading, edit }) => (
  <Field
    component="text"
    name={name}
    placeholder={label}
    rules={{ required: true }}
    customUpdate={edit}
    disbled={loading}
    targetProperty={targetProperty}
    targetID={_id}
  />
);

window.PDFJS = PDFJS;

const Index = ({ title }) => {
  const params = useParams();
  const toast = useToast();
  const [selectedBenefits, setSelectedBenefits] = useState([]);

  const { data: branchData } = useQuery(BRANCH_FETCH_ALL);
  const [selectedFile, setSelectedFile] = useState();

  const [fetchData, { data, loading, error, refetch }] = useLazyQuery(FETCH);
  const [imageUrl, setImageUrl] = useState();

  const { data: benefitsData } = useQuery(FETCH_BENEFITS);
  const [
    editJobSpec,
    { data: jobSpecData, loading: jobSpecLoading, error: jobSpecError },
  ] = useMutation(EDIT_JOB_SPEC);

  const [
    editSaticPage,
    {
      data: editStaticData,
      loading: editStaticLoading,
      error: editStaticError,
    },
  ] = useMutation(EDIT_STATIC_PAGE);

  const [
    editSaticPageEmail,
    { data: editStaticEmailData, loading: editStaticEmailLoading },
  ] = useMutation(EDIT_STATIC_PAGE);

  const [createJobSpec] = useMutation(CREATE);
  const [fetchLoading, setLoading] = useState(false);
  const [applyEmail, setApplyEmail] = useState('');
  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);
  const [call, { called, data: uploadData }] = useLazyQuery(UPLOAD_DATA, {
    variables: {
      storagePath: '/staffAppStatic',
    },
  });
  useEffect(() => {
    if (imageUrl) {
      editSaticPage({
        variables: {
          record: {
            content: { pdfFile: imageUrl },
          },
          id: data?.jobs_findById?.feed?.jobspec?._id,
        },
      });
      setLoading(false);
    }
  }, [imageUrl, data?.jobs_findById?.feed?.jobspec?._id, editSaticPage]);

  useEffect(() => {
    const benefits = data?.jobs_findById?.feed?.jobspec?.benefits;
    if (benefits) {
      setSelectedBenefits(benefits);
    }
  }, [data?.jobs_findById?.feed?.jobspec?.benefits]);

  useEffect(() => {
    if (data?.jobs_findById?.feed?.staticpage?.cta?.email) {
      setApplyEmail(data?.jobs_findById?.feed?.staticpage?.cta?.email);
    }
  }, [data?.jobs_findById?.feed?.staticpage?.cta?.email]);

  useEffect(() => {
    const jobId = data?.jobs_findById?.feed?.jobspec?._id;
    if (selectedBenefits.length > 0 && jobId) {
      const __selectedBenefits = selectedBenefits.map((el) => ({
        _id: el._id,
      }));
      editJobSpec({
        variables: {
          record: {
            benefits: __selectedBenefits,
          },

          _id: jobId,
        },
      });
    }
  }, [selectedBenefits, data?.jobs_findById?.feed?.jobspec?._id]);

  useEffect(() => {
    if (jobSpecData) {
      toast({
        title: 'Saved',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }

    if (jobSpecError) {
      toast({
        title: 'Error, try again',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [jobSpecData, jobSpecError]);

  useEffect(() => {
    if (selectedFile) {
      setSelectedFile(selectedFile);
    }
  }, [selectedFile]);

  const handleClickBenefit = (selectedBenefit) => {
    if (selectedBenefits?.includes(selectedBenefit)) {
      setSelectedBenefits(
        selectedBenefits.filter((el) => el._id !== selectedBenefit._id)
      );
    } else {
      setSelectedBenefits([...selectedBenefits, selectedBenefit]);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', selectedFile);
    // let url = `${process.env.NEXT_PUBLIC_BACKEND_HOST}${data?.bunnyFile_GetUploadData?.uploadPath}`;

    const requestOptions = {
      method: 'POST',
      'Content-Type': 'multipart/form-data; boundary=something',
      redirect: 'follow',
      headers: uploadData?.bunnyFile_GetUploadData?.headers,
      body: formData,
    };
    fetch(uploadData?.bunnyFile_GetUploadData?.uploadUrl, requestOptions)
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .then((result) => {
        if (result) {
          setImageUrl(result.imageUrl);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log('error', error);
      });
  };

  const makeBunnyCall = () => {
    call();
  };

  const handleFileUpload = async (event) => {
    setSelectedFile(event.target.files[0]);
    makeBunnyCall();
  };

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT_JOB_SPEC}
        gqlCreate={CREATE}
        gqlDelete={DELETE_ID}
        gqlString={'jobs_createOne'}
      >
        <>
          <Flex direction="column" maxWidth="75%" w="100%">
            <Tabs
              orientation="vertical"
              w="100%"
              m="0"
              p="0"
              ml="-40px"
              align="start"
            >
              <TabList>
                <Tab justifyContent={'flexStart'}>General</Tab>
                {type === 'Edit' && (
                  <Tab justifyContent={'flexStart'}>Specs</Tab>
                )}
                {type === 'Edit' && (
                  <Tab justifyContent={'flexStart'}>Benefits</Tab>
                )}

                {type === 'Edit' && (
                  <Tab justifyContent={'flexStart'}>Upload</Tab>
                )}
                {type === 'Edit' && (
                  <Tab justifyContent="flexStart" textAlign="left">
                    Schedule Notification
                  </Tab>
                )}
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Grid
                    templateColumns={{ base: '1fr' }}
                    w="100%"
                    maxWidth="1000px"
                    gap="25px"
                  >
                    <Stack spacing="20px" w="100%">
                      <Stack spacing="20px" w="100%">
                        <FormSection title={title}>
                          <FormSectionInner>
                            <FeedFields
                              targetID={data?.jobs_findById?.feed?._id}
                              feed={data?.jobs_findById?.feed}
                              refetch={refetch}
                            />
                          </FormSectionInner>
                        </FormSection>
                      </Stack>
                    </Stack>
                  </Grid>
                </TabPanel>
                {type === 'Edit' && (
                  <TabPanel>
                    <Grid
                      templateColumns={{ base: '1fr' }}
                      w="100%"
                      maxWidth="1000px"
                      gap="25px"
                    >
                      <Stack spacing="20px" w="100%">
                        <FormSection title="Update">
                          <FormSectionInner>
                            <Field
                              component="richtext"
                              name="feed.jobspec.description"
                              defaultValue={
                                data?.jobs_findById?.feed?.jobspec?.description
                              }
                              placeholder="Description"
                              rules={{ required: true }}
                              customUpdate={editJobSpec}
                              disbled={jobSpecLoading}
                              targetProperty="description"
                              targetID={data?.jobs_findById?.feed?.jobspec?._id}
                            />
                            <Field
                              component="switch"
                              name="feed.jobspec.hireMultiple"
                              placeholder="Hiring Multiple Candidates"
                              onChange={(e) => {
                                editJobSpec({
                                  variables: {
                                    record: {
                                      hireMultiple: e,
                                    },
                                    _id: data?.jobs_findById?.feed?.jobspec
                                      ?._id,
                                  },
                                });
                              }}
                              disabled={jobSpecLoading}
                              targetID={data?.jobs_findById?.feed?.jobspec?._id}
                            />
                            {fields.map((each, i) => (
                              <SingleInput
                                key={`${each.name}-myitem-${i}`}
                                _id={data?.jobs_findById?.feed?.jobspec?._id}
                                name={each.name}
                                label={each.label}
                                loading={jobSpecLoading}
                                edit={editJobSpec}
                                targetProperty={each.targetProperty}
                              />
                            ))}
                            <Text fontWeight="medium">Apply to email</Text>
                            <Input
                              onChange={(e) => setApplyEmail(e.target.value)}
                              onBlur={() => {
                                editSaticPageEmail({
                                  variables: {
                                    record: {
                                      cta: {
                                        email: applyEmail,
                                      },
                                    },
                                    id: data?.jobs_findById?.feed?.jobspec?._id,
                                  },
                                  onCompleted: (res) => {
                                    if (res) {
                                      toast({
                                        title: 'Saved',
                                        status: 'success',
                                        duration: 2000,
                                        isClosable: true,
                                      });
                                    }
                                  },
                                });
                              }}
                              onFocus={() => {
                                // onFocus && onFocus();
                              }}
                              placeholder="email"
                              value={applyEmail}
                              required={false}
                              isRequired={false}
                              minWidth={type === 'number' ? '100px' : '200px'}
                              type={type}
                              size="md"
                            />
                          </FormSectionInner>
                        </FormSection>
                      </Stack>
                    </Grid>
                  </TabPanel>
                )}
                <TabPanel>
                  <Grid>
                    <Stack>
                      <FormSection>
                        <FormSectionInner>
                          <Flex direction={'column'}>
                            {benefitsData?.jobbenefits_pagination?.items?.map(
                              (benefit) => (
                                <Box
                                  key={benefit?._id}
                                  p="1rem"
                                  onClick={() => handleClickBenefit(benefit)}
                                  cursor={'pointer'}
                                >
                                  <Text
                                    as={
                                      selectedBenefits?.find(
                                        (el) => el._id === benefit._id
                                      ) && 'strong'
                                    }
                                  >
                                    {benefit?.title}
                                  </Text>
                                </Box>
                              )
                            )}
                          </Flex>
                        </FormSectionInner>
                      </FormSection>
                    </Stack>
                  </Grid>
                </TabPanel>
                {type === 'Edit' && (
                  <TabPanel>
                    <Grid>
                      <Stack>
                        <FormSection title="Upload">
                          <FormSectionInner>
                            <Flex>
                              <Input
                                type="file"
                                onChange={(e) => handleFileUpload(e)}
                              />
                            </Flex>
                            {(imageUrl ||
                              data?.jobs_findById?.feed?.staticpage?.content
                                ?.pdfFile) && (
                              <Box
                                alignSelf={'center'}
                                width={'auto'}
                                borderWidth={1}
                              >
                                <Link
                                  href={
                                    !imageUrl
                                      ? data?.jobs_findById?.feed?.staticpage
                                          ?.content?.pdfFile
                                      : imageUrl
                                  }
                                  target="_blank"
                                >
                                  <Text p="1%" textDecorationLine={'underline'}>
                                    View PDF
                                  </Text>
                                </Link>
                              </Box>
                            )}
                          </FormSectionInner>
                        </FormSection>

                        {selectedFile && (
                          <Button
                            w="100%"
                            type="submit"
                            onClick={(e) => handleSubmit(e)}
                            disabled={imageUrl && !fetchLoading}
                          >
                            {fetchLoading ? (
                              <Spinner color="blue" />
                            ) : imageUrl ? (
                              'Upload Completed'
                            ) : (
                              'Upload'
                            )}
                          </Button>
                        )}
                      </Stack>
                    </Grid>
                  </TabPanel>
                )}
                {type === 'Edit' && (
                  <TabPanel>
                    <ScheduleNotification id={params?.id} />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </Flex>
        </>
      </EditView>
    </Meta>
  );
};

export default Index;
