import React, { memo } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';
import ReactJson from 'react-json-view';
import { use100vh } from 'react-div-100vh';

const DeveloperTools = memo(({ setDevTools, devTools }) => {
  const values = useWatch();
  const height = use100vh();

  return (
    <Box height={height} maxHeight={height} overflow="scroll">
      <Button
        onClick={() => setDevTools(!devTools)}
        mt="5px"
        ml="5px"
        variant="outline"
        color="white"
        size="sm"
        _hover={{ bg: 'gray.800' }}
      >
        Close
      </Button>
      {devTools && (
        <ReactJson
          src={values}
          displayDataTypes={false}
          collapseStringsAfterLength={100}
          style={{
            height: 'auto',
            minHeight: '100%',
            fontSize: '12px',
            padding: '8px',
            paddingBottom: '100px',
            width: 'auto',
          }}
          theme={{
            base00: '#1a1a1e',
            base01: '#4E4E4E',
            base02: 'rgba(255, 255, 230, 0.8)',
            base03: 'rgba(255, 255, 230, 0.5)',
            base04: '#B988FF',
            base05: '#444',
            base06: '#444',
            base07: '#88E8FF',
            base08: 'rgba(255, 255, 230, 1)',
            base09: 'rgba(255, 255, 230, 1)',
            base0A: 'rgba(255, 255, 230, 1)',
            base0B: 'rgba(255, 255, 230, 1)',
            base0C: 'rgba(255, 255, 230, 1)',
            base0D: 'rgba(255, 255, 230, 1)',
            base0E: 'rgba(255, 255, 230, 1)',
            base0F: 'rgba(89, 169, 226, 1)',
          }}
        />
      )}
    </Box>
  );
});

export default DeveloperTools;
