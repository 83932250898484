const Button = {
  defaultProps: {
    colorScheme: 'blue',
  },
  baseStyle: {},
  variants: {
    solid: () => ({
      _disabled: {
        opacity: 1,
      },
      _focus: {
        boxShadow: 'none',
      },
      boxShadow:
        'rgba(0, 0, 0, 0.05) 0px 1px 0px 0px, rgba(0, 0, 0, 0.2) 0px -1px 0px 0px inset',
    }),
  },
};

export default Button;
