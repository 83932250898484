import { gql } from '@apollo/client';

export const CREATE = gql`
  mutation BunnyFile_CreateOne($record: CreateOnebunnyFileInput!) {
    bunnyFile_CreateOne(record: $record) {
      recordId
      record {
        imageUrl
        title
        _id
        updatedAt
        createdAt
        url
        Path
        ObjectName
        IsDirectory
      }
      error {
        message
      }
    }
  }
`;

export const UPLOAD_DATA = gql`
  query BunnyFile_GetUploadData(
    $useFileName: Boolean
    $bulk: Boolean
    $path: String
  ) {
    bunnyFile_GetUploadData(
      useFileName: $useFileName
      bulk: $bulk
      path: $path
    ) {
      uploadUrl
      headers {
        projectid
        path
      }
    }
  }
`;
