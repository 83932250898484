import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { MdRemoveCircle, MdAddCircleOutline } from 'react-icons/md';
import { RiFilterLine } from 'react-icons/ri';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useRecoilState } from 'recoil';
import {
  Flex,
  Stack,
  Box,
  Input,
  MenuList,
  Menu,
  MenuButton,
  Button,
} from '@chakra-ui/react';

import { tableFilterAtom } from './atoms';
import { reactSelectTheme } from '../../theme/ReactSelect';

const customStyles = {
  control: (base, state) => ({
    ...base,
    height: 30,
    minHeight: 30,
    borderWidth: 0,
    borderRadius: 0,
    boxShadow: 'none',

    borderTopLeftRadius: state.selectProps.roundedLeft ? '5px' : 0,
    borderBottomLeftRadius: state.selectProps.roundedLeft ? '5px' : 0,
    borderTopRightRadius: state.selectProps.roundedRight ? '5px' : 0,
    borderBottomRightRadius: state.selectProps.roundedRight ? '5px' : 0,
    ':hover': {
      boxShadow: 'none',
    },
  }),
  menu: (base) => ({
    ...base,
    minWidth: 200,
  }),
  option: (base) => ({
    ...base,
    fontSize: 12,
  }),

  input: (base, state) => ({
    ...base,
    margin: 0,
    minWidth: state.selectProps.minWidth ? state.selectProps.minWidth : 30,
  }),
  valueContainer: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: 0,
    paddingBottom: 0,
  }),
  placeholder: (base, state) => ({
    ...base,
    fontSize: 12,
    color: state.selectProps.hasBg ? '#2b6da3' : 'rgba(0,0,0,0.5)',
  }),
  singleValue: (base, state) => ({
    ...base,
    fontSize: 12,
    fontWeight:
      state.selectProps.hasBg || state.selectProps.isOperator
        ? 'bold'
        : 'normal',
    backgroundColor: state.selectProps.isOperator ? '#dfeaff' : 'white',
    color: state.selectProps.isOperator ? '#2563d6' : 'black',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: state.selectProps.isOperator ? '#467ee6' : 'white',
    paddingLeft: state.selectProps.isOperator ? '4px' : '0px',
    paddingRight: state.selectProps.isOperator ? '4px' : '0px',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    '*': {
      padding: 0,
    },
  }),
  multiValueLabel: (base) => ({
    ...base,
    fontSize: 10,
    fontWeight: '500',
    color: 'white',
    backgroundColor: '#366DDD',
  }),
};

const OperatorSearchSelect = ({ filters, defaultFilter, setup }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [dataFilters, setFilters] = useRecoilState(tableFilterAtom);

  const setActiveFilters = (fil) => {
    setFilters({ ...defaultFilter, ...fil });
  };

  const addNew = (item) => {
    const newFilter = {};
    newFilter[item] = undefined;
    setSelectedFilters((old) => [...old, newFilter]);
  };

  const refactorObject = (val) => {
    const non_operators = [];
    const operators = [];

    val.forEach((filter) => {
      if (typeof Object.values(filter)[0] === 'object') {
        operators.push(filter);
      } else {
        non_operators.push(filter);
      }
    });

    return {
      ..._.assign.apply(_, non_operators),
    };
  };

  const handleChange = (v, i) => {
    const fil = [...selectedFilters];

    fil[i] = v;
    setSelectedFilters(fil);
    setActiveFilters(refactorObject(fil));
  };

  const remove = (i) => {
    const fil = [...selectedFilters];
    fil.splice(i, 1);

    setSelectedFilters(fil);
    setActiveFilters(refactorObject(fil));
  };

  return setup.canFilter ? (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<RiFilterLine fontSize="18px" style={{ opacity: 0.5 }} />}
      >
        Filters
      </MenuButton>
      <MenuList>
        <Flex direction="column" px="10px">
          {selectedFilters.map((fil, i) => (
            <Field
              key={`filter-${i}`}
              i={i}
              filters={filters}
              onChange={handleChange}
              remove={remove}
              item={fil}
            />
          ))}
          {filters.map((item) => (
            <Button
              key={`filter-${item.value}`}
              isFullWidth
              size="sm"
              variant="solid"
              bg="blue.50"
              color="blue.500"
              colorScheme="blue"
              marginTop="5px"
              onClick={() => addNew(item.value)}
              rightIcon={<MdAddCircleOutline fontSize="18px" />}
              _hover={{ color: 'white', bg: 'blue.400' }}
            >
              Add new {item.label} filter
            </Button>
          ))}
        </Flex>
      </MenuList>
    </Menu>
  ) : null;
};

const Field = ({ filters, onChange, i, remove, item }) => {
  const [construct, setConstruct] = useState({
    name: null,
    operator: null,
    value: null,
  });
  const [value, setValue] = useState({});

  const setOperatorName = (v) => {
    const _val = {};
    if (v.isOperator) {
      _val[v.value] = { [v.operators[0].value]: null };
    } else {
      _val[v.value] = null;
    }
    setConstruct(() => ({
      name: v,
      operator: v.isOperator ? v.operators[0] : null,
      value: null,
    }));
    setValue(_val);
  };

  const setOperatorFunction = (v) => {
    const _val = { ...value };
    _val[construct.name.value] = { [v.value]: null };

    setConstruct((old) => ({ ...old, operator: v, value: null }));
    setValue(_val);
  };

  const setOperatorValue = (v) => {
    const _val = { ...value };
    if (construct.name.isOperator) {
      const obj = { ..._val[construct.name.value] };
      if (construct.name.selectProps.isMulti) {
        obj[construct.operator.value] = v.map(
          (_v) => _v[construct.name.selectProps.selectValue],
        );
      } else {
        obj[construct.operator.value] = construct.name.selectProps.selectValue
          ? v[construct.name.selectProps.selectValue]
          : v;
      }
      _val[construct.name.value] = obj;
    } else if (construct.name.type === 'select') {
      _val[construct.name.value] = construct.name.selectProps.selectValue
        ? v[construct.name.selectProps.selectValue]
        : v;
    } else {
      _val[construct.name.value] = v;
    }

    setConstruct((old) => ({ ...old, value: v }));
    setValue(_val);
  };

  useEffect(() => {
    const currentFilter = filters.find(
      (fil) => fil.value === Object.keys(item)[0],
    );
    setConstruct((old) => ({
      ...old,
      name: currentFilter,
    }));
    if (filters.length !== 0) {
      setValue({
        [currentFilter.value]: null,
      });
    }
  }, [filters]);

  useEffect(() => {
    if (construct.name && construct.name.isOperator) {
      if (construct.name && construct.operator && construct.value) {
        onChange(value, i);
      }
    } else if (construct.name && construct.value) {
      onChange(value, i);
    }
  }, [construct]);

  return (
    <Stack
      align="center"
      justify="flex-start"
      border="1px"
      borderColor="gray.300"
      rounded="5px"
      isInline
      my="5px"
    >
      <Box>
        <Select
          styles={customStyles}
          placeholder="Field"
          options={filters}
          formatOptionLabel={(o) => o.label}
          onChange={(v) => setOperatorName(v)}
          hasBg
          roundedLeft
          value={construct.name}
          minWidth="90px"
          theme={(theme) => reactSelectTheme(theme)}
        />
      </Box>

      {construct.name &&
        construct.name.isOperator &&
        construct.name.type === 'operator' && (
          <Box>
            <Select
              styles={customStyles}
              placeholder="Type"
              minWidth="90px"
              flex={1}
              options={construct.name ? construct.name.operators : []}
              onChange={(v) => setOperatorFunction(v)}
              value={construct.operator}
              isOperator
              theme={(theme) => reactSelectTheme(theme)}
            />
          </Box>
        )}

      {construct.name && construct.name.type === 'text' && (
        <Input
          type="text"
          variant="filled"
          placeholder="Enter term"
          size="xs"
          autoFocus
          onBlur={(e) => setOperatorValue(e.target.value)}
        />
      )}

      {construct.name && construct.name.type === 'date' && (
        <DatePicker
          label="Date"
          selected={construct.value ? dayjs(construct.value).toDate() : null}
          closeOnScroll={true}
          onChange={(v) => setOperatorValue(v ? dayjs(v).toISOString() : null)}
          animateYearScrolling={false}
          customInput={
            <Input size="xs" variant="filled" placeholder="Select Date" />
          }
          isClearable={true}
          emptyLabel={'Select Date'}
          showPopperArrow={false}
          disabledKeyboardNavigation
        />
      )}

      {construct.name &&
        construct.name.selectProps &&
        construct.name.selectProps.items.length !== 0 &&
        construct.name.type === 'select' && (
          <Box>
            <Select
              style={{ flex: 3 }}
              styles={customStyles}
              classNamePrefix="nucleus"
              isClearable
              minWidth="90px"
              options={construct.name ? construct.name.selectProps.items : []}
              onChange={(v) => setOperatorValue(v)}
              value={construct.value}
              roundedLeft
              theme={(theme) => reactSelectTheme(theme)}
            />
          </Box>
        )}

      {construct.name && construct.name.selectorType === 'date' && (
        <DatePicker
          label="Date"
          selected={construct.value ? dayjs(construct.value).toDate() : null}
          closeOnScroll={true}
          onChange={(v) => setOperatorValue(v ? dayjs(v).toISOString() : null)}
          animateYearScrolling={false}
          customInput={
            <Input size="xs" variant="filled" placeholder="Select Date" />
          }
          isClearable={true}
          emptyLabel={'Select Date'}
          showPopperArrow={false}
          disabledKeyboardNavigation
        />
      )}

      {construct.name && construct.name.selectorType === 'select' && (
        <Box>
          <Select
            style={{ flex: 3 }}
            styles={customStyles}
            classNamePrefix="nucleus"
            isClearable
            minWidth="90px"
            options={construct.name ? construct.name.selectProps.items : []}
            onChange={(v) => setOperatorValue(v)}
            isMulti={
              construct.name ? construct.name.selectProps.isMulti : false
            }
            value={construct.value}
            roundedLeft
            theme={(theme) => reactSelectTheme(theme)}
          />
        </Box>
      )}

      <Box
        fontSize="14px"
        px="4px"
        onClick={() => remove(i)}
        cursor="pointer"
        opacity={0.3}
        ml="auto"
      >
        <MdRemoveCircle fontSize="16px" />
      </Box>
    </Stack>
  );
};

export default OperatorSearchSelect;
