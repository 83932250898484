import React from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Grid, Stack } from '@chakra-ui/react';
import * as emoji from 'node-emoji';

import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { CREATE, EDIT, FETCH, DELETE_ID } from './graphql';
import { setup } from './setup';

const Index = ({ title }) => {
  const params = useParams();
  const [fetchData, { data, loading, error }] = useLazyQuery(FETCH);
  const unicode = data?.emojis_findById?.unicode;

  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
        gqlDelete={DELETE_ID}
      >
        <Grid
          templateColumns={{ base: '1fr', lg: '1fr 300px' }}
          w="100%"
          maxWidth="1000px"
          gap="25px"
        >
          <Stack spacing="20px" w="100%">
            <FormSection
              title={unicode ? `Update - ${emoji.emojify(unicode)}` : 'Add'}
            >
              <FormSectionInner>
                <Field
                  component="text"
                  name="name"
                  placeholder="Name"
                  rules={{ required: true }}
                />
                <Field
                  component="text"
                  name="unicode"
                  placeholder="Unicode"
                  rules={{ required: true }}
                  help="<a href='https://github.com/ikatyang/emoji-cheat-sheet/blob/master/README.md' target='_blank'>Get Emoji Unicodes from <strong>full-emoji-list</strong></a>"
                />
              </FormSectionInner>
            </FormSection>
          </Stack>
        </Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
