import { useLazyQuery } from '@apollo/client';
import { Box, Button, Stack, useColorModeValue } from '@chakra-ui/react';

import React from 'react';
import { Link } from 'react-router-dom';
import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';

import { DELETE, FETCH_ALL } from './graphql';
import { setup } from './setup';

const columns = [
  // {
  //     // Make an expander cell
  //     Header: () => null, // No header
  //     id: 'expander', // It needs an ID
  //     Cell: ({ row }) => (
  //         // Use Cell to render an expander for each row.
  //         // We can use the getToggleRowExpandedProps prop-getter
  //         // to build the expander.
  //         <span {...row.getToggleRowExpandedProps()}>
  //             {row.isExpanded ? '👇' : '👉'}
  //         </span>
  //     ),
  // },
  {
    Header: '',
    accessor: '_id',
    disableSortBy: true,
    Cell: ({ row }) => (
      <Link to={`/${setup.model}/edit/${row.original[setup.accessor]}`}>
        <Button variant="link">View</Button>
      </Link>
    ),
  },
  {
    Header: 'Name',
    accessor: 'name',
    disableSortBy: true,
    Cell: ({ value }) => <div>{value}</div>,
  },
  {
    Header: 'Email',
    accessor: 'email',
    disableSortBy: false,
    Cell: ({ value }) => <div>{value}</div>,
  },
];

const filters = [
  {
    value: 'last_name',
    type: 'text',
    label: 'Last Name',
    isOperator: false,
  },
];

const List = ({ title }) => {
  // const renderRowSubComponent = React.useCallback(
  //     ({ row }) => (
  //         <pre
  //             style={{
  //                 fontSize: '10px',
  //             }}
  //         >
  //             <code>{JSON.stringify({ values: row.values }, null, 2)}</code>
  //         </pre>
  //     ),
  //     []
  // );

  const [fetchData, { loading, error, data }] = useLazyQuery(FETCH_ALL);

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml="auto" isInline spacing="15px">
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>
      <Box
        mt="25px"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w="100%"
      >
        <ListView
          setup={setup}
          fetchData={fetchData}
          data={data?.[FETCH_ALL]}
          loading={loading}
          error={error}
          gqlDelete={DELETE}
          columns={columns}
          filters={filters}
        />
      </Box>
    </Meta>
  );
};

export default List;
