import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Flex, Grid, Input, Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { subYears, subDays } from 'date-fns';
import dayjs from 'dayjs';
import {
  FETCH,
  CREATE as CREATE_NOTIFICATION,
  CREATE_SCHEDULE,
} from '../containers/Notifications/graphql';
import { FormSection, FormSectionInner } from './FormSection';
import DatePickerComponent from '../Fields/DatePicker';
import NotificationDropdown from './NotificationDropdown';

const ScheduleNotification = ({ id }) => {
  const [notificationForm, setNotificationForm] = useState({});

  const [fetchData, { data, loading, error, refetch }] = useLazyQuery(FETCH);

  const [createNotification] = useMutation(CREATE_NOTIFICATION);
  const [scheduleNotification] = useMutation(CREATE_SCHEDULE);

  useEffect(() => {
    fetchData();
  }, []);

  const handleNotificationSubmit = async () => {
    const { data } = await createNotification({
      variables: {
        record: {
          message: {
            body: notificationForm.body,
            title: notificationForm.title,
          },
        },
      },
    });

    await scheduleNotification({
      variables: {
        record: {
          date: dayjs(notificationForm.date).toDate(),
          feed: {
            _id: id,
            active: true,
          },
          enabled: true,
          notifications: [
            {
              _id: data.notifications_createOne.recordId,
            },
          ],
        },
      },
    });
  };

  const handleNotificationForm = e => {
    if (e.target) {
      setNotificationForm(prevState => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    } else {
      setNotificationForm(prevState => ({
        ...prevState,
        date: e,
      }));
    }
  };

  return (
    <Grid>
      <Stack>
        <FormSection title="Schedule Notification">
          <FormSectionInner>
            <form onSubmit={e => e.preventDefault()}>
              <Flex flexDir="column" gap="18px" flex="1">
                <label>
                  Title
                  <Input
                    name="title"
                    type="text"
                    required
                    onChange={handleNotificationForm}
                  />
                </label>
                <label>
                  Body
                  <Input
                    name="body"
                    type="text"
                    required
                    onChange={handleNotificationForm}
                  />
                </label>
                <label>
                  Schedule Time
                  <DatePickerComponent
                    disablePast
                    showTimeSelect
                    excludeDateIntervals={[
                      {
                        start: subYears(new Date(), 10),
                        end: subDays(new Date(), 1),
                      },
                    ]}
                    timeIntervals={5}
                    rules={{ required: true }}
                    onChange={handleNotificationForm}
                    dateFormat="DD MM YYYY hh:mm"
                  />
                </label>
                <label>Branches</label>
                <NotificationDropdown
                  refetch={refetch}
                  targetID={data?.notifications_findById._id}
                />
                <Button type="button" onClick={handleNotificationSubmit}>
                  Set Notification
                </Button>
              </Flex>
            </form>
          </FormSectionInner>
        </FormSection>
      </Stack>
    </Grid>
  );
};

export default ScheduleNotification;
