import { Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { GrPowerCycle } from 'react-icons/gr';
import { Field } from '../../components/Form';
import Controls from './Controls';
import Links from './components/Links';

const TextWithImage = ({ field, index, align, name }) => {
  const { setValue, watch } = useFormContext();
  const direction = watch(`${name}[${index}].align`);
  // const imagePreview = watch(`${name}[${index}].image`);
  const changeDirection = () => {
    setValue(
      `${name}[${index}].align`,
      direction === 'left' ? 'right' : 'left',
    );
  };

  return (
    <Flex
      w="100%"
      direction="column"
      align="flex-start"
      rounded="5px"
      bg="white"
      p="15px"
      mb="15px"
    >
      <Flex w="100%" mb="5px">
        <Controls index={index} />
        <Button
          size="sm"
          ml="auto"
          fontWeight="normal"
          leftIcon={<GrPowerCycle />}
          onClick={() => changeDirection()}
        >
          Switch Direction
        </Button>
      </Flex>
      <Flex w="100%" direction={direction === 'left' ? 'row' : 'row-reverse'}>
        <Flex flex={1} direction={'column'}>
          <Field component="file" name={`${name}[${index}].image`} max={1} />
        </Flex>
        <Flex
          direction="column"
          w="100%"
          ml={direction === 'left' ? '20px' : '0px'}
          mr={direction === 'right' ? '20px' : '0px'}
          flex={2}
        >
          <Field
            name={`${name}[${index}].heading`}
            component="text"
            placeholder="Heading"
          />
          <Field
            name={`${name}[${index}].text`}
            component="richtext"
            placeholder="Text Content"
          />
        </Flex>
      </Flex>
      <Links name={name} index={index} />
    </Flex>
  );
};

export default TextWithImage;
