import Slider from 'react-slick';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const settings = {
  dots: true,
  arrows: false,
  fade: false,
  infinite: false,
  autoplay: false,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const ImageCarousel = ({ staticpage }) => {
  const [mergedImages, setMergedImages] = useState([]);

  useEffect(() => {
    if (staticpage?.content?.images && staticpage?.hero?.imageUrl) {
      const mergImages = [
        { imageUrl: staticpage?.hero?.imageUrl },
        ...staticpage?.content?.images,
      ];
      setMergedImages(mergImages);
    } else if (staticpage?.content?.images) {
      setMergedImages(staticpage?.content?.images);
    } else if (staticpage?.hero?.imageUrl) {
      setMergedImages([{ imageUrl: staticpage?.hero?.imageUrl }]);
    }
  }, [staticpage?.content?.images, staticpage?.hero?.imageUrl]);

  return (
    <Box>
      {mergedImages?.length > 0 && (
        <Slider {...settings}>
          {mergedImages.map((image, index) => {
            if (image?.imageUrl?.includes('mp4')) {
              return (
                <iframe
                  key={image._id}
                  title="post-video"
                  src={image?.imageUrl}
                  height={335}
                />
              );
            }
            return (
              <Box
                key={index}
                height={'sm'}
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundImage={`url(${image?.imageUrl})`}
              />
            );
          })}
        </Slider>
      )}
    </Box>
  );
};

export default ImageCarousel;
