import { gql } from '@apollo/client';

export const FETCH_SCHEDULE = gql`
  query Schedules_pagination(
    $page: Int
    $perPage: Int
    $sort: SortFindManyschedulesInput
    $filter: FilterFindManyschedulesInput
  ) {
    schedules_pagination(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        date
        notifications {
          _id
        }
        enabled
        job {
          name
        }
        feed {
          _id
        }
        completed
        _id
        updatedAt
        createdAt
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const FETCH_ALL = gql`
  query Notifications_pagination {
    notifications_pagination {
      count
      items {
        devices
        message {
          title
          body
          data {
            pdf
            other
            type
          }
        }
        postId
        fired {
          status
          date
          response
        }
        _id
        updatedAt
        createdAt
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const FETCH_FEED = gql`
  query Items($filter: FilterFindManyfeedsInput) {
    feeds_pagination(filter: $filter) {
      items {
        title
        type
        _id
      }
    }
  }
`;
export const FETCH = gql`
  query Notifications_findById($id: MongoID!) {
    notifications_findById(_id: $id) {
      createdAt
      _id
      devices
      fired {
        date
        response
        status
      }
      message {
        body
        data {
          type
          pdf
          other
        }
        title
      }
      updatedAt
    }
  }
`;

export const CREATE = gql`
  mutation Notifications_createOne(
    $recipients: notifications_recipients
    $record: CreateOnenotificationsInput!
  ) {
    notifications_createOne(recipients: $recipients, record: $record) {
      recordId
      record {
        devices
        message {
          title
          body
          data {
            pdf
            other
            type
          }
        }
        postId
        fired {
          status
          date
          response
        }
        _id
        updatedAt
        createdAt
      }
      error {
        message
      }
    }
  }
`;

export const FIRE = gql`
  mutation Notifications_fire($id: MongoID!) {
    notifications_fire(_id: $id) {
      recordId
      record {
        devices
        message {
          title
          body
          data {
            pdf
            other
            type
          }
        }
        postId
        fired {
          status
          date
          response
        }
        _id
        updatedAt
        createdAt
      }
      error {
        message
      }
    }
  }
`;

export const CREATE_SCHEDULE = gql`
  mutation Schedules_createOne($record: CreateOneschedulesInput!) {
    schedules_createOne(record: $record) {
      recordId
    }
  }
`;

// {
//     record: {
//         feedId: dsdjkhsadjas,
//         notifications: [
//             {
//                 id: dsajbdgsakjdsakdsad
//             }
//         ]
//     }
// }

export const EDIT = gql`
  mutation Notifications_updateById(
    $id: MongoID!
    $record: UpdateByIdnotificationsInput!
    $recipients: notifications_recipients
  ) {
    notifications_updateById(
      _id: $id
      record: $record
      recipients: $recipients
    ) {
      recordId
      record {
        devices
        message {
          title
          body
          data {
            pdf
            other
            type
          }
        }
        postId
        fired {
          status
          date
          response
        }
        _id
        updatedAt
        createdAt
      }
      error {
        message
      }
    }
  }
`;

export const DELETE_MANY = gql`
  mutation Emojis_removeMany($filter: FilterRemoveManyemojisInput!) {
    emojis_removeMany(filter: $filter) {
      numAffected
      error {
        message
      }
    }
  }
`;

export const DELETE_SCHEDULE = gql`
  mutation Schedules_removeById($id: MongoID!) {
    schedules_removeById(_id: $id) {
      recordId
      record {
        date
        notifications {
          _id
        }
        enabled
        job {
          name
        }
        feed {
          _id
        }
        completed
        _id
        updatedAt
        createdAt
      }
      error {
        message
      }
    }
  }
`;
