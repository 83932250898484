import React, { useState, createContext } from 'react';
import { Flex, useDisclosure, Button } from '@chakra-ui/react';
import { useFieldArray } from 'react-hook-form';
import SelectModal from './SelectModal';
import TextWithHeading from './TextWithHeading';
import TextWithImage from './TextWithImage';
// import AddBlogLinks from './AddBlogLinks';
import AddGalleryImages from './AddGalleryImages';
import FullWidthImage from './FullWidthImage';
// import AddFeaturedLocations from './AddFeaturedLocations';

export const PageEditorContext = createContext();

const PageEditor = ({ value, onChange, name, blog, location, gallery }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeIndex, setActiveIndex] = useState();
  const { fields, insert, remove } = useFieldArray({
    name: `${name}`,
  });

  const addBlock = (type) => {
    onClose();
    if (type === 'textWithHeading') {
      insert(activeIndex, {
        type,
        heading: '',
        text: '',
        link: '',
      });
    }
    if (type === 'textImageLeft') {
      insert(activeIndex, {
        type,
        align: 'left',
        heading: '',
        text: '',
        image: null,
        link: '',
      });
    }
    if (type === 'textImageRight') {
      insert(activeIndex, {
        type,
        align: 'right',
        heading: '',
        text: '',
        image: null,
        link: '',
      });
    }
    // if (type === 'addBlogLinks') {
    //   insert(activeIndex, {
    //     type: type,
    //     blogs: [],
    //   });
    // }
    if (type === 'addGalleryImages') {
      insert(activeIndex, {
        type,
        gallery: [],
      });
    }
    if (type === 'fullWidthImage') {
      insert(activeIndex, {
        type,
        image: null,
      });
    }
  };

  return (
    <PageEditorContext.Provider
      value={{ onOpen, setActiveIndex, insert, remove }}
    >
      <Flex w="100%" direction="column">
        {fields && fields.length === 0 && (
          <Button
            onClick={() => {
              setActiveIndex(0);
              onOpen();
            }}
          >
            Add New Block
          </Button>
        )}

        <Flex bg="gray.100" rounded="5px" p="10px" direction="column">
          {fields &&
            fields.map((field, i) => {
              switch (field.type) {
                case 'textWithHeading':
                  return (
                    <TextWithHeading
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      name={name}
                    />
                  );
                case 'textImageLeft':
                  return (
                    <TextWithImage
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      align="left"
                      name={name}
                    />
                  );
                case 'textImageRight':
                  return (
                    <TextWithImage
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      align="right"
                      name={name}
                    />
                  );
                // case 'addBlogLinks':
                //   return (
                //     <AddBlogLinks
                //       key={field.id}
                //       field={field}
                //       index={i}
                //       setActiveIndex={setActiveIndex}
                //       onOpen={onOpen}
                //       name={name}
                //     />
                //   );
                case 'addGalleryImages':
                  return (
                    <AddGalleryImages
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      name={name}
                    />
                  );
                case 'fullWidthImage':
                  return (
                    <FullWidthImage
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      name={name}
                    />
                  );
              }
            })}
        </Flex>

        <SelectModal
          onClose={onClose}
          isOpen={isOpen}
          onOpen={onOpen}
          addBlock={addBlock}
          blog={blog}
          location={location}
          gallery={gallery}
        />
      </Flex>
    </PageEditorContext.Provider>
  );
};

export default PageEditor;
