import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Box, Flex, Grid, Image, Stack, useToast } from '@chakra-ui/react';

import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { CREATE, EDIT, FETCH, DELETE_ID } from './graphql';
import { setup } from './setup';

const Index = ({ title }) => {
  const params = useParams();
  const toast = useToast();

  const [fetchData, { data, loading, error }] = useLazyQuery(FETCH);
  const [
    editSticker,
    { data: editData, loading: editLoading, error: editError },
  ] = useMutation(EDIT);
  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  useEffect(() => {
    if (editData) {
      toast({
        title: 'Saved',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
    if (editError) {
      toast({
        title: 'Error, try again',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [editData, editError]);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
        gqlDelete={DELETE_ID}
        gqlString={'profile_image_bank_createOne'}
      >
        <Grid
          templateColumns={{ base: '1fr', lg: '1fr 300px' }}
          w="100%"
          maxWidth="1000px"
          gap="25px"
        >
          <Stack spacing="20px" w="100%">
            <FormSection
              title={
                data?.profile_image_bank_findById?.image_url ? 'Update' : 'Add'
              }
            >
              <FormSectionInner>
                <Field
                  component="text"
                  name="name"
                  placeholder="Name"
                  rules={{ required: true }}
                  disabled={editLoading}
                  customUpdate={editSticker}
                  targetProperty="name"
                  targetID={data?.profile_image_bank_findById}
                  overrideTargetKey={'id'}
                />

                {type === 'Edit' && (
                  <Box>
                    <Flex justifyContent={'center'}>
                      {data?.profile_image_bank_findById?.image_url && (
                        <Image
                          src={data?.profile_image_bank_findById?.image_url}
                          w={'30%'}
                          borderRadius={'50%'}
                        />
                      )}
                    </Flex>

                    <Field
                      component="file"
                      name="image_url"
                      placeholder="Image"
                      rules={{ required: true }}
                      customUpdate={editSticker}
                      disabled={editLoading}
                      targetProperty="image_url"
                      targetID={data?.profile_image_bank_findById}
                      multiple={false}
                      targetKey={'id'}
                    />
                  </Box>
                )}
              </FormSectionInner>
            </FormSection>
          </Stack>
        </Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
