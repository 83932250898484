import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Grid, Stack, useToast } from '@chakra-ui/react';
// import * as emoji from 'node-emoji';

import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { CREATE, DELETE_ID, EDIT, FETCH } from './graphql';
import { setup } from './setup';
// import DeleteField from './components/Delete';

const Index = ({ title }) => {
  const params = useParams();
  const toast = useToast();

  const [fetchData, { data, loading, error }] = useLazyQuery(FETCH, {
    variables: {
      id: params.id,
    },
  });
  // console.log('data edit', data);
  const catName = data?.messagestreamsCategories_findById?.name;

  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  const [
    editSaticPage,
    { data: editData, loading: editLoading, error: editError },
  ] = useMutation(EDIT, {
    variables: {
      id: params.id,
    },
  });

  useEffect(() => {
    if (editData) {
      toast({
        title: 'Saved',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
    if (editError) {
      toast({
        title: 'Error, try again',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [editData, editError]);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
        gqlDelete={DELETE_ID}
      >
        <Grid
          templateColumns={{ base: '1fr', lg: '1fr 300px' }}
          w="100%"
          maxWidth="1000px"
          gap="25px"
        >
          <Stack spacing="20px" w="100%">
            <FormSection title={catName ? `Update ` : 'Add'}>
              <FormSectionInner>
                <Field
                  component="text"
                  name="name"
                  placeholder="Name"
                  rules={{ required: true }}
                  customUpdate={editSaticPage}
                  targetProperty="name"
                  targetID={catName}
                />
              </FormSectionInner>
            </FormSection>
          </Stack>
        </Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
