import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Grid, Stack } from '@chakra-ui/react';
import { subYears, subDays } from 'date-fns';
import * as emoji from 'node-emoji';

import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { CREATE, EDIT, FETCH, DELETE_MANY, CREATE_SCHEDULE } from './graphql';
import { setup } from './setup';
import NotificationDropdown from '../../components/NotificationDropdown';

const Index = ({ title }) => {
  const params = useParams();
  const [fetchData, { data, loading, error, refetch }] = useLazyQuery(FETCH, {
    variables: {
      id: params?.id,
    },
  });

  const [
    editNotification,
    { data: editData, loading: editLoading, error: editError },
  ] = useMutation(EDIT);

  const [searchParams, setSearchParams] = useSearchParams();

  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={type === 'Schedule' ? CREATE_SCHEDULE : EDIT}
        gqlCreate={CREATE}
        gqlDelete={DELETE_MANY}
        extraParam={'recipients'}
        hideDelete={true}
        ignoreValues={
          type === 'Edit'
            ? ['devices', 'fired', 'recipients']
            : ['devices', 'fired']
        }
        editFields={type === 'Schedule' ? ['date'] : undefined}
      >
        <Grid
          templateColumns={{ base: '1fr', lg: '1fr 300px' }}
          w="100%"
          maxWidth="1000px"
          gap="25px"
        >
          <Stack spacing="20px" w="100%">
            <FormSection
              title={
                type === 'Create'
                  ? 'Add'
                  : type === 'Schedule'
                  ? `Schedule - ${title}`
                  : `Update - ${title}`
              }
            >
              {type === 'Schedule' ? (
                <FormSectionInner>
                  <Field
                    component="date"
                    name="date"
                    disablePast
                    showTimeSelect
                    excludeDateIntervals={[
                      {
                        start: subYears(new Date(), 10),
                        end: subDays(new Date(), 1),
                      },
                    ]}
                    timeIntervals={5}
                    rules={{ required: true }}
                  />
                </FormSectionInner>
              ) : (
                <FormSectionInner>
                  {type === 'Create' && (
                    <NotificationDropdown
                      refetch={refetch}
                      targetID={data?.notifications_findById._id}
                    />
                  )}
                  <Field
                    component="text"
                    name="message.title"
                    customUpdate={editNotification}
                    placeholder="Heading"
                    defaultValue={searchParams.get('title')}
                    rules={{ required: true }}
                    // targetProperty="message.title"
                    // targetID={data?.notifications_findById._id}
                    // overrideTargetKey={'id'}
                  />

                  <Field
                    component="textarea"
                    name="message.body"
                    defaultValue={searchParams.get('body')}
                    placeholder="Body"
                    rules={{ required: true }}
                    help="This will be previewed under the main header"
                    // targetFullObject={data?.notifications_findById.message}
                    // targetProperty="message.body"
                    // targetID={data?.notifications_findById._id}
                    // overrideTargetKey={'id'}
                  />
                </FormSectionInner>
              )}
            </FormSection>
          </Stack>
        </Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
