import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
  query Surveys_pagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManysurveysInput
    $sort: SortFindManysurveysInput
  ) {
    surveys_pagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        _id
        name
        updatedAt
        createdAt
        questions {
          surveyId
          title
          type
          isRequired
          options {
            title
            responseType
            _id
          }
          _id
          updatedAt
          createdAt
        }
      }
    }
  }
`;

export const FETCH = gql`
  query Surveys_findById($_id: MongoID!) {
    surveys_findById(_id: $_id) {
      _id
      updatedAt
      createdAt
      feed {
        title
        type
        active
        isSticky
        _id
        updatedAt
        createdAt
        visibility {
          public
          branches {
            _id
          }
        }
        staticpage {
          _id
          title
          url
          seo
          content
          slug
          hero
          cta
          updatedAt
          createdAt
        }
        jobspec {
          status
          address
          salary
          type
          benefits {
            title
            _id
            updatedAt
            createdAt
          }
          description
          hireMultiple
          _id
          updatedAt
          createdAt
        }
      }
      questions {
        surveyId
        title
        type
        isRequired
        linked {
          optionId
        }
        options {
          title
          responseType
          _id
          responses {
            userId
            value
          }
        }
        _id
        updatedAt
        createdAt
      }
    }
  }
`;

export const CREATE = gql`
  mutation Surveys_createOne($record: CreateOnesurveysInput!) {
    surveys_createOne(record: $record) {
      _id
      updatedAt
      createdAt
      feed {
        title
        type
        active
        isSticky
        _id
        updatedAt
        createdAt
        staticpage {
          _id
          title
          title
          url
          seo
          content
          slug
          hero
          cta
          updatedAt
          createdAt
          created
        }
        jobspec {
          status
          address
          salary
          type
          benefits {
            title
            _id
            updatedAt
            createdAt
          }
          description
          hireMultiple
          _id
          updatedAt
          createdAt
        }
        surveyComplete
      }
      questions {
        surveyId
        title
        type
        isRequired
        linked {
          optionId
        }
        options {
          title
          responseType
          _id
          responses {
            userId
            value
          }
        }
        _id
        updatedAt
        createdAt
      }
    }
  }
`;

export const EDIT = gql`
  mutation Surveys_updateById(
    $_id: MongoID!
    $record: UpdateByIdsurveysInput!
  ) {
    surveys_updateById(_id: $_id, record: $record) {
      recordId
      record {
        _id
        name
      }
      error {
        message
      }
    }
  }
`;

export const DELETE_ID = gql`
  mutation Surveys_removeById($id: MongoID!) {
    surveys_removeById(_id: $id) {
      recordId
      error {
        message
      }
    }
  }
`;

// Surveys delete by many needs backend fixes
export const DELETE_MANY = gql`
  mutation Surveys_removeById($id: MongoID!) {
    surveys_removeById(_id: $id) {
      recordId
      error {
        message
      }
    }
  }
`;

export const FETCH_QUESTIONS = gql`
  query Surveyquestions_findMany($filter: FilterFindManysurveyquestionsInput) {
    surveyquestions_findMany(filter: $filter) {
      _id
      surveyId
      title
      type
      isRequired
      options {
        title
        responseType
        _id
      }
      updatedAt
      createdAt
    }
  }
`;

export const CREATE_QUESTION = gql`
  mutation Surveyquestions_createOne($record: CreateOnesurveyquestionsInput!) {
    surveyquestions_createOne(record: $record) {
      error {
        message
      }
      recordId
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation Surveyquestions_updateById(
    $id: MongoID!
    $record: UpdateByIdsurveyquestionsInput!
  ) {
    surveyquestions_updateById(_id: $id, record: $record) {
      error {
        message
      }
      recordId
    }
  }
`;

export const REMOVE_QUESTION = gql`
  mutation Surveyquestions_removeById($_id: MongoID!) {
    surveyquestions_removeById(_id: $_id) {
      error {
        message
      }
    }
  }
`;
