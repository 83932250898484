import React, { useEffect } from 'react';
import { Flex, FormLabel, Switch } from '@chakra-ui/react';

const SwitchField = (props) => {
  const {
    onChange,
    onBlur,
    value,
    defaultValue,
    fieldLabel,
    isChecked,
    ...rest
  } = props;

  // useEffect(() => {
  //   if (value === '') {
  //     onChange(false);
  //   }
  // }, [value, onChange]);

  return (
    <Flex align="center" w="100%">
      <Switch
        onChange={(e) => onChange(e.target.checked)}
        onBlur={() => {
          onBlur && onBlur();
        }}
        value={value === true}
        isChecked={value === true}
        required={false}
        isRequired={false}
        defaultValue={defaultValue || false}
        {...rest}
      />
      {fieldLabel && (
        <FormLabel ml="15px" fontWeight="normal">
          {fieldLabel}
        </FormLabel>
      )}
    </Flex>
  );
};

export default SwitchField;
