import {
  Input,
  Button,
  Box,
  Flex,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Select,
} from "@chakra-ui/react";

import React, { useState, useRef, useEffect, useCallback } from "react";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebouceEffects";

import "react-image-crop/dist/ReactCrop.css";
import { debounce } from "lodash";
import { a } from "aws-amplify";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function EditImage({ image, onChange, index }) {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (image) {
      setImgSrc(image?.file);

      setScale(image?.scale || 1);
      setRotate(image?.rotate || 0);
      setAspect(image?.aspect || 16 / 9);
      setWidth(image?.width || 0);
      setHeight(image?.height || 0);
      setCrop(image?.crop || undefined);

      setCompletedCrop(
        convertToPixelCrop(
          image?.crop ||
            centerAspectCrop(
              imgRef?.current?.width,
              imgRef?.current?.height,
              image?.aspect || aspect
            ),
          imgRef.current?.width,
          imgRef.current?.height
        )
      );
    }
  }, [image]);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(crop || centerAspectCrop(width, height, aspect));
    }
  }

  async function createNewImageFile() {
    const img = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!img || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    const offscreen = new OffscreenCanvas(
      width || completedCrop.width,
      height || completedCrop.height
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    console.log("offscreen");
    if (offscreen?.width === 0 && offscreen?.height === 0) {
      return null;
    }

    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });

    const file = new File([blob], "parfetts-nucleus-post-image", {
      type: "image/png",
    });

    return file;
  }

  async function onPreview() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    const offscreen = new OffscreenCanvas(
      width || completedCrop.width,
      height || completedCrop.height
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    console.log("offscreen", offscreen?.width, offscreen?.height);

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });

    const fil = new File([blob], "parfetts-nucleus-post-image", {
      type: "image/png",
    });

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }
    blobUrlRef.current = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank";
    link.click();
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else {
      setAspect(16 / 9);
    }
  }

  const handleRatioChange = (ratio) => {
    if (ratio) {
      setAspect(ratio);
    }
  };

  const dimensionsFromRatio = (ratio, type, value) => {
    let width, height;

    if (type === "width") {
      width = value;
      height = value / ratio;
    }
    if (type === "height") {
      height = value;
      width = value * ratio;
    }

    setWidth(width);
    setHeight(height);
  };

  const handleWidthChange = useCallback(
    debounce((value) => {
      console.log("WIDTH", value);
      if (!aspect) {
        setWidth(Number(value));
      } else {
        dimensionsFromRatio(aspect, "width", Number(value));
      }
    }, 800)
  );

  const handleHeightChange = useCallback(
    debounce((value) => {
      console.log("WIDTH", value);
      if (!aspect) {
        setHeight(Number(value));
      } else {
        dimensionsFromRatio(aspect, "height", Number(value));
      }
    }, 800)
  );

  const handleSizeChange = (size, type) => {
    if (type === "width") handleWidthChange(size);
    if (type === "height") handleHeightChange(size);
  };

  useEffect(() => {
    if (aspect && aspect !== image?.aspect) {
      if (imgRef.current) {
        const newCrop = centerAspectCrop(
          imgRef.current?.width,
          imgRef.current?.height,
          aspect
        );
        setCrop(newCrop);
        // Updates the preview
        setCompletedCrop(
          convertToPixelCrop(
            newCrop,
            imgRef.current?.width,
            imgRef.current?.height
          )
        );
        dimensionsFromRatio(aspect, "width", imgRef?.current?.width);
      }
    }
  }, [aspect]);

  useEffect(() => {
    if (completedCrop) {
      console.log("completedCrop", completedCrop);
      if (width === 0) setWidth(completedCrop?.width);
      if (height == 0) setHeight(completedCrop?.height);
    }
  }, [completedCrop]);

  useEffect(() => {
    async function update() {
      const editFile = await createNewImageFile();
      onChange(
        {
          file: imgSrc,
          editFile,
          width,
          height,
          scale,
          rotate,
          aspect,
          crop,
        },
        index
      );
    }
    if (completedCrop) {
      update();
    }
  }, [completedCrop, width, height, scale, aspect, rotate, crop]);

  const handleCropUpdate = (newCrop) => {
    console.log("newCrop", newCrop);
    setCrop(newCrop);
  };

  return (
    <>
      <div className="App">
        <div className="Crop-Controls">
          <div>
            <label htmlFor="scale-input">Scale: {scale}</label>
            <Slider
              aria-label="slider-ex-1"
              value={scale}
              min={1}
              max={100}
              onChange={(v) => setScale(Number(v === 0 ? 1 : v))}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </div>
          <div>
            <label htmlFor="rotate-input">Rotate: {rotate}</label>
            <Slider
              aria-label="slider-ex-1"
              value={rotate}
              min={-180}
              max={180}
              onChange={(v) =>
                setRotate(Math.min(180, Math.max(-180, Number(v))))
              }
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </div>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"full"}
            mb={2}
            mt={2}
          >
            <Flex
              flexDirection={"row"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              width={"full"}
            >
              <Button onClick={handleToggleAspectClick}>
                Aspect {aspect ? "off" : "on"}
              </Button>

              {aspect && (
                <Select
                  key={`aspect-${aspect}`}
                  ml={2}
                  width={"120px"}
                  placeholder="Ratio"
                  defaultValue={aspect}
                  onChange={(e) => handleRatioChange(e.target.value)}
                >
                  <option value={16 / 9}>16:9</option>
                  <option value={4 / 3}>4:3</option>
                  <option value={4 / 5}>4:5</option>
                  <option value={1 / 1}>1:1</option>
                  <option value={5 / 4}>5:4</option>
                  <option value={9 / 16}>9:16</option>
                </Select>
              )}
            </Flex>

            <Flex
              flexDirection={"row"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              width={"full"}
            >
              <Text>Image Size:</Text>
              <Input
                key={`width-${width}`}
                defaultValue={width?.toFixed(0)}
                ml={2}
                width={"100px"}
                onChange={(e) => {
                  handleSizeChange(e.target.value, "width");
                }}
              />
              <Input
                key={`height-${height}`}
                defaultValue={height?.toFixed(0)}
                ml={2}
                width={"100px"}
                onChange={(e) => {
                  handleSizeChange(e.target.value, "height");
                }}
              />
              <Text ml={2}>px</Text>

              <Button onClick={onPreview} ml={6}>
                Preview
              </Button>
              <a
                href="#hidden"
                ref={hiddenAnchorRef}
                download
                style={{
                  position: "absolute",
                  top: "-200vh",
                  visibility: "hidden",
                }}
              >
                Hidden download
              </a>
            </Flex>
          </Flex>
        </div>

        <Flex
          direction={"row"}
          alignItems={"center"}
          border={"2px solid #cbd5e0"}
        >
          <Box width={"full"} height={"max"}>
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={handleCropUpdate}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
                // minWidth={400}
                minHeight={100}
                // circularCrop
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
          </Box>
          <Flex
            width={"full"}
            height={"max"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {!!completedCrop && (
              <>
                <div>
                  <canvas
                    id="preview-edit"
                    ref={previewCanvasRef}
                    style={{
                      objectFit: "contain",
                      width: completedCrop.width,
                      height: completedCrop.height,
                      margin: "0px",
                      padding: "0px",
                    }}
                  />
                </div>
              </>
            )}
          </Flex>
        </Flex>
      </div>
    </>
  );
}
