const Input = {
  parts: ['field'],
  defaultProps: {
    size: 'md',
    isFullWidth: true,
    // colorScheme: 'purple',
  },
  baseStyle: {},
  variants: {
    outline: () => ({
      field: {
        outlineOffset: '0px',
        bg: 'white',
        borderColor: 'gray.300',
        borderTopColor: 'gray.400',
        _placeholder: {
          color: 'gray.500',
        },
        _hover: {
          borderColor: 'gray.300',
          borderTopColor: 'gray.400',
        },
        // _focus: {
        //     borderWidth: '1px',
        //     boxShadow: 'none',
        //     borderColor: 'gray.200',
        //     outline: '1px solid #6865C8',
        // },
        _invalid: {
          // bg: 'red.50',
        },
      },
    }),
  },
};

export default Input;
