import { Box, Collapse, Flex, Spinner, Text } from '@chakra-ui/react';
import React from 'react';

const Loading = ({ loading }) => (
  <Box
    pos="absolute"
    top="0px"
    left="0px"
    zIndex={100}
    w="100%"
    bg="white"
    roundedTop="lg"
  >
    <Collapse animateOpacity in={loading}>
      <Box w="100%" p="5px">
        <Flex w="100%" rounded="md" bg="blue.50" p="10px">
          <Spinner color="blue.400" />
          <Text ml="20px">Loading, please wait</Text>
        </Flex>
      </Box>
    </Collapse>
  </Box>
);

export default Loading;
