import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  Flex,
  Link,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';

import { useFormContext } from 'react-hook-form';
import HeaderWithActions from '../../components/HeaderWithActions';
import Meta from '../../components/Meta';
import { CREATE } from './graphql';
import { setup } from './setup';
import { Field, Form } from '../../components/Form';
import { PasswordField } from '../../components/PasswordField';

const SubmitButton = ({ loading }) => {
  const { submit } = useFormContext();
  return (
    <Button
      size="lg"
      isFullWidth
      variant="primary"
      mb={3}
      type="submit"
      isLoading={loading}
      onClick={() => submit()}
      loadingText="Adding..."
      backgroundColor={'black'}
      textColor={'white'}
      my={2}
    >
      Add
    </Button>
  );
};

const List = () => {
  const toast = useToast();
  const [createAdmin, { loading, error, data: dataCreateAdmin }] =
    useMutation(CREATE);

  const onSubmit = async data => {
    createAdmin({
      variables: {
        email: data?.adminEmail,
        password: data?.adminPassword,
      },
    });
  };

  useEffect(() => {
    if (dataCreateAdmin) {
      toast({
        title: 'Saved',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
    if (error) {
      toast({
        title: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [dataCreateAdmin, error]);

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}></HeaderWithActions>
      <Flex
        mt="25px"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w="100%"
        justifyContent={'center'}
        alignSelf={'center'}
        p={10}
      >
        <Flex
          w="60%"
          alignSelf={'center'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Form onSubmit={onSubmit}>
            <Field
              auto={false}
              isFullWidth
              name="adminEmail"
              component="text"
              variant="filled"
              size="lg"
              placeholder="Email Address"
              type="email"
              autoFocus
              mb={3}
              rules={{ required: true }}
            />

            <Field
              name="adminPassword"
              component={PasswordField}
              mb={3}
              rules={{ required: true }}
            />

            <Text fontSize="sm" color="lightgrey.100">
              Note: Please use strong password {` `}
              <Link
                href="https://www.strongpasswordgenerator.org/"
                textDecoration={'underline'}
                target="_blank"
              >
                Password Generator
              </Link>
            </Text>

            <SubmitButton loading={loading} />
            {/* <Text fontSize="sm" color="green.700" textAlign={'center'}>
              Successfully Added
            </Text> */}
          </Form>
        </Flex>
      </Flex>
    </Meta>
  );
};

export default List;
