import React from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Box, Flex, Button, Stack, useColorModeValue } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { BiMessageRoundedDots } from 'react-icons/bi';
import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import { DELETE, FETCH_ALL } from './graphql';
import { setup } from './setup';

const columns = [
  {
    Header: 'Title',
    accessor: '_id',
    disableSortBy: true,
    Cell: ({ row }) => {
      const messagesReadBy = row?.original?.lastMessageViewed.filter(
        val => val !== row?.original?.created?.userId
      );
      return (
        <Flex>
          {messagesReadBy.length === 0 && (
            <Box
              w="12px"
              h="12px"
              bg="gray.400"
              borderRadius="full"
              mr="5px"
            ></Box>
          )}
          <Link to={`/${setup.model}/edit/${row.original[setup.accessor]}`}>
            <Button variant="link"> {row.original.title}</Button>
          </Link>
        </Flex>
      );
    },
  },

  {
    Header: 'Last update',
    accessor: 'updatedAt',
    disableSortBy: false,
    Cell: ({ value }) => <div>{dayjs(value).format('DD/MM/YYYY @ HH:mm')}</div>,
  },
];

const filters = [
  {
    value: 'name',
    type: 'text',
    label: 'Name',
    isOperator: false,
  },
];

const List = () => {
  const [fetchData, { loading, error, data }] = useLazyQuery(FETCH_ALL);
  // console.log('data', data);

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml="auto" isInline spacing="15px">
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>
      <Box
        mt="25px"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w="100%"
      >
        <ListView
          setup={setup}
          fetchData={fetchData}
          data={data?.messagestreams_pagination}
          loading={loading}
          error={error}
          gqlDelete={DELETE}
          columns={columns}
          filters={filters}
        />
      </Box>
    </Meta>
  );
};

export default List;
