import React from 'react';
import { Textarea } from '@chakra-ui/react';

const TextAreaComponent = (props) => {
  const { onChange, onBlur, value, resize = 'vertical', ...rest } = props;
  return (
    <Textarea
      onChange={(e) => onChange(e.target.value)}
      onBlur={() => {
        onBlur && onBlur();
      }}
      value={value === null ? '' : value}
      minWidth="200px"
      resize={resize}
      {...rest}
    />
  );
};

export default TextAreaComponent;
