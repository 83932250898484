import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
  query Feeds_pagination($filter: FilterFindManyfeedsInput) {
    feeds_pagination(filter: $filter) {
      count
      items {
        title
        type
        active
        isSticky
        publishedAt
        _id
        updatedAt
        createdAt
        staticpage {
          _id
          title
          url
          seo
          content
          slug
          hero
          cta
          updatedAt
          createdAt
        }
        jobspec {
          status
          address
          salary
          type
          benefits {
            title
            _id
            updatedAt
            createdAt
          }
          description
          hireMultiple
          _id
          updatedAt
          createdAt
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const FETCH = gql`
  query Emojis_findById($_id: MongoID!) {
    emojis_findById(_id: $_id) {
      _id
      name
      unicode
      createdAt
      updatedAt
    }
  }
`;

export const CREATE = gql`
  mutation Emojis_createOne($record: CreateOneemojisInput!) {
    emojis_createOne(record: $record) {
      error {
        message
      }
      recordId
      record {
        _id
        name
        unicode
        updatedAt
        createdAt
      }
    }
  }
`;

export const EDIT = gql`
  mutation Emojis_updateById($_id: MongoID!, $record: UpdateByIdemojisInput!) {
    emojis_updateById(_id: $_id, record: $record) {
      error {
        message
      }
      recordId
      record {
        _id
        name
        unicode
        updatedAt
        createdAt
      }
    }
  }
`;

export const DELETE = gql`
  mutation Emojis_removeOne(
    $filter: FilterRemoveOneemojisInput
    $sort: SortRemoveOneemojisInput
  ) {
    emojis_removeOne(filter: $filter, sort: $sort) {
      error {
        message
      }
      recordId
      record {
        _id
      }
    }
  }
`;
