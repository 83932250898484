import React from 'react';
// import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import {
  Grid,
  // Stack,
  Flex,
  // Tab,
  // Tabs,
  // TabList,
  // TabPanel,
  // TabPanels,
  Heading,
  Text,
} from '@chakra-ui/react';

import EditView from '../../components/EditView';
// import { Field } from '../../components/Form';
// import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { CREATE, EDIT, FETCH, DELETE_ID } from './graphql';
import { setup } from './setup';
import Conversation from './Conversation';

const Index = ({ title }) => {
  // const params = useParams();
  const [fetchData, { data, loading, error }] = useLazyQuery(FETCH);
  const message = data?.messagestreams_findById;
  // console.log('data edit', data);

  // const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  return (
    <Meta title={title}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
        gqlDelete={DELETE_ID}
        ignoreValues={['questions']}
      >
        <Flex
          direction="column"
          maxWidth="75%"
          w="100%"
          backgroundColor="#ffffff"
          padding={4}
        >
          <Heading as="h1" size="lg" mb={4}>
            {message?.title}
          </Heading>

          <Conversation message={message} />
        </Flex>
        <Grid
          templateColumns={{ base: '1fr' }}
          w="100%"
          maxWidth="1000px"
          gap="25px"
        ></Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
