import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const PreviewTable = ({ rows, columns }) => {
  const [localRows, setLocalRows] = useState([]);
  const [length, setLength] = useState(10);

  useEffect(() => {
    if (rows) {
      setLocalRows(rows.slice(0, length));
    }
  }, [rows, length]);

  return (
    <TableContainer>
      <Table variant="simple">
        <TableCaption onClick={() => setLength(length + 10)} cursor={'pointer'}>
          Load more
        </TableCaption>
        <Thead>
          <Tr>
            {columns.map((col, i) => (
              <Th key={`header-row-${i}`}>{col}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {localRows.map((row, j) => (
            <Tr key={`body-row-${j}`}>
              {row.map((column, k) => (
                <Td key={`body-row-${j}-col${k}`}>{column}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            {columns.map((col, i) => (
              <Th key={`header-row-${i}`}>{col}</Th>
            ))}
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default PreviewTable;
