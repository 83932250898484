const Menu = {
  parts: ['list'],
  defaultProps: {
    colorScheme: 'blue',
  },
  baseStyle: {
    list: {
      borderRadius: 'lg',
      boxShadow:
        'rgba(23, 24, 24, 0.05) -1px 0px 20px 0px, rgba(0, 0, 0, 0.15) 0px 1px 5px 0px',
    },
  },
  variants: {},
};

export default Menu;
