import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
  query Feeds_pagination($filter: FilterFindManyfeedsInput) {
    feeds_pagination(filter: $filter) {
      count
      items {
        title
        type
        active
        isSticky
        _id
        updatedAt
        createdAt
        staticpage {
          _id
          title
          url
          seo
          content
          slug
          hero
          cta
          updatedAt
          createdAt
        }
        jobspec {
          status
          address
          salary
          type
          benefits {
            title
            _id
            updatedAt
            createdAt
          }
          description
          hireMultiple
          _id
          updatedAt
          createdAt
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const EDIT = gql`
  mutation Feeds_updateById($_id: MongoID!, $record: UpdateByIdfeedsInput!) {
    feeds_updateById(_id: $_id, record: $record) {
      recordId
      record {
        title
        type
        active
        isSticky
        _id
        updatedAt
        createdAt
        staticpage {
          _id
          title
          url
          seo
          content
          slug
          hero
          cta
          updatedAt
          createdAt
        }
        jobspec {
          status
          address
          salary
          type
          benefits {
            title
            _id
            updatedAt
            createdAt
          }
          description
          hireMultiple
          _id
          updatedAt
          createdAt
        }
      }
      error {
        message
      }
    }
  }
`;
export const DELETE = gql`
  mutation Jobs_removeById($id: MongoID!) {
    jobs_removeById(_id: $id) {
      error {
        message
      }
      recordId
    }
  }
`;

export const SCHEDULE = gql`
  mutation Schedules_createOne($record: CreateOneschedulesInput!) {
    schedules_createOne(record: $record) {
      record {
        _id
        feed {
          _id
        }
        completed
        date
      }
    }
  }
`;

export const LIST_SCHEDULES = gql`
  query Schedules_pagination($filter: FilterFindManyschedulesInput) {
    schedules_pagination(filter: $filter) {
      count
      items {
        _id
        date
        enabled
        feed {
          _id
          active
        }
      }
    }
  }
`;
