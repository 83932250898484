import React, { useEffect, useState } from 'react';

import { Box, Flex, useToast } from '@chakra-ui/react';
import Select from 'react-select';

import { useMutation, useQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { Field } from '../Form';

import { FETCH_ALL as BRANCH_FETCH_ALL } from '../../containers/Branches/graphql';
import { EDIT } from '../../containers/Notifications/graphql';

function NotificationDropdown({ feed, refetch, targetID }) {
  const [isPublic, setIsPublic] = useState(false);
  const toast = useToast();

  const { getValues, reset } = useFormContext();

  const { data: branchData } = useQuery(BRANCH_FETCH_ALL);
  const [selectedBranches, setSelectedBranches] = useState([]);

  const [
    editBranches,
    {
      data: editBranchData,
      loading: editBranchLoading,
      error: editBranchError,
    },
  ] = useMutation(EDIT);

  const branchesOptions = branchData?.branches_pagination?.items.map(
    (branch) => ({
      label: branch.code,
      value: branch._id,
    }),
  );

  useEffect(() => {
    if (isPublic) {
      setIsPublic(isPublic);
    }
  }, [isPublic]);

  useEffect(() => {
    const selectedBranchesIds = feed?.visibility?.branches.map(
      (branch) => branch._id,
    );
    if (!selectedBranchesIds) {
      return;
    }
    const result = branchesOptions
      ? branchesOptions.filter((branch) =>
          selectedBranchesIds.includes(branch.value),
        )
      : [];
    setSelectedBranches(result);
  }, [feed?.visibility?.branches, branchData?.branches_pagination?.items]);

  useEffect(() => {
    if (editBranchData) {
      toast({
        title: 'Saved',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
    if (editBranchError) {
      toast({
        title: 'Error, try again',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [editBranchError, editBranchData]);

  return (
    <Flex flexDirection={'column'}>
      <Field
        component="switch"
        name="recipients.public"
        placeholder="Send to all"
        value={isPublic}
        onChange={async (val) => {
          setIsPublic(val);
          const values = getValues();
          reset({ ...values, recipients: { branches: null, public: val } });
        }}
      />

      {!isPublic && (
        <Box mt={5}>
          <Field
            component="select"
            name="recipients.branches"
            placeholder="Select.."
            labelKey="label"
            valueKey="value"
            selectValue="label"
            options={branchesOptions}
            isMulti
            rules={{ required: !isPublic }}
          />
        </Box>
      )}
    </Flex>
  );
}

export default NotificationDropdown;
