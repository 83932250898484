import { useLazyQuery } from '@apollo/client';
import { Box, Button, Stack, useColorModeValue } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import { DELETE, FETCH_ALL } from './graphql';

const filters = [
  {
    value: 'title',
    type: 'text',
    label: 'Title',
    isOperator: false,
  },
];

const List = ({ setup }) => {
  const [fetchData, { loading, error, data }] = useLazyQuery(FETCH_ALL);
  const location = useLocation();

  const columns = [
    {
      Header: '',
      accessor: '_id',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Link to={`/${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant="link">View</Button>
        </Link>
      ),
    },
    {
      Header: 'Title',
      accessor: 'title',
      disableSortBy: false,
      Cell: ({ value }) => <div>{value}</div>,
    },
    {
      Header: 'Status',
      accessor: 'active',
      disableSortBy: false,
      Cell: ({ value }) => (value ? 'Published' : ''),
    },
    {
      Header: 'Updated At',
      accessor: 'updatedAt',
      disableSortBy: false,
      Cell: ({ value }) => <div>{dayjs(value).format('DD/MM/YYYY HH:mm')}</div>,
    },
  ];

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml="auto" isInline spacing="15px">
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>
      <Box
        mt="25px"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w="100%"
      >
        <ListView
          setup={setup}
          fetchData={fetchData}
          defaultFilter={{
            type: location?.pathname?.substring(1),
          }}
          data={data?.feeds_pagination}
          loading={loading}
          error={error}
          gqlDelete={DELETE}
          columns={columns}
          filters={filters}
        />
      </Box>
    </Meta>
  );
};

export default List;
