import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
  query Comment_bank_pagination {
    comment_bank_pagination {
      items {
        _id
        content
        createdAt
        updatedAt
      }
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const FETCH = gql`
  query Comment_bank_findById($id: MongoID!) {
    comment_bank_findById(_id: $id) {
      title
      content
      _id
      updatedAt
      createdAt
    }
  }
`;

export const CREATE = gql`
  mutation Comment_bank_createOne($record: CreateOnecomment_bankInput!) {
    comment_bank_createOne(record: $record) {
      error {
        message
      }
      record {
        _id
        content
      }
    }
  }
`;

export const EDIT = gql`
  mutation Comment_bank_updatedById(
    $id: MongoID!
    $record: UpdateByIdcomment_bankInput!
  ) {
    comment_bank_updatedById(_id: $id, record: $record) {
      error {
        message
      }
      record {
        _id
        content
      }
    }
  }
`;

export const DELETE_MANY = gql`
  mutation Emojis_removeMany($filter: FilterRemoveManyemojisInput!) {
    emojis_removeMany(filter: $filter) {
      numAffected
      error {
        message
      }
    }
  }
`;
export const DELETE_ID = gql`
  mutation Comment_bank_removeById($id: MongoID!) {
    comment_bank_removeById(_id: $id) {
      error {
        message
      }
      record {
        _id
        content
      }
    }
  }
`;
