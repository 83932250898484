import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Heading,
  useColorModeValue,
  Text,
  Flex,
  IconButton,
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import { AiOutlineFolder, AiFillDelete } from 'react-icons/ai';

import Meta from '../../components/Meta';
import { FETCH_ALL, DELETE } from './graphql';
import { setup } from './setup';
import Uploader from './Uploader';
import NewDirectoryModal from './NewDirectoryModal';

const List = () => {
  const [fetchData, { data }] = useLazyQuery(FETCH_ALL);
  const [path, setPath] = useState(['documents']);
  const [manualFetch, setManualFetch] = useState(1);
  const [deleteFile, { data: deleteData }] = useMutation(DELETE);

  useEffect(() => {
    if (deleteData) {
      window.location.reload();
    }
  }, [deleteData]);

  useEffect(() => {
    const saveDirectory = path.join('/');

    fetchData({
      variables: {
        path: `/${saveDirectory}/`,
      },
    });
  }, [path, manualFetch]);

  const handleSetPath = (step, index) => {
    const __path = [...path];
    __path.length = index + 1;
    setPath(__path);
  };

  const handleDeleteDirectory = item => {
    console.log('handleDeleteDirectory', item);
    alert('Folder deletion is not yet supported.');
  };

  return (
    <Meta title={setup.title}>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Box>
          {path.map((step, index) => (
            <Heading
              key={`step-${step}-${index}`}
              fontSize="1.5rem"
              color="gray.800"
              fontWeight="600"
              cursor={'pointer'}
              display={'inline-block'}
              mr="5px"
              onClick={() => handleSetPath(step, index)}
            >
              {index > 0 && '/ '}

              {step}
            </Heading>
          ))}
        </Box>
        <Flex>
          <NewDirectoryModal path={path} />
          <Uploader path={path} reFetch={setManualFetch} />
        </Flex>
      </Flex>
      {/* <HeaderWithActions title={setup.title}>
        <Stack ml="auto" isInline spacing="15px">
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions> */}
      <Box
        mt="25px"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w="100%"
      >
        {data?.bunnyFile_findMany.map((item, i) => {
          if (item.ObjectName === 'dir_data.txt') {
            return null;
          }

          return (
            <Flex
              key={`image-item${i}-${item?.ObjectName}`}
              p="1rem"
              borderBottom={
                data?.bunnyFile_findMany?.length !== i + 1 &&
                '1px solid #aeaeae'
              }
              justifyContent={'space-between'}
            >
              <Flex
                cursor={'pointer'}
                onClick={() => {
                  if (item?.IsDirectory) {
                    setPath([...path, item?.ObjectName]);
                  } else {
                    window.open(item?.url);
                  }
                }}
              >
                {item?.IsDirectory && (
                  <Box marginRight={1}>
                    <AiOutlineFolder fontSize={'20px'} />
                  </Box>
                )}
                <Text>{item?.ObjectName}</Text>
              </Flex>
              <Flex alignItems={'center'}>
                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      aria-label="Delete"
                      colorScheme="red"
                      icon={<AiFillDelete />}
                    />
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverHeader>
                        Are you sure you want to delete this{' '}
                        {item?.IsDirectory
                          ? 'directory and all its content'
                          : 'item'}
                        ?
                      </PopoverHeader>
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Flex justifyContent={'end'}>
                          <Button
                            colorScheme="red"
                            onClick={() => {
                              if (!item?.IsDirectory) {
                                deleteFile({ variables: { url: item?.url } });
                              } else {
                                handleDeleteDirectory(item?.ObjectName);
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </Flex>
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </Flex>
            </Flex>
          );
        })}
      </Box>
    </Meta>
  );
};

export default List;
