const Container = {
  baseStyle: {},
  variants: {
    'site-wrapper': {
      maxWidth: '1600px',
      px: { base: '10px', md: '30px' },
    },
    'auth-wrapper': {
      maxWidth: '1100px',
    },
    'page-wrapper': {
      maxWidth: '1300px',
      px: { base: '20px', md: '30px' },
    },
  },
};

export default Container;
