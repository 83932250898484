import { useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  FormControl,
  FormLabel,
  Divider,
} from '@chakra-ui/react';

import { CREATE_FOLDER } from './graphql';

const NewDirectoryModal = ({ path }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit } = useForm({
    shouldUseNativeValidation: true,
  });
  const [getUploadData] = useLazyQuery(CREATE_FOLDER);

  const onSubmit = async ({ name }) => {
    const { data } = await getUploadData({
      variables: {
        path: `/${path.join('/')}/${name}/`,
      },
    });
    if (data?.bunnyFile_dir_create) {
      window.location.reload();
    }
  };

  return (
    <>
      <Button marginRight={4} onClick={onOpen}>
        Create Folder
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create a folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>Folder Name</FormLabel>
                <Input
                  {...register('name', {
                    required: 'Please enter directory name.',
                  })}
                />
              </FormControl>
              <Divider my={4} />
              <Button type="submit" colorScheme="green">
                Submit
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewDirectoryModal;
