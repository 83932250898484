import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Field } from '../../components/Form';
import Links from './components/Links';
import Controls from './Controls';

const TextWithHeading = ({ index, name }) => (
  <Flex
    w="100%"
    direction="column"
    align="flex-start"
    rounded="5px"
    bg="white"
    p="15px"
    mb="15px"
  >
    <Controls index={index} />
    <Flex direction="column" w="100%">
      <Field
        name={`${name}[${index}].heading`}
        component="text"
        placeholder="Heading"
      />
      <Field
        name={`${name}[${index}].text`}
        component="richtext"
        placeholder="Text Content"
      />

      <Links name={name} index={index} />
    </Flex>
  </Flex>
);

export default TextWithHeading;
