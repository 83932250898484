import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import {
  Button,
  Grid,
  useToast,
  Box,
  Flex,
  IconButton,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  Tooltip,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import Select from 'react-select';
import { MdOutlineNotificationAdd } from 'react-icons/md';
import { FaRegClock } from 'react-icons/fa';
import dayjs from 'dayjs';
import { Field } from '../Form';
import { FETCH_ALL as BRANCH_FETCH_ALL } from '../../containers/Branches/graphql';
import { EDIT, SCHEDULE, LIST_SCHEDULES } from './graphql';
import { DELETE_SCHEDULE } from '../../containers/Notifications/graphql';
import DatePickerComponent from '../../Fields/DatePicker';

const FeedFields = ({ targetID, feed }) => {
  const params = useParams();
  const toast = useToast();
  const { setValue } = useFormContext();
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [scheuledDate, setScheuledDate] = useState(null);
  const { data: branchData } = useQuery(BRANCH_FETCH_ALL);

  const [
    editFeed,
    { data: editFeedData, loading: editFeedLoading, error: editFeedError },
  ] = useMutation(EDIT);

  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);
  const { id } = params;

  const branchesOptions = branchData?.branches_pagination?.items.map(
    (branch) => ({
      label: branch.code,
      value: branch._id,
    }),
  );

  useEffect(() => {
    const selectedBranchesIds = feed?.visibility?.branches.map(
      (branch) => branch._id,
    );
    if (!selectedBranchesIds) {
      return;
    }
    const result = branchesOptions
      ? branchesOptions.filter((branch) =>
          selectedBranchesIds.includes(branch.value),
        )
      : [];

    setSelectedBranches(result);
    console.log('feed', feed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed?.visibility?.branches, branchData?.branches_pagination?.items]);

  useEffect(() => {
    setValue('feed.visibility.public', true);
  }, []);

  useEffect(() => {
    if (editFeedData) {
      toast({
        title: 'Saved',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }
    if (editFeedError) {
      toast({
        title: 'Error, try again',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [editFeedError, editFeedData]);

  return (
    <>
      {type === 'Edit' && targetID && (
        <RightSideBar
          targetID={targetID}
          editFeed={editFeed}
          editFeedLoading={editFeedLoading}
          feed={feed}
          setScheuledDate={setScheuledDate}
          scheuledDate={scheuledDate}
          id={id}
        />
      )}
      {type === 'Edit' && (
        <Box mt={5}>
          <Text fontWeight="medium" py={2}>
            Branch
          </Text>
          <Select
            options={branchesOptions}
            placeholder="leave blank for all branches"
            name="branches"
            isMulti
            value={selectedBranches}
            onChange={(value) => {
              setSelectedBranches(value);
              const branches = value
                .map((item) => ({ _id: item.value }))
                .filter((item) => item._id);
              editFeed({
                variables: {
                  record: {
                    visibility:
                      branches?.length > 0 ? { branches } : { public: true },
                  },
                  _id: targetID,
                },
              });
            }}
          />
        </Box>
      )}
      <Grid templateColumns={'100px 1fr'}>
        {/* <Field
          component="switch"
          name="feed.visibility.public"
          placeholder="All Branches"
          targetID={targetID}
          isChecked={feed?.visibility?.public || false}
          onChange={async () => {
            const newValue = !isPublic;
            setIsPublic(newValue);
            await editFeed({
              variables: {
                record: {
                  visibility: { public: newValue, branches: [] },
                },
                _id: targetID,
              },
            });
            if (typeof refetch === 'function') {
              refetch();
            }
          }}
        /> */}
        {/*  && !isPublic && ( */}

        {/* )} */}
      </Grid>
      <Field
        component="textarea"
        name="feed.title"
        placeholder="Title"
        rules={{ required: true }}
        customUpdate={editFeed}
        targetProperty="title"
        targetID={targetID}
      />
    </>
  );
};

const RightSideBar = ({
  targetID,
  editFeed,
  editFeedLoading,
  feed,
  id,
  setScheuledDate,
  scheuledDate,
}) => {
  const [fetchSchedules, { data: scheduleListData }] =
    useLazyQuery(LIST_SCHEDULES);

  const [
    scheduleFeedItem,
    { data: scheduleData, loading: scheduleLoading, error: sceduleError },
  ] = useMutation(SCHEDULE);

  useEffect(() => {
    fetchSchedules({
      variables: {
        filter: {
          feed: {
            _id: id,
          },
          // completed: false,
        },
      },
      fetchPolicy: 'network-only',
    });
  }, []);

  useEffect(() => {
    if (scheduleData) {
      window.location.reload();
    }
  }, [scheduleData]);

  return (
    <Flex
      bg="white"
      position={'absolute'}
      zIndex={1}
      right={'5rem'}
      // w="400px"
      top="74px"
      p="1rem"
      // templateColumns={'1fr 1fr 1fr'}
      gap={10}
    >
      <Box>
        <PublishOrSchedule
          editFeed={editFeed}
          targetID={targetID}
          editFeedLoading={editFeedLoading}
          feed={feed}
          scheduleListData={scheduleListData}
          setScheuledDate={setScheuledDate}
          scheduleFeedItem={scheduleFeedItem}
          scheuledDate={scheuledDate}
          id={id}
        />
      </Box>
      <Box>
        <Field
          component="switch"
          name="feed.isSticky"
          placeholder="Pin"
          onChange={(e) => {
            editFeed({
              variables: {
                record: {
                  isSticky: e,
                },
                _id: targetID,
              },
            });
          }}
          disabled={editFeedLoading}
          targetProperty="isSticky"
          targetID={targetID}
        />

        <Flex direction={'column'}>
          <label>Notify</label>
          <IconButton
            colorScheme="green"
            aria-label="Search database"
            as="a"
            href={`/notifications/create/?title=${feed?.title}`}
            icon={<MdOutlineNotificationAdd />}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

const PublishOrSchedule = ({
  editFeed,
  targetID,
  editFeedLoading,
  feed,
  id,
  setScheuledDate,
  scheduleFeedItem,
  scheuledDate,
}) => {
  const [scheuledStartDate, setScheuledStartDate] = useState(null);
  const [scheuledEndDate, setScheuledEndDate] = useState(null);
  const [dates, setDates] = useState([]);

  const [fetchSchedules, { data: scheduleListData }] =
    useLazyQuery(LIST_SCHEDULES);

  useEffect(() => {
    fetchSchedules({
      variables: {
        filter: {
          feed: {
            _id: id,
          },
          // completed: false,
        },
      },
      fetchPolicy: 'no-cache',
    });
  }, []);

  const [deleteSchedule, { data, loading, error }] =
    useMutation(DELETE_SCHEDULE);

  useEffect(() => {
    if (data) {
      window.location.reload();
    }
  }, [data]);

  useEffect(() => {
    if (scheduleListData?.schedules_pagination?.items) {
      const sorted = [...scheduleListData?.schedules_pagination?.items];

      const futureDates = sorted.filter(
        (item) => new Date(item.date) > new Date(),
      );

      setDates(futureDates);
    }
  }, [scheduleListData]);

  return (
    <Flex direction={'column'}>
      {!scheduleListData?.schedules_pagination?.items.filter(
        (val) => val?.feed?.active !== false,
      )?.length && (
        <>
          <label>Publish</label>
          <Field
            component="switch"
            name="feed.active"
            onChange={(e) => {
              editFeed({
                variables: {
                  record: {
                    active: e,
                    ...(e && { publishedAt: new Date() }),
                  },
                  _id: targetID,
                },
              });
            }}
            disabled={editFeedLoading}
            targetProperty="active"
            targetID={targetID}
          />
        </>
      )}

      {scheduleListData?.schedules_pagination?.items?.length > 0 &&
        scheduleListData?.schedules_pagination?.items?.map((sched) => (
          <Box key={sched?._id}>
            <strong>{sched?.feed?.active ? 'Start Date' : 'End Date'}</strong>
            <Tooltip label="Remove">
              <Flex
                direction="column"
                onClick={() => {
                  deleteSchedule({ variables: { id: sched?._id } });
                }}
              >
                <Text
                  as="strong"
                  fontSize="10px"
                  color="purple.400"
                  cursor="pointer"
                >
                  {dayjs(sched.date).format('DD/MM/YY')}
                </Text>
                <Text
                  as="strong"
                  fontSize="10px"
                  color="purple.400"
                  cursor="pointer"
                >
                  {dayjs(sched.date).format('HH:mm')}
                </Text>
              </Flex>
            </Tooltip>
          </Box>
        ))}

      {scheduleListData?.schedules_pagination?.items?.length < 2 && (
        <Popover>
          <PopoverTrigger>
            <Button
              size="xs"
              variant="link"
              leftIcon={<FaRegClock />}
              m="0"
              p="0"
              width="fit-content"
            >
              Schedule
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Schedule</PopoverHeader>
            <PopoverBody>
              <Flex direction={'column'} gap={5}>
                {!feed?.active &&
                  !scheduleListData?.schedules_pagination?.items.filter(
                    (val) => val?.feed?.active !== false,
                  )?.length && (
                    <label>
                      Start Time
                      <DatePickerComponent
                        showTimeSelect
                        minDate={new Date()}
                        onChange={(e) => {
                          setScheuledStartDate(e);
                        }}
                        dateFormat="YYYY-MM-DD HH:mm:00+00:00"
                        disablePast
                        timeIntervals={5}
                        rules={{ required: true }}
                      />
                    </label>
                  )}

                {!scheduleListData?.schedules_pagination?.items.filter(
                  (val) => val?.feed?.active !== true,
                )?.length && (
                  <label>
                    End Time
                    <DatePickerComponent
                      showTimeSelect
                      minDate={new Date()}
                      onChange={(e) => {
                        setScheuledEndDate(e);
                      }}
                      dateFormat="YYYY-MM-DD HH:mm:00+00:00"
                      disablePast
                      timeIntervals={5}
                      rules={{ required: true }}
                    />
                  </label>
                )}

                <Button
                  colorScheme="green"
                  onClick={() => {
                    if (scheuledStartDate) {
                      scheduleFeedItem({
                        variables: {
                          record: {
                            feed: {
                              _id: id,
                              active: true,
                            },
                            enabled: true,
                            date: dayjs(scheuledStartDate).toDate(),
                          },
                        },
                      });
                    }
                    if (scheuledEndDate) {
                      scheduleFeedItem({
                        variables: {
                          record: {
                            feed: {
                              _id: id,
                              active: false,
                            },
                            enabled: true,
                            date: dayjs(scheuledEndDate).toDate(),
                          },
                        },
                      });
                    }
                  }}
                >
                  Save
                </Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </Flex>
  );
};
export default FeedFields;
