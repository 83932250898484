import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Stack,
  useColorModeValue,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
} from '@chakra-ui/react';

import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import {
  DELETE,
  FETCH_ALL,
  FETCH_ALL_STAFF_USERS,
  RESET_PASSWORD,
} from './graphql';

import { FETCH_ALL as BRANCH_FETCH_ALL } from '../Branches/graphql';
import { setup } from './setup';
import BulkUploader from './BulkUploader';

const filters = [
  {
    value: 'id',
    type: 'text',
    label: 'ID',
    isOperator: false,
  },
];

const List = () => {
  const [fetchData, { loading, error, data, refetch }] =
    useLazyQuery(FETCH_ALL);

  const {
    loading: usersLoading,
    error: usersError,
    data: usersData,
    refetch: refetchUsers,
  } = useQuery(FETCH_ALL_STAFF_USERS, {
    variables: {
      limit: 1000000,
    },
  });

  const { data: branchData } = useQuery(BRANCH_FETCH_ALL);

  const [resetPassword, { error: resetError }] = useMutation(RESET_PASSWORD);

  // console.log('data', data);
  const columns = [
    {
      Header: 'Staff ID',
      accessor: 'id',
      disableSortBy: false,
      Cell: ({ value }) => <Text as="b">{value}</Text>,
    },
    {
      Header: 'First Name',
      accessor: 'name',
      disableSortBy: false,
      Cell: ({ value }) => <Text as="b">{value}</Text>,
    },
    {
      Header: 'Last Name',
      accessor: 'last_name',
      disableSortBy: false,
      Cell: ({ value }) => <Text as="b">{value}</Text>,
    },
    {
      Header: 'Password',
      accessor: 'createdAt',
      disableSortBy: false,
      Cell: ({ row }) => {
        const { isOpen, onOpen, onClose } = useDisclosure();

        const [newPassword, setNewPassword] = useState('abc123');
        return (
          <Flex alignItems={'center'}>
            <Button variant="outline" size="sm" onClick={onOpen}>
              Reset
            </Button>

            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  Reset Password for {row?.original?.id}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Input onChange={e => setNewPassword(e.target.value)} />
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="green"
                    onClick={() => {
                      resetPassword({
                        variables: {
                          id: row?.original?._id,
                          password: newPassword,
                          passwordExpires: Date.now(),
                        },
                      });
                      onClose();
                    }}
                  >
                    Reset
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Flex>
        );
      },
    },
    {
      Header: 'Notifications',
      accessor: 'branch',
      disableSortBy: false,
      Cell: ({ value }) => {
        const branch = branchData?.branches_pagination?.items.find(
          el => el._id === value?._id
        );

        return branch?.code ?? 'NA';
      },
    },

    {
      Header: 'Accepted Terms',
      accessor: 'acceptedTerms',
      disableSortBy: true,
      Cell: ({ value }) => (value ? 'Yes' : '-'),
    },
    {
      Header: 'Notification Key',
      accessor: 'notifications',
      disableSortBy: false,
      Cell: ({ value }) => (
        <div>{value?.devices?.length > 0 ? 'Ready' : '-'}</div>
      ),
    },
  ];

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml="auto" isInline spacing="15px">
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
          <BulkUploader
            staff={usersData?.staff_findMany}
            reload={() => {
              window.location.reload();
            }}
          />
        </Stack>
      </HeaderWithActions>

      <Box
        mt="25px"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w="100%"
      >
        <ListView
          setup={setup}
          fetchData={fetchData}
          data={data?.staff_pagination}
          loading={loading}
          error={error}
          gqlDelete={DELETE}
          columns={columns}
          filters={filters}
        />
      </Box>
    </Meta>
  );
};

export default List;
