import {
  Alert,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { CHECK_AUTH } from '../../containers/Messages/graphql';
import { useForm } from 'react-hook-form';
import { BsPersonCircle } from 'react-icons/bs';

const ACCOUNT_UPDATE = gql`
  mutation Admins_updateById($id: MongoID, $record: UpdateByIdadminsInput!) {
    admins_updateById(_id: $id, record: $record) {
      error {
        message
      }
      recordId
    }
  }
`;
export default function AccountInfo() {
  const [isOpen, setOpen] = useState(false);

  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { data: userData } = useQuery(CHECK_AUTH);

  const first_name = useMemo(() => userData?.admins_findById?.name, [userData]);
  const last_name = useMemo(
    () => userData?.admins_findById?.last_name,
    [userData]
  );

  const [updateAccount, { loading }] = useMutation(ACCOUNT_UPDATE);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const [firstName, setFirstName] = useState(first_name);

  const [lastName, setLastName] = useState(last_name);

  useEffect(() => {
    setFirstName(first_name || '');
    setLastName(last_name || '');
    if (first_name === null || last_name === null) {
      setOpen(true);
    }
  }, [userData]);

  const handleFormSubmit = formData => {
    updateAccount({
      variables: {
        id: userData?.admins_findById?._id,
        record: {
          name: firstName,
          last_name: lastName,
        },
      },
      onCompleted: res => {
        console.log('🚀 ~ handleFormSubmit ~ res:', res);
        setOpen(false);
      },
    });
  };
  return (
    <>
      <Button variant="ghost" colorScheme="whiteAlpha">
        <BsPersonCircle size={30} onClick={() => setOpen(true)} />
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <ModalHeader>Account Info</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl name="firstName" control={control}>
                <FormLabel>First name</FormLabel>
                <Input
                  ref={initialRef}
                  placeholder="First name"
                  value={firstName}
                  onChange={e => {
                    setFirstName(e.target.value);
                  }}
                />
                {firstName === '' && (
                  <Alert status="warning">Please enter your first name.</Alert>
                )}
              </FormControl>

              <FormControl mt={4} name="lastName" control={control}>
                <FormLabel>Last name</FormLabel>
                <Input
                  placeholder="Last name"
                  value={lastName}
                  onChange={e => {
                    setLastName(e.target.value);
                  }}
                />
                {lastName === '' && (
                  <Alert status="warning">Please enter your last name.</Alert>
                )}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                type="submit"
                isLoading={loading}
                loadingText="Saving"
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
