import { Button, Stack } from '@chakra-ui/react';
import React from 'react';

import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useRecoilState } from 'recoil';
import { tableAtom } from '../ListView/atoms';

const CursorPagination = ({ onChangePage, nextPage, previousPage }) => {
  const [tableState, setTableState] = useRecoilState(tableAtom);

  const prev = () => {
    const currentSkip = tableState.params.skip;
    const limit = tableState.params.pagination;
    if (currentSkip !== 0) {
      setTableState((old) => ({
        ...old,
        params: {
          ...tableState.params,
          skip: currentSkip - limit,
        },
      }));
    }
  };

  const next = () => {
    const currentSkip = tableState.params.skip;
    const limit = tableState.params.pagination;

    setTableState((old) => ({
      ...old,
      params: {
        ...tableState.params,
        skip: currentSkip + limit,
      },
    }));
  };

  return (
    <Stack
      isInline
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      px="10px"
      py="5px"
      bottom={0}
      rounded={0}
      boxShadow="none"
    >
      <Button
        onClick={() => prev()}
        isDisabled={tableState.params.skip === 0}
        variant="ghost"
        colorScheme="blackAlpha"
        size="sm"
        leftIcon={<MdChevronLeft fontSize="20px" />}
      >
        Previous Page
      </Button>{' '}
      <Button
        onClick={() => next()}
        size="sm"
        variant="ghost"
        colorScheme="blackAlpha"
        rightIcon={<MdChevronRight fontSize="20px" />}
      >
        Next Page
      </Button>{' '}
    </Stack>
  );
};

export default CursorPagination;
