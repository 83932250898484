import { Menu, MenuButton, MenuItem, MenuList, Button } from '@chakra-ui/react';
import React from 'react';
import { BiChevronDown } from 'react-icons/bi';

import PopConfirm from '../PopConfirm';

const TableActions = ({ setup, onDelete, selected }) => (
  <Menu>
    <MenuButton
      as={Button}
      rightIcon={<BiChevronDown fontSize="22px" />}
      isDisabled={selected?.length === 0}
    >
      Actions
    </MenuButton>
    <MenuList>
      <MenuItem
        isDisabled={
          (!setup.canSelect || !setup.canDelete) && selected?.length !== 0
        }
      >
        <PopConfirm
          okText="Delete"
          onConfirm={() => onDelete()}
          okButtonProps={{
            variantColor: 'error',
          }}
          cancelButtonProps={{
            variantColor: 'gray',
          }}
          title="Are you sure you want to delete these entries?  This cannot be undone."
        >
          Delete {selected?.length} items
        </PopConfirm>
      </MenuItem>
    </MenuList>
  </Menu>
);
export default TableActions;
