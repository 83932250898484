import { Flex } from '@chakra-ui/react';
import React from 'react';
import { Field } from '../../components/Form';
import Controls from './Controls';

const FullWidthImage = ({ field, index, name }) => (
  <Flex
    w="100%"
    direction="column"
    align="flex-start"
    rounded="5px"
    bg="white"
    p="15px"
    mb="15px"
  >
    <Controls index={index} />
    <Flex direction="column" w="100%">
      <Field
        name={`${name}[${index}].image`}
        component="file"
        width="100%"
        minWidth="100%"
        label="Full Width Image"
        isRepeater={true}
      />
    </Flex>
  </Flex>
);

export default FullWidthImage;
