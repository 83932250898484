import React from 'react';
import {
  Flex,
  Button,
  PopoverTrigger,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  IconButton,
  Portal,
  PopoverHeader,
} from '@chakra-ui/react';
import { AiFillDelete } from 'react-icons/ai';

function DeleteButton({ id, setup, deleteFunction }) {
  const HandleDelete = async () => {
    await deleteFunction({
      variables: {
        id,
      },
    });

    setTimeout(() => {
      window.location.href = `/${setup.model}`;
    }, 1000);
  };

  return (
    <Flex>
      <Popover>
        <PopoverTrigger>
          <IconButton
            aria-label="Delete"
            colorScheme="red"
            icon={<AiFillDelete />}
          />
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>
              Warning! Are you sure you want to delete this item?
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              <Flex justifyContent={'center'}>
                {' '}
                <Button colorScheme="red" onClick={HandleDelete}>
                  Delete
                </Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </Flex>
  );
}

export default DeleteButton;
