import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';

const Error = ({ open }) => {
  const toast = useToast();

  useEffect(() => {
    if (open) {
      toast({
        status: 'error',
        title: 'Error Fetching Data',
        description: 'There was an error fetching the requested data.',
        position: 'top-right',
      });
    }
  }, [open]);

  return null;
};

export default Error;
