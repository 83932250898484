/* global localStorage, */

import Cookies from 'js-cookie';

const bypassAuth = process.env.REACT_APP_BYPASS_AUTH === 'true';

export const generateSlugFromSite = () => {
  const siteName = process.env.REACT_APP_SITENAME;
  return siteName.split(' ').join('_');
};

/// ///// Authentication Helpers

export const authToken = JSON.parse(
  localStorage.getItem(`${generateSlugFromSite()}-token`),
);

export const hasAuthToken = () => (bypassAuth ? true : !!authToken);

export const checkAuthTokenExpiry = () => {
  const now = new Date();

  if (bypassAuth) {
    localStorage.setItem(
      `${generateSlugFromSite()}-token`,
      JSON.stringify({
        token: 'testToken',
        expiry: now.getTime() + 230400000,
      }),
    );
  } else {
    if (hasAuthToken() && now.getTime() > authToken.expiry) {
      localStorage.removeItem(`${generateSlugFromSite()}-token`);
      return false;
    }
    if (hasAuthToken() && now.getTime() < authToken.expiry) {
      localStorage.setItem(
        `${generateSlugFromSite()}-token`,
        JSON.stringify({
          ...authToken,
          expiry: now.getTime() + 230400000,
        }),
      );
      return true;
    }
  }
};

export const saveSiteToStorage = (sites) => {
  const hasSiteSaved = localStorage.getItem(`${generateSlugFromSite()}-site`);
  if (!hasSiteSaved) {
    localStorage.setItem(
      `${generateSlugFromSite()}-site`,
      JSON.stringify(sites[0]),
    );
    window.location.reload();
  }
};

export const getSite = JSON.parse(
  localStorage.getItem(`${generateSlugFromSite()}-site`),
);

export const logout = () => {
  Cookies.remove(`${generateSlugFromSite()}-token`);
  localStorage.removeItem(`${generateSlugFromSite()}-site`);

  window.location.href = '/';
};

export const checkPermissions = ({ has = [], required = [] }) => {
  let hasPermission;
  if (required) {
    required.forEach((r) => {
      if (has.some((e) => e.code === r)) {
        hasPermission = true;
      }
    });
  }
  // const hasPermission = has.some((e) => e.code === required);
  const hasAll = has.some((e) => e.code === 'ALL');

  if (hasAll === true) {
    return true;
  }

  if (hasPermission === true) {
    return true;
  }
  return false;
};

/// ///// Form Helpers

export const saveFormToStorage = ({ path, values }) => {
  localStorage.setItem(path, JSON.stringify(values));
};

export const getFormFromStorage = ({ path, values }) =>
  JSON.parse(localStorage.getItem(path));

// General

export const downloadFile = (url) => {
  const a = document.createElement('a');
  a.href = url;
  const fileName = url.split('/').pop();
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};
