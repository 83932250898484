import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  Select,
} from '@chakra-ui/react';
import QuestionOptions from './QuestionOptions';

const emptyOptions = [{ title: '', responseType: 'boolean' }];

const QuestionForm = ({
  isOpen,
  onClose,
  onSubmit,
  data,
  surveyId,
  isLoading,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const fieldType = watch('type');

  const [options, setOptions] = useState(emptyOptions);
  const [isEdit, setIsEdit] = useState(false);
  const showOptions = ['radio', 'checkbox', 'select'].includes(fieldType);
  useEffect(() => {
    setValue('type', 'input');
  }, [setValue]);

  // for edit
  useEffect(() => {
    if (data) {
      reset(data);
      setOptions(data.options);
      setIsEdit(true);
    }
  }, [data, reset]);

  useEffect(() => {
    setValue('options', options);
  }, [options, setValue]);

  const handleAddOption = () => {
    const newOptions = [
      ...options,
      {
        title: '',
        responseType: fieldType === 'select' ? 'string' : 'boolean',
      },
    ];
    setOptions(newOptions);
  };

  const handleChangeOption = (formData, index) => {
    const item = { ...options[index], ...formData };

    const newOptions = [...options];
    newOptions[index] = item;
    setOptions(newOptions);
  };

  const handleRemoveOption = (val) => {
    const newOptions = options.filter((item) => item._id !== val._id);
    setOptions([...newOptions]);
  };

  const handleOnClose = () => {
    onClose();
    reset({});
    setOptions(emptyOptions);
  };

  const handleFormSubmit = (formData) => {
    const newData = { ...formData, surveyId, _id: data?._id };
    // if (fieldType === 'input' || fieldType === 'textarea') {
    //   newData.options = [
    //     {
    //       title: 'answer',
    //       responseType: 'string',
    //     },
    //   ];
    // } else {
    //   newData.options = options.filter((item) => item.title !== ''); // some cleanup
    // }
    onSubmit(newData, isEdit);
    handleOnClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <ModalHeader>Question Update</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDirection="column" gap="2">
              <FormControl>
                <FormLabel>Question</FormLabel>
                <Input type="text" {...register('title', { required: true })} />
                {errors.title && (
                  <FormErrorMessage>Question is required.</FormErrorMessage>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Type</FormLabel>
                <Select
                  defaultValue={data?.type || 'input'}
                  placeholder="Select option"
                  {...register('type', { required: true })}
                >
                  <option value="input">Input</option>
                  <option value="textarea">Textarea</option>
                  <option value="radio">Radio</option>
                  <option value="checkbox">Checkbox</option>
                  <option value="select">Select</option>
                </Select>
                {errors.type && (
                  <FormErrorMessage>Type is required.</FormErrorMessage>
                )}
              </FormControl>
              {showOptions && (
                <QuestionOptions
                  options={options}
                  handleAddOption={handleAddOption}
                  handleChangeOption={handleChangeOption}
                  handleRemoveOption={handleRemoveOption}
                />
              )}
              <FormControl>
                <Checkbox defaultChecked {...register('isRequired')}>
                  Is Required Field
                </Checkbox>
              </FormControl>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              type="submit"
              isLoading={isLoading}
              loadingText="Saving"
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export default QuestionForm;
