import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
  query Staff_pagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManystaffInput
    $sort: SortFindManystaffInput
  ) {
    staff_pagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        id
        branch {
          _id
        }
        notifications {
          devices
        }
        acceptedTerms
        _id
        updatedAt
        createdAt
        name
        last_name
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const FETCH_ALL_STAFF_USERS = gql`
  query Query($filter: FilterFindManystaffInput, $limit: Int) {
    staff_findMany(filter: $filter, limit: $limit) {
      _id
      branch {
        _id
      }
      id
      acceptedTerms
      notifications {
        devices
      }
      name
    }
  }
`;

export const FETCH = gql`
  query Staff_findById($id: MongoID) {
    staff_findById(_id: $id) {
      _id
      images {
        _id
        url
      }
      name
      last_name
      branch {
        _id
      }
      notifications {
        devices
      }
      acceptedTerms
      updatedAt
      createdAt
    }
  }
`;

export const CREATE = gql`
  mutation Staff_registration(
    $staffRegistrationId: String!
    $branchId: String
    $branchCode: String
    $password: String
    $passwordExpires: Date
    $name: String
    $lastName: String
  ) {
    staff_registration(
      id: $staffRegistrationId
      branchId: $branchId
      branchCode: $branchCode
      name: $name
      last_name: $lastName
    ) {
      id
      branch {
        _id
      }
      notifications {
        devices
      }
      acceptedTerms
      _id
      user(password: $password, passwordExpires: $passwordExpires) {
        _id
        passwordExpires
        token
        created
      }
    }
  }
`;

export const UPDATE = gql`
  mutation Staff_updateById($record: UpdateByIdstaffInput!, $id: MongoID) {
    staff_updateById(record: $record, _id: $id) {
      error {
        message
      }
      record {
        branch {
          _id
        }
        _id
        notifications {
          devices
        }
      }
    }
  }
`;

export const GET_BRANCH_ID = gql`
  query Branches_pagination($filter: FilterFindManybranchesInput) {
    branches_pagination(filter: $filter) {
      items {
        _id
        code
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation User_resetPassword(
    $password: String!
    $id: MongoID
    $token: String
    $passwordExpires: Date
  ) {
    user_resetPassword(
      password: $password
      _id: $id
      token: $token
      passwordExpires: $passwordExpires
    ) {
      user {
        _id
      }
      payload
    }
  }
`;

export const EDIT = gql`
  mutation Owner_updateById($record: UpdateByIdownerInput!, $_id: MongoID) {
    owner_updateById(record: $record, _id: $_id) {
      error {
        message
      }
      record {
        _id
      }
    }
  }
`;

export const DELETE = gql`
  mutation Owner_remove($_id: MongoID!) {
    owner_remove(_id: $_id) {
      payload
    }
  }
`;
export const DELETE_STAFF = gql`
  mutation Staff_removeMany($filter: FilterRemoveManystaffInput!) {
    staff_removeMany(filter: $filter) {
      numAffected
      error {
        ... on MongoError {
          message
        }
      }
    }
  }
`;

export const FETCH_LEGACY = gql`
  query Owner_findLegacy($filter: legacy_ownerInput) {
    owner_findLegacy(filter: $filter) {
      id
      email
      full_name
    }
  }
`;

export const MIGRATE_OWNER = gql`
  mutation Owner_migrate($id: String) {
    owner_migrate(id: $id) {
      _id
      email
      user {
        _id
        created
      }
    }
  }
`;

export const ADMIN_FIND_BY_ID = gql`
  query Admins_findById($id: MongoID) {
    admins_findById(_id: $id) {
      email
      _id
      updatedAt
      createdAt
      role
      last_name
      name
    }
  }
`;
