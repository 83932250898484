import { gql } from '@apollo/client';

export const CREATE = gql`
  mutation Admins_registration($email: String, $password: String) {
    admins_registration(email: $email) {
      user(password: $password) {
        token
        passwordExpires
        created
        _id
      }
      _id
    }
  }
`;
