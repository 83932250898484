import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
  query Query(
    $page: Int
    $perPage: Int
    $sort: SortFindManyemojisInput
    $filter: FilterFindManyemojisInput
  ) {
    emojis_pagination(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        unicode
        name
        _id
        updatedAt
        createdAt
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const FETCH = gql`
  query Emojis_findById($_id: MongoID!) {
    emojis_findById(_id: $_id) {
      _id
      name
      unicode
      createdAt
      updatedAt
    }
  }
`;

export const CREATE = gql`
  mutation Emojis_createOne($record: CreateOneemojisInput!) {
    emojis_createOne(record: $record) {
      error {
        message
      }
      recordId
      record {
        _id
        name
        unicode
        updatedAt
        createdAt
      }
    }
  }
`;

export const EDIT = gql`
  mutation Emojis_updateById($_id: MongoID!, $record: UpdateByIdemojisInput!) {
    emojis_updateById(_id: $_id, record: $record) {
      error {
        message
      }
      recordId
      record {
        _id
        name
        unicode
        updatedAt
        createdAt
      }
    }
  }
`;

export const DELETE_MANY = gql`
  mutation Emojis_removeMany($filter: FilterRemoveManyemojisInput!) {
    emojis_removeMany(filter: $filter) {
      numAffected
      error {
        message
      }
    }
  }
`;
export const DELETE_ID = gql`
  mutation Emojis_removeById($id: MongoID!) {
    emojis_removeById(_id: $id) {
      error {
        ... on MongoError {
          code
          message
        }
        message
      }
    }
  }
`;
