import React from 'react';
import { Flex } from '@chakra-ui/react';

import MultipleImageUpload from '../../components/ImageUpload';

const BunnyUploadImage = ({
  onUpload,
  targetID,
  value,
  multiple,
  targetKey,
}) => (
  <Flex direction="column" w="100%" py="0px">
    <MultipleImageUpload
      onUpload={onUpload}
      targetID={targetID}
      value={value}
      multiple={multiple}
      targetKey={targetKey}
    />
  </Flex>
);

export default BunnyUploadImage;
