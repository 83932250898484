import React, { memo } from 'react';
import { Stack, Text, Button } from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import PopConfirm from '../PopConfirm';

const MoreOptions = memo(({ setup }) => {
  const navigate = useNavigate();

  return (
    <Stack isInline spacing="20px" mr="10px">
      <PopConfirm
        onConfirm={() => navigate(`/${setup.model}`)}
        title="Are you sure you want to discard your changes?  All changes will be lost."
      >
        <Button variant="outline" colorScheme="whiteAlpha">
          <Text display={{ xs: 'none', sm: 'inline-block' }}>Discard</Text>
        </Button>
      </PopConfirm>
    </Stack>
  );
});

export default MoreOptions;
