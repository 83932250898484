import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Stack,
  Flex,
  useColorModeValue,
  Text,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AiFillPushpin } from 'react-icons/ai';
import * as emoji from 'node-emoji';
import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import { DELETE_MANY, FETCH_ALL, POST_DATA } from './graphql';
import { setup } from './setup';
import { FETCH_ALL as FETCH_EMOJIS } from '../Emojis/graphql';
import { FETCH_ALL_STAFF_USERS } from '../Users/graphql';
import { FETCH as FETCH_SINGLE_POST } from '../Posts/graphql';

const filters = [
  {
    value: 'title',
    type: 'text',
    label: 'Title',
    isOperator: false,
  },
];

const List = () => {
  const [fetchData, { loading, error, data }] = useLazyQuery(FETCH_ALL);

  const {
    data: emojiData,
    loading: emojiLoading,
    error: emojiError,
  } = useQuery(FETCH_EMOJIS);

  const [
    fetchStaffWithIds,
    { data: staffData, loading: staffLoading, error: staffError },
  ] = useLazyQuery(FETCH_ALL_STAFF_USERS);
  useEffect(() => {
    fetchStaffWithIds({
      variables: {
        limit: 1000000,
      },
    });
  }, []);
  const columns = [
    {
      Header: '',
      accessor: '_id',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Link to={`/${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant="link">View</Button>
        </Link>
      ),
    },
    {
      Header: 'Title',
      accessor: 'title',
      disableSortBy: false,
      Cell: ({ value, row }) => (
        <Flex>
          {row?.original?.isSticky && (
            <AiFillPushpin style={{ marginRight: '3px' }} />
          )}
          <Text>{value}</Text>
        </Flex>
      ),
    },
    {
      Header: 'Reactions',
      accessor: 'type',
      disableSortBy: false,
      Cell: ({ value, row }) =>
        row.original._id ? (
          <Reactions
            id={row.original._id}
            staffData={staffData}
            emojiData={emojiData}
            data={data}
          />
        ) : (
          '-'
        ),
    },
    {
      Header: 'Published',
      accessor: 'active',
      disableSortBy: false,
      Cell: ({ value }) => (value ? 'Active' : '-'),
    },
    {
      Header: 'Updated At',
      accessor: 'updatedAt',
      disableSortBy: false,
      Cell: ({ value }) => <div>{dayjs(value).format('DD/MM/YYYY HH:mm')}</div>,
    },
  ];

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml="auto" isInline spacing="15px">
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>
      <Box
        mt="25px"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w="100%"
      >
        <ListView
          setup={setup}
          fetchData={fetchData}
          defaultFilter={{
            type: 'posts',
          }}
          data={data?.feeds_pagination}
          loading={loading}
          error={error}
          gqlDelete={DELETE_MANY}
          columns={columns}
          filters={filters}
        />
      </Box>
    </Meta>
  );
};

const Reactions = ({ id, staffData, emojiData, data }) => {
  const [
    fetchreactionData,
    {
      loading: reactionDataLoading,
      error: reactionDataError,
      data: reactionData,
    },
  ] = useLazyQuery(POST_DATA);

  const [
    fetchSinglePost,
    { data: postData, loading: postLoading, error: postError },
  ] = useLazyQuery(FETCH_SINGLE_POST);

  useEffect(() => {
    fetchSinglePost({
      variables: { _id: id },
    });
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchreactionData({
        variables: {
          staticpageId: id,
        },
      });
    }
  }, [id]);

  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Flex gap="10px">
      {postData?.posts_findById?.reactions?.length > 0 ? (
        <Flex direction={'row'}>
          <Button
            variant="link"
            cursor={'pointer'}
            onClick={() => {
              onOpen();
            }}
          >
            {reactionData?.posts_reactions_get?.reduce(
              (a, b) => a + b.users_count,
              0
            )}
          </Button>
          <UserTableModal
            isOpen={isOpen}
            onClose={onClose}
            reactionData={reactionData}
            staffData={staffData}
            reactions={postData?.posts_findById?.reactions}
            data={data}
          />
        </Flex>
      ) : (
        '-'
      )}
    </Flex>
  );
};

const UserTableModal = ({
  isOpen,
  onClose,
  staffData,
  reactionData,
  reactions,
  data,
}) => (
  <Modal isOpen={isOpen} onClose={onClose} size="lg">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Question Update</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {' '}
        <Table>
          <Thead>
            <Tr>
              <Th>user</Th>
              <Th>reaction</Th>
            </Tr>
          </Thead>
          <Tbody>
            {reactions.map((el, i) =>
              el?.users?.map((user, j) => (
                <Tr key={j}>
                  {console.log(el, '<+++++!!!!!')}
                  <Td>
                    {
                      staffData?.staff_findMany?.find(
                        (el) => el._id === user?._id
                      )?.id
                    }
                  </Td>
                  <Td>
                    {console.log(
                      reactionData?.posts_reactions_get,
                      '!!!!!!!!! thoer',
                      el?.emoji?._id
                    )}
                    <FindMeAnEmoji
                      emojiData={reactionData?.posts_reactions_get?.find(
                        (rct) => rct?.emoji?._id === el?.emoji?._id
                      )}
                    />
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </ModalContent>
  </Modal>
);

const FindMeAnEmoji = ({ user, data, emojiData }) =>
  emojiData ? emoji.emojify(emojiData?.emoji?.unicode) : '-';

export default List;
