import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import {
  Text,
  InputGroup,
  InputRightElement,
  Input,
  Button,
  HStack,
  VStack,
  Box,
  Avatar,
} from '@chakra-ui/react';

import { FETCH_CONVERSATION, SEND_MESSAGE, CHECK_AUTH } from './graphql';
import { CREATE } from '../Notifications/graphql';
import { ADMIN_FIND_BY_ID, FETCH } from '../Users/graphql';

const Conversation = ({ message }) => {
  const [newMessage, setNewMessage] = useState('');
  // eslint-disable-next-line prettier/prettier
  const [fetchConversation, { data, refetch }] = useLazyQuery(
    FETCH_CONVERSATION,
    { pollInterval: 10000 }
  ); // refetch every 10 seconds
  const { data: userData } = useQuery(CHECK_AUTH);
  const [fetchAsker, { data: askerData }] = useLazyQuery(FETCH);
  const [fetchUserName, { data: UserNameData }] = useLazyQuery(FETCH);
  const [fetchAdminName, { data: AdminUserNameData }] =
    useLazyQuery(ADMIN_FIND_BY_ID);
  const [sendMessage] = useMutation(SEND_MESSAGE);
  const [notifyDevice] = useMutation(CREATE);
  const [conversationData, setConversationData] = useState([]);

  const conversation = data?.messages_pagination?.items || [];

  const asker = askerData?.staff_findById;

  const taggedUserIds = useMemo(
    () => message?.status?.userIds || [],
    // eslint-disable-next-line prettier/prettier
    [message]
  );

  const currentUserId = useMemo(
    () => userData?.admins_findById?._id,
    // eslint-disable-next-line prettier/prettier
    [userData]
  );

  const otherUserId = useMemo(
    () => taggedUserIds?.filter(id => id !== currentUserId)[0],
    // eslint-disable-next-line prettier/prettier
    [taggedUserIds, currentUserId]
  );

  useEffect(() => {
    if (otherUserId) {
      fetchAsker({
        variables: {
          _id: otherUserId,
        },
      });
    }
  }, [fetchAsker, otherUserId]);

  useEffect(() => {
    // fetch on load
    fetchConversation({
      variables: {
        filter: {
          streamId: message?._id,
        },
        sort: '_ID_DESC',
      },
    });
  }, [fetchConversation, message?._id]);

  // send message
  const handleSend = async () => {
    // make mutation to send message
    await sendMessage({
      variables: {
        record: {
          content: newMessage,
          streamId: message?._id,
        },
      },
    });

    const askerDevices = asker?.notifications?.devices;

    if (askerDevices.length > 0) {
      await notifyDevice({
        variables: {
          record: {
            message: {
              body: newMessage,
              title: 'You have a new message',
            },
            devices: askerDevices,
          },
        },
      });
    }

    // clear input
    setNewMessage('');
    // refetch conversation
    refetch();
  };

  const isViewed = item => {
    let borderColor = 'gray.300';
    let backgroundColor = 'transparent';
    // if not written by current user
    if (item.userId === currentUserId && !item.viewedBy.includes(otherUserId)) {
      // if written by current user and not viewed by other user
      borderColor = 'gray.900';
      backgroundColor = 'gray.100';
    }

    return { borderColor, backgroundColor };
  };

  const [updatedConversation, setUpdatedConversation] = useState([]);

  const getUserName = async userId => {
    try {
      const adminData = await fetchAdminName({
        variables: { id: userId },
      });

      if (adminData?.data?.admins_findById === null) {
        const userData = await fetchUserName({
          variables: { id: userId },
          fetchPolicy: 'no-cache',
        });
        const name = `${userData?.data?.staff_findById?.name} ${userData?.data?.staff_findById?.last_name}`;
        return {
          name:
            name === 'null null' || name === 'undefined undefined'
              ? 'User (No name) '
              : name,
          userType: 'User',
          userImage:
            userData?.data?.staff_findById?.images[0]?.url ||
            'https://img.freepik.com/free-vector/blue-circle-with-white-user_78370-4707.jpg?w=1480&t=st=1719410522~exp=1719411122~hmac=5b22e4b5a4c51ce2d6d693c925146d2e7b203783d0e0149f5d4a85d5eccb96c7',
        };
      } else {
        const name = `${adminData?.data?.admins_findById?.name} ${adminData?.data?.admins_findById?.last_name}`;
        return {
          name:
            name === 'null null' || name === 'undefined undefined'
              ? 'Admin (No name)'
              : name,
          userType: 'Admin',
          userImage:
            'https://img.freepik.com/premium-vector/irrational-type-demand-refers-unplanned-factor-that-appears-due-sudden-whim-sharp_1076610-15527.jpg?w=1480',
        };
      }
    } catch (error) {
      console.error('Error fetching user/admin name:', error);
      return 'Unknown'; // Default value if there's an error
    }
  };

  useEffect(() => {
    const updateConversationWithNames = async () => {
      if (conversation.length > 0) {
        const addedNames = await Promise.all(
          conversation.map(async item => {
            const userId = item.userId;
            const userDetails = await getUserName(userId);
            return { ...item, userDetails };
          })
        );

        setUpdatedConversation(addedNames);
      }
    };

    updateConversationWithNames();
  }, [conversation]);

  return (
    <Box>
      <InputGroup size="lg" marginTop={6} marginBottom={4}>
        <Input
          pr="4.5rem"
          type="text"
          placeholder="Enter message"
          value={newMessage}
          onChange={e => setNewMessage(e.target.value)}
        />
        <InputRightElement width="4.5rem">
          <Button h="1.75rem" size="sm" onClick={handleSend}>
            Send
          </Button>
        </InputRightElement>
      </InputGroup>

      <Box p={4}>
        {updatedConversation.map(item => {
          const isMessageViewed = isViewed(item);
          const isAdmin = item.userDetails?.userType === 'Admin';
          return (
            <HStack
              key={item._id}
              justifyContent={isAdmin ? 'flex-start' : 'flex-end'}
            >
              <Box
                w={'50%'}
                // backgroundColor={isMessageViewed.backgroundColor}
                backgroundColor={isAdmin ? 'gray.100' : 'blue.400'}
                p={2}
                mb={6}
                borderWidth={1}
                borderColor={'transparent'}
                // borderBottomColor={isMessageViewed.borderColor}
                borderRadius={10}
                position={'relative'}
              >
                <Box
                  transform={
                    isAdmin
                      ? `translateX(24px) translateY(-10px) rotate(230deg)`
                      : `translateX(24px) translateY(-10px) rotate(120deg)`
                  }
                  width={0}
                  height={0}
                  borderWidth={'10px'}
                  borderColor={'transparent'}
                  borderTop={0}
                  borderBottomWidth={isAdmin ? '25px' : '40px'}
                  borderBottomColor={isAdmin ? 'gray.100' : 'blue.400'}
                  position={'absolute'}
                  right={isAdmin ? 'none' : 4}
                  left={isAdmin ? -10 : 'none'}
                  bottom={isAdmin ? -6 : -7}
                />
                <Box>
                  <HStack>
                    <HStack alignItems={'center'}>
                      <Avatar
                        size="xs"
                        name="Dan Abrahmov"
                        src={item?.userDetails?.userImage}
                      />
                      <Text
                        fontSize="sm"
                        fontWeight="bold"
                        color={isAdmin ? 'black' : 'white'}
                      >
                        {item.userDetails?.name} - {item.userDetails?.userType}
                      </Text>

                      <Text
                        fontSize="xs"
                        color="gray.600"
                        fontWeight="light"
                        color={isAdmin ? 'black' : 'white'}
                      >
                        {dayjs(item.createdAt).format('DD/MM/YYYY @ HH:mm')}
                      </Text>
                    </HStack>
                  </HStack>

                  <Box>
                    <Text
                      fontWeight="medium"
                      fontSize="md"
                      color={isAdmin ? 'black' : 'white'}
                    >
                      {item.content}{' '}
                    </Text>
                  </Box>
                </Box>
              </Box>
            </HStack>
          );
        })}
      </Box>
    </Box>
  );
};

export default Conversation;
