import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
} from '@chakra-ui/react';
import { useEffect, useState, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';

import { CREATE } from './graphql';

const Uploader = ({ path, reFetch }) => {
  const { isOpen, onClose } = useDisclosure();
  const [selectedFile, setSelectedFile] = useState(null);
  const hiddenFileInput = useRef(null);
  const [getUploadData, { data }] = useLazyQuery(CREATE);

  useEffect(() => {
    getUploadData({
      variables: {
        path: `/${path.join('/')}/`,
        useFileName: true,
        bulk: true,
      },
    });
  }, [path]);

  const handleFileChange = e => {
    setSelectedFile(e.target.files[0]);
  };

  useEffect(() => {
    if (selectedFile && data) {
      const {
        bunnyFile_GetUploadData: { headers, uploadUrl },
      } = data;
      const formData = new FormData();
      formData.append('file', selectedFile);

      const requestOptions = {
        method: 'POST',
        headers,
        body: formData,
      };

      fetch(uploadUrl, requestOptions)
        .then(result => {
          reFetch(Date.now());
          window.location.reload();
          onClose();
        })
        .catch(error => console.log('error', error));
    }
  }, [selectedFile, data]);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <Button colorScheme="green" onClick={handleClick}>
        Add Document
      </Button>
      <Input
        type="file"
        placeholder="upload users"
        onChange={handleFileChange}
        ref={hiddenFileInput}
        style={{ display: 'none' }}
      />
      <Modal isOpen={isOpen} onClose={onClose} size="ls">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Bulk staff upload</ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>

          <ModalFooter>
            <Button colorScheme="green" disabled="true">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Uploader;
