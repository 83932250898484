import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
  query Messagestreams_pagination($perPage: Int) {
    messagestreams_pagination(perPage: $perPage) {
      count
      items {
        title
        content
        isPublic
        categories {
          _id
          value
        }
        created {
          userId
        }
        terminated
        recipients
        lastMessageViewed
        _id
        updatedAt
        createdAt
        status {
          viewed
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const FETCH = gql`
  query Messagestreams_findById($id: MongoID!) {
    messagestreams_findById(_id: $id) {
      title
      content
      isPublic
      categories {
        _id
        value
      }
      created {
        userId
      }
      terminated
      recipients
      lastMessageViewed
      _id
      updatedAt
      createdAt
      status {
        viewed
      }
    }
  }
`;

export const FETCH_CONVERSATION = gql`
  query Messages_pagination(
    $filter: FilterFindManymessagesInput!
    $sort: SortFindManymessagesInput
  ) {
    messages_pagination(filter: $filter, sort: $sort) {
      count
      items {
        content
        streamId
        userId
        viewedBy
        _id
        updatedAt
        createdAt
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const CREATE = gql`
  mutation Messagestreams_createOne($record: CreateOnemessagestreamsInput!) {
    messagestreams_createOne(record: $record) {
      recordId
    }
  }
`;

export const EDIT = gql`
  mutation Emojis_updateById($_id: MongoID!, $record: UpdateByIdemojisInput!) {
    emojis_updateById(_id: $_id, record: $record) {
      error {
        message
      }
      recordId
      record {
        _id
        name
        unicode
        updatedAt
        createdAt
      }
    }
  }
`;

export const DELETE = gql`
  mutation Emojis_removeMany($filter: FilterRemoveManyemojisInput!) {
    emojis_removeMany(filter: $filter) {
      numAffected
      error {
        message
      }
    }
  }
`;

export const DELETE_ID = gql`
  mutation Messagestreams_removeById($id: MongoID!) {
    messagestreams_removeById(_id: $id) {
      recordId
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation Messages_createOne($record: CreateOnemessagesInput!) {
    messages_createOne(record: $record) {
      recordId
      record {
        content
        streamId
        userId
        viewedBy
        _id
        updatedAt
        createdAt
      }
      error {
        message
      }
      messageNotifications {
        userIds
        content
        notifications {
          devices
          message {
            title
            body
            data {
              pdf
              other
              type
            }
          }
          postId
          fired {
            status
            date
            response
          }
          _id
          updatedAt
          createdAt
        }
      }
    }
  }
`;

export const CHECK_AUTH = gql`
  query Admins_findById {
    admins_findById {
      _id
      email
      last_name
      name
    }
  }
`;
