import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
  query Jobs_pagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyjobsInput
    $sort: SortFindManyjobsInput
  ) {
    jobs_pagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      count
      items {
        name
        _id
        updatedAt
        createdAt
      }
    }
  }
`;

export const CREATE_SCHEDULE = gql`
  mutation Schedules_createOne($record: CreateOneschedulesInput!) {
    schedules_createOne(record: $record) {
      recordId
    }
  }
`;

export const FETCH = gql`
  query Jobs_findById($_id: MongoID!) {
    jobs_findById(_id: $_id) {
      _id
      updatedAt
      createdAt
      feed {
        title
        type
        active
        isSticky
        _id
        updatedAt
        createdAt
        schedules {
          _id
          date
          feed {
            _id
            active
          }
        }
        visibility {
          public
          branches {
            _id
          }
        }
        staticpage {
          _id
          title
          url
          seo
          content
          slug
          hero
          cta
          updatedAt
          createdAt
        }
        jobspec {
          status
          address
          salary
          type
          benefits {
            title
            _id
            updatedAt
            createdAt
          }
          description
          hireMultiple
          _id
          updatedAt
          createdAt
        }
      }
    }
  }
`;

export const CREATE = gql`
  mutation Jobs_createOne($record: CreateOnejobsInput!) {
    jobs_createOne(record: $record) {
      _id
      updatedAt
      createdAt
      feed {
        title
        type
        active
        isSticky
        _id
        updatedAt
        createdAt
        staticpage {
          _id
          title
          title
          url
          seo
          content
          slug
          hero
          cta
          updatedAt
          createdAt
          created
        }
        jobspec {
          status
          address
          salary
          type
          benefits {
            title
            _id
            updatedAt
            createdAt
          }
          description
          hireMultiple
          _id
          updatedAt
          createdAt
        }
      }
    }
  }
`;
export const FETCH_BENEFITS = gql`
  query Jobbenefits_pagination {
    jobbenefits_pagination {
      count
      items {
        title
        _id
        updatedAt
        createdAt
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export const EDIT_JOB_SPEC = gql`
  mutation Jobspecs_updateById(
    $_id: MongoID!
    $record: UpdateByIdjobspecsInput!
  ) {
    jobspecs_updateById(_id: $_id, record: $record) {
      recordId
      record {
        status
        address
        salary
        type
        benefits {
          title
          _id
          updatedAt
          createdAt
        }
        description
        hireMultiple
        _id
        updatedAt
        createdAt
      }
      error {
        message
      }
    }
  }
`;

export const DELETE_ID = gql`
  mutation Jobs_removeById($id: MongoID!) {
    jobs_removeById(_id: $id) {
      record {
        _id
      }
      error {
        ... on MongoError {
          code
          message
        }
      }
    }
  }
`;
export const DELETE_MANY = gql`
  mutation Jobs_removeById($_id: MongoID!) {
    jobs_removeById(_id: $_id) {
      record {
        _id
      }
      recordId
      error {
        message
      }
    }
  }
`;
