import { Flex, Heading, Image } from '@chakra-ui/react';
import React from 'react';
import config from '../../config.json';

const LogoHeading = ({ noFlex }) => (
  <Flex align="center" flex={noFlex ? 0 : 1}>
    <Image src="/parfetts.png" w="30px" />
    <Heading ml="10px" fontSize="14px" fontWeight="500" color="gray.500">
      {config.project}
    </Heading>
  </Flex>
);

export default LogoHeading;
