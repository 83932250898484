import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { MdEditSquare, MdOutlineDelete } from 'react-icons/md';
import { useMutation, useLazyQuery } from '@apollo/client';
import Swal from 'sweetalert2';
import {
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  useToast,
} from '@chakra-ui/react';

import QuestionForm from './QuestionForm';
import {
  FETCH_QUESTIONS,
  CREATE_QUESTION,
  UPDATE_QUESTION,
  REMOVE_QUESTION,
} from './graphql';

const ManageQuestions = ({ surveyId }) => {
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(undefined);
  const [fetchData, { data: questionsData, refetch }] =
    useLazyQuery(FETCH_QUESTIONS);
  const [createQuestion, { loading: loading1 }] = useMutation(CREATE_QUESTION);
  const [updateQuestion, { loading: loading2 }] = useMutation(UPDATE_QUESTION);
  const [removeQuestion, { loading: loading3 }] = useMutation(REMOVE_QUESTION);

  const loading = loading1 || loading2 || loading3;
  // console.log('questions', questions);

  useEffect(() => {
    if (surveyId) {
      fetchData({
        variables: {
          filter: {
            surveyId,
          },
        },
      });
    }
  }, [surveyId, fetchData]);

  useEffect(() => {
    if (questionsData) {
      setQuestions(questionsData.surveyquestions_findMany);
    }
  }, [questionsData]);

  const onClose = () => {
    setIsOpen(false);
    setActiveQuestion(undefined);
  };

  const handleAddQuestion = () => {
    setIsOpen(true);
  };

  const handleEditQuestion = (index) => {
    const question = questions[index];
    setActiveQuestion(question);
    setIsOpen(true);
  };

  const handleRemoveQuestion = (item) => {
    Swal.fire({
      title: 'Do you want to remove this question?',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await removeQuestion({
          variables: {
            _id: item._id,
          },
        });
        // do party toast
        toast({
          title: 'Question removed!',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
        refetch();
      }
    });
  };

  // save the questions, to the server
  const handleSave = async (formData, isEdit) => {
    const { _id, __typename, options, ...rest } = formData;

    // clean the data before launching to the server
    const optionsCleaned = options.map((option) => {
      const { __typename: __typename2, ...rest2 } = option;
      return rest2;
    });
    const questionCleaned = {
      ...rest,
      options: optionsCleaned,
    };

    // launch the spaceship
    if (isEdit) {
      // update
      await updateQuestion({
        variables: {
          id: _id,
          record: questionCleaned,
        },
      });
    } else {
      // create
      await createQuestion({
        variables: {
          record: questionCleaned,
        },
      });
    }

    // do party toast
    toast({
      title: `Question ${isEdit ? 'updated' : 'created'}!`,
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
    refetch();
    onClose();
  };

  return (
    <Flex flexDirection="column">
      <Button colorScheme="green" onClick={handleAddQuestion} marginBottom={4}>
        + Add Question
      </Button>
      {questions.length > 0 && (
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Question</Th>
                <Th>Type</Th>
                <Th isNumeric>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {questions.map((item, index) => (
                <Tr key={item.id}>
                  <Td>{item.title}</Td>
                  <Td>{capitalize(item.type)}</Td>
                  <Td isNumeric>
                    <IconButton
                      aria-label="Edit question"
                      icon={<MdEditSquare />}
                      w={8}
                      h={8}
                      marginRight={2}
                      variant="outline"
                      onClick={() => handleEditQuestion(index)}
                      isDisabled={loading}
                    />
                    <IconButton
                      aria-label="Remove question"
                      icon={<MdOutlineDelete />}
                      w={8}
                      h={8}
                      variant="outline"
                      colorScheme="red"
                      onClick={() => handleRemoveQuestion(item)}
                      isDisabled={loading}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <QuestionForm
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleSave}
        data={activeQuestion}
        surveyId={surveyId}
        isLoading={loading}
      />
    </Flex>
  );
};

export default ManageQuestions;
