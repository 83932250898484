import React from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Box, Button, Stack, useColorModeValue } from '@chakra-ui/react';
import * as emoji from 'node-emoji';

import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import { DELETE_MANY, FETCH_ALL } from './graphql';
import { setup } from './setup';

const columns = [
  {
    Header: '',
    accessor: '_id',
    disableSortBy: true,
    Cell: ({ row }) => (
      <Link to={`/${setup.model}/edit/${row.original[setup.accessor]}`}>
        <Button variant="link">View</Button>
      </Link>
    ),
  },
  {
    Header: 'Name',
    accessor: 'name',
    disableSortBy: false,
    Cell: ({ value }) => <div>{value}</div>,
  },
  {
    Header: 'Unicode',
    accessor: 'unicode',
    disableSortBy: false,
    Cell: ({ value }) => <div>{emoji.emojify(value)}</div>,
  },
];

const filters = [
  {
    value: 'name',
    type: 'text',
    label: 'Name',
    isOperator: false,
  },
];

const List = () => {
  const [fetchData, { loading, error, data }] = useLazyQuery(FETCH_ALL);
  // console.log('data', data);

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml="auto" isInline spacing="15px">
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>
      <Box
        mt="25px"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w="100%"
      >
        <ListView
          setup={setup}
          fetchData={fetchData}
          data={data?.emojis_pagination}
          loading={loading}
          error={error}
          gqlDelete={DELETE_MANY}
          columns={columns}
          filters={filters}
        />
      </Box>
    </Meta>
  );
};

export default List;
