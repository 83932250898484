import { Box, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/client';
import { FETCH_ALL } from '../../Comments/graphql';
import { EDIT } from '../graphql';

export default function CommentsSelect({ feed }) {
  const toast = useToast();

  const [commentsOptions, setCommentsOptions] = useState([]);
  const [selectedComments, setSelectedComments] = useState([]);

  const {
    data: commentsData,
    loading: commentsLoading,
    error: commentsError,
  } = useQuery(FETCH_ALL);

  const [editFeed, { data: editFeedData, error: editFeedError }] =
    useMutation(EDIT);

  const targetId = feed?.staticpage?._id;

  useEffect(() => {
    if (commentsData) {
      const formattedOptions = commentsData?.comment_bank_pagination?.items.map(
        (item) => ({
          label: item.content,
          value: item._id,
        }),
      );
      setCommentsOptions(formattedOptions);
      const selectedCommentsIds = feed?.comment_bank_options.map(
        (comment) => comment._id,
      );
      if (!selectedCommentsIds) {
        return;
      }

      const result = formattedOptions
        ? formattedOptions.filter((comment) =>
            selectedCommentsIds.includes(comment.value),
          )
        : [];
      setSelectedComments(result);
    }
  }, [commentsData, feed?.comment_bank_options]);

  useEffect(() => {
    if (editFeedData) {
      toast({
        title: 'Saved',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }

    if (commentsError || editFeedError) {
      toast({
        title: 'Error, try again',
        message: commentsError?.message || editFeedError?.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }, [commentsError, editFeedError, toast, editFeedData]);

  if (commentsLoading) return <Text>Loading...</Text>;

  return (
    <Box>
      <Text fontWeight={'medium'} py={2}>
        Comments
      </Text>
      <Select
        options={commentsOptions}
        placeholder="select comments"
        name="comments"
        isMulti
        value={selectedComments}
        onChange={(value) => {
          setSelectedComments(value);

          const comments = value
            .map((item) => ({ _id: item.value }))
            .filter((item) => item._id);

          editFeed({
            variables: {
              record: {
                comment_bank_options: comments,
              },
              _id: targetId,
            },
          });
        }}
      />
    </Box>
  );
}
