import {
  Input,
  Button,
  Stack,
  Box,
  AspectRatio,
  Flex,
  Image,
  Heading,
  Text,
  Spinner,
  Img,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  IconButton,
} from "@chakra-ui/react";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";

import React, { useState, useRef, useEffect } from "react";
import EditImage from "./EditImage";

export function Editor({ files = [], open = false, onChange }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [saving, setSaving] = useState(false);

  // Index of file files[currentImage]
  const [currentImage, setCurrentImage] = useState(0);

  // Decrement current Image index
  const handlePreviousImage = () => {
    if (currentImage === 0) return;
    if (currentImage > 0) setCurrentImage((prev) => prev - 1);
  };

  // Increment current Image index
  const handleNextImage = () => {
    if (currentImage === files.length - 1) return;
    setCurrentImage((prev) => prev + 1);
  };

  useEffect(() => {
    // Open Editor Modal if open is true
    if (open) {
      setCurrentImage(0);
      setSaving(false);
      onOpen();
    }
  }, [open]);

  // Tests

  useEffect(() => {
    console.log("currentImage", currentImage);
  }, [currentImage]);

  useEffect(() => {
    console.log("closeddd", isOpen);
    if (!isOpen) {
      onChange([]);
    }
  }, [isOpen]);

  const handleOnChange = (file, index) => {
    console.log("handleOnChange", file, index);
    files[index].scale = file?.scale;
    files[index].rotate = file?.rotate;
    files[index].width = file?.width;
    files[index].height = file?.height;
    files[index].aspect = file?.aspect;
    files[index].crop = file?.crop;
    files[index].editFile = file?.editFile;
  };

  const saveImages = () => {
    console.log("save images", files);
    onChange(files?.map((f) => f?.editFile));
    setSaving(true);
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Text>Image Editor</Text>
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Text>
                  {currentImage + 1}/{files.length}
                </Text>
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  ml={4}
                >
                  <IconButton
                    isDisabled={currentImage === 0 ? true : false}
                    onClick={handlePreviousImage}
                    icon={<FaArrowAltCircleLeft />}
                    mr={2}
                  ></IconButton>
                  <IconButton
                    isDisabled={
                      currentImage === files.length - 1 ? true : false
                    }
                    onClick={handleNextImage}
                    icon={<FaArrowAltCircleRight />}
                  ></IconButton>
                </Flex>
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalBody>
            <EditImage
              index={currentImage}
              image={files[currentImage]}
              onChange={handleOnChange}
            />
          </ModalBody>
          <ModalFooter>
            {!saving && (
              <Button onClick={saveImages} colorScheme="blue" mr={2}>
                Save
              </Button>
            )}
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
