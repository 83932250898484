import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import {
  Flex,
  Grid,
  Stack,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';

import EditView from '../../components/EditView';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { EDIT, FETCH, CREATE, DELETE_ID } from './graphql';
import ManageQuestions from './ManageQuestions';
import SurveyResponses from './SurveyResponses';
import { setup } from './setup';
import FeedFields from '../../components/Feed/Fields';

const Index = () => {
  const title = 'Update Survey';
  const params = useParams();
  const [fetchData, { data, loading, error, refetch }] = useLazyQuery(FETCH);
  const { id: surveyId } = params;
  // console.log('survey', data?.surveys_findById);

  return (
    <Meta title={title}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
        gqlDelete={DELETE_ID}
        ignoreValues={['questions']}
        gqlString={'surveys_createOne'}
      >
        <Flex direction="column" maxWidth="75%" w="100%">
          <Tabs
            orientation="vertical"
            w="100%"
            m="0"
            p="0"
            ml="-40px"
            align="start"
          >
            <TabList>
              <Tab justifyContent={'flexStart'}>General</Tab>
              {params.type === 'edit' && (
                <Tab justifyContent={'flexStart'}>Questions</Tab>
              )}
              {params.type === 'edit' && (
                <Tab justifyContent={'flexStart'}>Responses</Tab>
              )}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Grid
                  templateColumns={{ base: '1fr' }}
                  w="100%"
                  maxWidth="1000px"
                  gap="25px"
                >
                  <Stack spacing="20px" w="100%">
                    <Stack spacing="20px" w="100%">
                      <FormSection title={title}>
                        <FormSectionInner>
                          <FeedFields
                            targetID={data?.surveys_findById?.feed?._id}
                            feed={data?.surveys_findById?.feed}
                            refetch={refetch}
                          />
                        </FormSectionInner>
                      </FormSection>
                    </Stack>
                  </Stack>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid
                  templateColumns={{ base: '1fr' }}
                  w="100%"
                  maxWidth="1000px"
                  gap="25px"
                >
                  <Stack spacing="20px" w="100%">
                    <Stack spacing="20px" w="100%">
                      <FormSection title={`${title} Questions`}>
                        <FormSectionInner>
                          <ManageQuestions surveyId={surveyId} />
                        </FormSectionInner>
                      </FormSection>
                    </Stack>
                  </Stack>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid
                  templateColumns={{ base: '1fr' }}
                  w="100%"
                  maxWidth="1000px"
                  gap="25px"
                >
                  <Stack spacing="20px" w="100%">
                    <Stack spacing="20px" w="100%">
                      <FormSection title="Survey Responses">
                        <FormSectionInner>
                          <SurveyResponses survey={data?.surveys_findById} />
                        </FormSectionInner>
                      </FormSection>
                    </Stack>
                  </Stack>
                </Grid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
        <Grid
          templateColumns={{ base: '1fr' }}
          w="100%"
          maxWidth="1000px"
          gap="25px"
        ></Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
