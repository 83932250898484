import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  IconButton,
  Spinner,
  Tag,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Calendar from 'react-calendar';
import { differenceInCalendarDays } from 'date-fns';
import { TbPencil } from 'react-icons/tb';
import HeaderWithActions from '../../components/HeaderWithActions';
import Meta from '../../components/Meta';
import { FETCH_FEED, FETCH_SCHEDULE, FETCH, DELETE_SCHEDULE } from './graphql';
import { setup } from './setup';
import 'react-calendar/dist/Calendar.css';

dayjs.extend(relativeTime);

const List = () => {
  const [title, setTitle] = useState(null);
  const [body, setBody] = useState(null);
  const [real_id, setReal_id] = useState(null);

  const [fetchFeedData, { loading, error, data: feedData }] =
    useLazyQuery(FETCH_FEED);
  const [
    fetchSingle,
    { data: singleData, loading: singleLoading, error: singleError },
  ] = useLazyQuery(FETCH);

  const { data: scheduleData, refetch: reFectchSchedule } = useQuery(
    FETCH_SCHEDULE,
    {
      variables: {
        page: 1,
        perPage: 999,
        filter: {
          completed: false,
          enabled: true,
        },
      },
    }
  );

  useEffect(() => {
    if (feedData) {
      setTitle(feedData?.feeds_pagination?.items?.[0].title);
      setBody(feedData?.feeds_pagination?.items?.[0].type);
      setReal_id(feedData?.feeds_pagination?.items?.[0]._id);
    }
  }, [feedData]);

  useEffect(() => {
    if (singleData?.notifications_findById?.message) {
      setTitle(singleData?.notifications_findById?.message?.title);
      setReal_id(singleData?.notifications_findById?._id);

      setBody('notifications');
    }
  }, [singleData?.notifications_findById?.message?.title]);

  const [removeSchedule, { data: removeData }] = useMutation(DELETE_SCHEDULE);

  const handleRemoveSchedule = removeID => {
    removeSchedule({ variables: { id: removeID } });
  };

  useEffect(() => {
    if (removeData) {
      reFectchSchedule();
    }
  }, [removeData]);

  function isSameDay(a, b) {
    return differenceInCalendarDays(a, b) === 0;
  }

  const [datesToAddContentTo, setDatesToAddContentTo] = useState([]);
  useEffect(() => {
    if (scheduleData) {
      setDatesToAddContentTo(
        scheduleData?.schedules_pagination?.items?.map(el => {
          const jsDate = dayjs(el.date).toDate();

          return {
            date: jsDate,
            _id: el?.notifications?.[0]?._id,
            sched_id: el._id,
            feedId: el.feed._id,
          };
        })
      );
    }
  }, [scheduleData]);
  function tileContent({ date, view }) {
    // Add class to tiles in month view only
    if (view === 'month') {
      const matchindDate = datesToAddContentTo.filter(dDate =>
        isSameDay(dDate?.date, date)
      );
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      if (matchindDate) {
        return matchindDate.map((eachDate, i) => (
          <Box key={`cal-sche-${i}`}>
            <Popover>
              <PopoverTrigger>
                <Button
                  variant="outline"
                  size="xs"
                  p="0"
                  onClick={() =>
                    eachDate?.feedId
                      ? fetchFeedData({
                          variables: {
                            filter: {
                              _id: eachDate?.feedId,
                            },
                          },
                        })
                      : fetchSingle({
                          variables: {
                            id: eachDate?._id,
                          },
                        })
                  }
                >
                  @{dayjs(eachDate?.date).format('HH:mm')}
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader color="black">
                  {dayjs(eachDate?.date).format('DD/MM/YY @ HH:mm')}
                </PopoverHeader>
                <PopoverBody color="black">
                  <Preview
                    loading={loading || singleLoading}
                    title={title}
                    body={body}
                    // arrData={feedData?.feeds_pagination?.items}
                    // data={singleData?.notifications_findById?.message}
                  />
                </PopoverBody>
                <PopoverFooter>
                  <Button
                    variant="solid"
                    colorScheme="red"
                    size="sm"
                    onClick={() => handleRemoveSchedule(eachDate?.sched_id)}
                  >
                    Remove schedule
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
            {/* </Link> */}
          </Box>
        ));
      }
    }
  }

  const Preview = ({ loading, title, body }) =>
    loading ? (
      <Spinner />
    ) : (
      <Flex direction="column" alignItems={'center'} justifyContent={'center'}>
        <Flex justifyContent={'center'} alignItems={'center'}>
          <Text>{title}</Text>{' '}
          <Link to={`/${body}/edit/${real_id}`}>
            <IconButton
              variant="outline"
              aria-label="Search database"
              icon={<TbPencil />}
            />
          </Link>
        </Flex>
        <Tag colorScheme="gray">{body}</Tag>
      </Flex>
    );
  return (
    <Meta title={setup.title}>
      <HeaderWithActions>
        <Calendar tileContent={tileContent} />
      </HeaderWithActions>
    </Meta>
  );
};

export default List;
