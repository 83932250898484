import { gql } from '@apollo/client';

export const POST_DATA = gql`
  query Posts_reactions_get($staticpageId: MongoID!) {
    posts_reactions_get(staticpage_id: $staticpageId) {
      emoji {
        unicode
        name
        _id
        updatedAt
        createdAt
      }
      users_count
      user_reacted
    }
  }
`;
export const FETCH_ALL = gql`
  query Feeds_pagination(
    $page: Int
    $perPage: Int
    $filter: FilterFindManyfeedsInput
    $sort: SortFindManyfeedsInput
    $regexp: feeds_pagination_regexp
    $hasReacted: Boolean
  ) {
    feeds_pagination(
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
      regexp: $regexp
      hasReacted: $hasReacted
    ) {
      count
      items {
        title
        type
        active
        isSticky
        _id
        updatedAt
        createdAt
        staticpage {
          _id
          title
          url
          seo
          content
          slug
          hero
          cta
          updatedAt
          createdAt
        }
        jobspec {
          status
          address
          salary
          type
          benefits {
            title
            _id
            updatedAt
            createdAt
          }
          description
          hireMultiple
          _id
          updatedAt
          createdAt
        }
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const FETCH = gql`
  query Posts_findById($_id: MongoID!) {
    posts_findById(_id: $_id) {
      reactions {
        emoji {
          _id
        }
        users {
          _id
        }
        _id
      }
      _id
      updatedAt
      createdAt
      feed {
        title
        type
        active
        isSticky
        visibility {
          public
          branches {
            _id
          }
        }
        comment_bank_options {
          _id
        }
        _id
        updatedAt
        createdAt
        staticpage {
          _id
          title
          url
          seo
          content
          slug
          hero
          cta
          updatedAt
          createdAt
        }
        jobspec {
          status
          address
          salary
          type
          benefits {
            title
            _id
            updatedAt
            createdAt
          }
          description
          hireMultiple
          _id
          updatedAt
          createdAt
        }
        surveyComplete
      }
    }
  }
`;

export const CREATE = gql`
  mutation Posts_createOne($record: CreateOnepostsInput!) {
    posts_createOne(record: $record) {
      _id
      feed {
        _id
        title
        staticpage {
          created
          _id
        }
      }
    }
  }
`;

export const EDIT = gql`
  mutation Feeds_updateById($_id: MongoID!, $record: UpdateByIdfeedsInput!) {
    feeds_updateById(_id: $_id, record: $record) {
      recordId
      record {
        title
        type
        active
        isSticky
        _id
        updatedAt
        createdAt
        staticpage {
          _id
          title
          url
          seo
          content
          slug
          hero
          cta
          updatedAt
          createdAt
        }
        jobspec {
          status
          address
          salary
          type
          benefits {
            title
            _id
            updatedAt
            createdAt
          }
          description
          hireMultiple
          _id
          updatedAt
          createdAt
        }
      }
      error {
        message
      }
    }
  }
`;

export const DELETE_ID = gql`
  mutation Posts_removeById($id: MongoID!) {
    posts_removeById(_id: $id) {
      error {
        message
      }
      recordId
    }
  }
`;

export const DELETE_MANY = gql`
  mutation Posts_removeById($id: MongoID!) {
    posts_removeById(_id: $id) {
      error {
        message
      }
      recordId
    }
  }
`;

export const EDIT_STATIC_PAGE = gql`
  mutation Staticpage_updateById(
    $_id: MongoID!
    $record: UpdateByIdstaticpageInput!
  ) {
    staticpage_updateById(_id: $_id, record: $record) {
      recordId
      record {
        _id
        url
        seo
        content
        slug
        hero
        cta
        updatedAt
        createdAt
      }
      error {
        message
      }
    }
  }
`;
export const EDIT_FEED = gql`
  mutation Staticpage_updateById(
    $_id: MongoID!
    $record: UpdateByIdstaticpageInput!
  ) {
    staticpage_updateById(_id: $_id, record: $record) {
      recordId
      record {
        _id
        url
        seo
        content
        slug
        hero
        cta
        updatedAt
        createdAt
      }
      error {
        message
      }
    }
  }
`;
